import { getErrorColumns } from '../../lib';

export const original_columns = [
  {
    title: '错误原因',
    dataIndex: 'error_msg',
    key: 'error_msg',
    width: 200,
  },
  {
    title: '真实姓名',
    width: 80,
  },
  {
    title: '手机号',
    width: 120,
  },
  {
    title: '性别',
    width: 120,
  },
  {
    title: '身份证号',
    width: 120,
  },
  {
    title: '部门',
    width: 120,
  },
  {
    title: '分部门',
    width: 120,
  },
  {
    title: '服务部门',
    width: 120,
  },
  {
    title: '卡号',
    width: 120,
  },
  {
    title: '入住时间',
    width: 120,
  },
  {
    title: '宿舍楼号',
    width: 120,
  },
  {
    title: '宿舍号',
    width: 120,
  },
  {
    title: '床号',
    width: 120,
  },
];

export const columns = getErrorColumns(original_columns)
