import moment from 'moment'

export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 80,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 200,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
  {
    title: '最后一次修改时间',
    dataIndex: 'updated_date',
    key: 'updated_date',
    width: 200,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
  },
];
