import React from 'react';
import { Icon, Modal, Form, Button, Upload, Table } from 'antd';

import { message } from '../../components/message';
import { api_uri } from '../../config';
import { columns } from './error.columns';
import { file_props } from '../../lib/upload';
import ExportExcel from '../../lib/xlsx';

const { Dragger } = Upload;

class UploadPeopleXlsxModal extends React.Component<any> {
  state = {
    visible: false,
    visible_upload: false,
    data: [],
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible, visible_upload, data } = this.state;
    return (
      <div style={{ display: 'inline-block', marginRight: '12px' }}>
        <Button onClick={() => this.setState({ visible: true })} type="primary">
          批量上传
        </Button>
        <Modal
          title="批量上传"
          centered
          visible={visible}
          width={data.length === 0 ? 500 : 1200}
          onCancel={() =>
            this.setState({ visible: false, visible_upload: false, data: [] })
          }
          footer={null}
        >
          {visible && (
            <>
              {data.length === 0 && (
                <Dragger
                  disabled={visible_upload}
                  {...file_props('people/upload/xlsx')}
                  onChange={(info: any) => {
                    const { status, response, name } = info.file;
                    if (status === 'done') {
                      const error_data = response.errors.map(
                        ({ error_msg, error_key, data }: any, index: any) => ({
                          error_msg,
                          error_key,
                          ...data,
                        }),
                      );
                      if (error_data.length === 0) {
                        this.setState({
                          visible: false,
                          visible_upload: false,
                          data: [],
                        });
                        return message.success('上传成功');
                      }
                      return this.setState({
                        visible_upload: true,
                        data: error_data,
                      });
                    } else if (status === 'error') {
                      if (response.message &&
                        (response.message.includes('Access denied') ||
                          response.message.includes('Forbidden resource'))
                      ) {
                        message.error(new Error(`无权限`));
                      } else {
                        message.error(new Error(`${name} 上传失败.`));
                      }
                    }
                    this.setState({ visible_upload: true });
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或者拖动到此处上传</p>
                  <p className="ant-upload-hint">请上传 xlsx 格式</p>
                </Dragger>
              )}
              {!visible_upload && (
                <a
                  target="view_window"
                  href={api_uri + '/file/public/人员导入模版.xlsx'}
                  download="人员导入模版.xlsx"
                  style={{ marginTop: '8px' }}
                >
                  下载模版
                </a>
              )}

              {data.length > 0 && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      ExportExcel(columns, data, '上传人员错误数据')
                    }
                    style={{ marginBottom: '16px' }}
                  >
                    导出错误数据
                  </Button>
                  <Table columns={columns} dataSource={data} />
                </div>
              )}
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export const UploadPeopleXlsxModalForm: any = Form.create({
  name: 'upload_people',
})(UploadPeopleXlsxModal);
