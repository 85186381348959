import React from 'react';
import { Button, Modal } from 'antd';

import { message } from '../../components/message';
import { StopPeopleComponent } from '../../apollo';

const { confirm } = Modal;

export class StopPeopleModal extends React.Component<any> {
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    confirm({
      title: '停用提示',
      content: '确定停用吗？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const { id, refetch } = this.props;
        try {
          await send({
            variables: {
              id,
            },
          });
          message.success('停用成功');
          refetch();
        } catch (e) {
          message.error(e);
        }
      },
    });
  };

  render() {
    return (
      <StopPeopleComponent>
        {send => {
          return (
            <Button 
              type="link" 
              style={{ marginTop: -5 }}
              onClick={this.handleSubmit.bind(this, send)}><span style={{ color: 'red' }}>停用</span></Button>
          );
        }}
      </StopPeopleComponent>
    );
  }
}
