import React from 'react';
import { Button, Modal, Form, Input, InputNumber, Select } from 'antd';

import { CreateCameraComponent } from '../../apollo';
import { message } from '../../components/message';

const { Option } = Select;

class AddCameraModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateCamera: {
                ...values,
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          新增摄像头
        </Button>
        <Modal
          title="新增摄像头"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <Form.Item label="相机名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入相机名称!' }],
              })(<Input placeholder="相机名称" />)}
            </Form.Item>
            <Form.Item label="类型">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: '请选择类型!' }],
              })(
                <Select placeholder="类型">
                  <Option value="入口">入口</Option>
                  <Option value="出口">出口</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="ip 地址">
              {getFieldDecorator('camera_address', {
                rules: [{ required: true, message: '请输入ip 地址!' }],
              })(<Input placeholder="ip 地址" />)}
            </Form.Item>
            <Form.Item label="楼号">
              {getFieldDecorator('dorm_number', {
                rules: [{ required: true, message: '请输入楼号!' }],
              })(<InputNumber style={{ width: '100%' }} placeholder="楼号" />)}
            </Form.Item>
            <Form.Item style={{ marginTop: '12px' }}>
              <CreateCameraComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateCameraComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddCameraModalForm: any = Form.create({ name: 'create_Camera' })(
  AddCameraModal,
);
