import React from 'react';
import {
  Button,
  Icon,
  Modal,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
} from 'antd';
import moment from 'moment';
import validator from 'validator';

import { UpdateHousemasterComponent } from '../../apollo';
import { message } from '../../components/message';
import { isPhoneNumber } from '../../lib';

class ChangeHousemasterModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateHousemaster: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form, item } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Icon onClick={() => this.setState({ visible: true })} type="edit" />
        <Modal
          title="修改宿舍管理员"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="真实姓名">
                  {getFieldDecorator('true_name', {
                    rules: [{ required: true, message: '请输入真实姓名!' }],
                    initialValue: item.true_name,
                  })(<Input style={{ width: 120 }} placeholder="真实姓名" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="职工号">
                  {getFieldDecorator('job_number', {
                    rules: [
                      { required: true, message: '请输入职工号!' },
                      { len: 8, message: '职工号必须是8位!' },
                    ],
                    initialValue: item.job_number,
                  })(<Input style={{ width: 120 }} placeholder="职工号" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="宿舍楼号">
                  {getFieldDecorator('dorm_number', {
                    rules: [{ required: true, message: '请输入宿舍楼号!' }],
                    initialValue: item.dorm_number,
                  })(
                    <InputNumber
                      style={{ width: 120 }}
                      placeholder="宿舍楼号"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="手机号">
              {getFieldDecorator('mobile', {
                validateFirst: true,
                initialValue: item.mobile,
                rules: [
                  { required: true, message: '请输入手机号!' },
                  {
                    validator: (_: any, value: any, callback: any) => {
                      // if (value && validator.isMobilePhone(value, 'zh-CN')) {
                      if (value && isPhoneNumber(value)) {
                        callback();
                      } else {
                        callback(new Error('请输入正确的手机号'));
                      }
                    },
                  },
                ],
              })(<Input placeholder="手机号" />)}
            </Form.Item>
            <Form.Item label="入职时间">
              {getFieldDecorator('start_date', {
                rules: [{ required: true, message: '请选择入职时间!' }],
                initialValue: moment(item.start_date),
              })(<DatePicker placeholder="入职时间" />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('remark', {
                initialValue: item.remark,
              })(
                <Input.TextArea autosize={{ minRows: 5 }} placeholder="备注" />,
              )}
            </Form.Item>
            <Form.Item>
              <UpdateHousemasterComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdateHousemasterComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeHousemasterModalForm: any = Form.create({
  name: 'change_housemaster',
})(ChangeHousemasterModal);
