import React from 'react';
import { DeleteScreenModal } from './delete-screen';
import { Row, Col } from 'antd';
import moment from 'moment';
import { ChangeScreenModalForm } from './update-screen';

export const columns = (refetch?: any, filter?: any) => [
  {
    title: '管理部门',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    // filters: filter && filter.dorms,
    // render: (_: any, record: any) => record.dorm_number,
  },
  {
    title: '所属单位',
    dataIndex: 'department',
    key: 'department',
    width: 160,
  },
  {
    title: '是否显示',
    dataIndex: 'is_show',
    key: 'is_show',
    width: 120,
    render: (text: any) => (text ? '是' : '否'),
  },
  {
    title: '房间数量',
    dataIndex: 'room_count',
    key: 'room_count',
    width: 120,
  },
  {
    title: '登记人员',
    dataIndex: 'people_count',
    key: 'people_count',
    width: 120,
  },
  {
    title: '在寝人员',
    dataIndex: 'people_in_count',
    key: 'people_in_count',
    width: 120,
  },
  {
    title: '未在寝人员',
    dataIndex: 'people_out_count',
    key: 'people_out_count',
    width: 120,
    render: (_: any, record: any) =>
      record.people_count - record.people_in_count,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 180,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '操作',
    width: 120,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <ChangeScreenModalForm item={record} refetch={refetch} />
        </Col>
        <Col className="gutter-row" span={12}>
          <DeleteScreenModal id={record.key} refetch={refetch} />
        </Col>
      </Row>
    ),
  },
];
