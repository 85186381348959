import React from 'react';
import { DeleteRoomModal } from './delete-room';
import { Row, Col } from 'antd';
import moment from 'moment';

import styles from './index.module.css'

export const columns = (refetch?: any, filter?: any) => [
  {
    title: '楼号',
    dataIndex: 'dorm_numbers',
    key: 'dorm_numbers',
    width: 80,
    filters: filter && filter.dorms,
    render: (_: any, record: any) => record.dorm_number,
  },
  {
    title: '楼层',
    dataIndex: 'floor_numbers',
    key: 'floor_numbers',
    width: 120,
    filters: [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
      { text: 6, value: 6 },
      { text: 7, value: 7 },
      { text: 8, value: 8 },
      { text: 9, value: 9 },
      { text: 10, value: 10 },
      { text: 11, value: 11 },
    ],
    render: (_: any, record: any) => record.floor_number,
  },
  {
    title: '房间号',
    dataIndex: 'number',
    key: 'number',
    width: 120,
  },
  {
    title: '可住人数',
    dataIndex: 'people_count',
    key: 'people_count',
    width: 120,
  },
  {
    title: '已住人数',
    dataIndex: 'people_in_count',
    key: 'people_in_count',
    width: 120,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <DeleteRoomModal id={record.key} refetch={refetch} />
        </Col>
      </Row>
    ),
  },
];
