import React from 'react';

export const columns = [
  {
    title: '姓名',
    dataIndex: 'username',
    key: 'username',
    // search: true,
    fixed: 'left',
    width: 80,
    // render: (text: string, record: any) => (
    //   <a href={`/competition/info?user_id=${record.key}`}>{text}</a>
    // ),
  },
  {
    title: '手机号码',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 120,
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    width: 120,
  },
];
