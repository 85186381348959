import React from 'react';
import { Row, Col } from 'antd';

import { BaseLayout } from '../../layout/base-layout';
import styles from './index.module.css';

import icon1 from './icon1@2x.png';
import icon2 from './icon2@2x.png';
// import icon3 from './icon3@2x.png';
import icon4 from './icon4@2x.png';
import icon6 from './icon6@2x.png';
// import icon7 from './icon7@2x.png';
// import icon8 from './icon8@2x.png';
import boy from './boy@2x.png';
import girl from './girl@2x.png';
import { NumberCard } from './components/number-card';
import { Floor, FloorCount, FloorNumber } from './components/floor';
import { ChartFirst } from './components/chart-first';
import { ChartSecond } from './components/chart-second';
// import { ChartThird } from './components/chart-third';
import {
  CountPeopleComponent,
  DormCountComponent,
  // CountTeacherComponent,
  DormFlowComponent,
  StatisticsComponent,
  // DormLateComponent,
  // DormNoReturnComponent,
  StatisticsBedComponent,
} from '../../apollo';
import NoAccess from '../../components/no-access';
import { bed_count } from '../../config';

function getStudentCount(data: any) {
  let count = 0;
  let boy_count = 0;
  let girl_count = 0;
  let in_count = 0;
  let out_count = 0;
  if (data && data.countPeople) {
    count = data.countPeople.count;
    boy_count = data.countBoyPeople.count;
    girl_count = data.countGirlPeople.count;
    in_count = data.countInPeople.count;
    out_count = data.countOutPeople.count;
  }
  return { count, boy_count, girl_count, in_count, out_count };
}

const Index: React.FC = () => {
  // const session = JSON.parse(localStorage.session);
  // if (
  //   !session ||
  //   (!session.roles.includes('super_admin') &&
  //     !session.permissions.includes('查看首页统计'))
  // ) {
  //   return (
  //     <BaseLayout
  //       contentStyles={{ background: 'rgb(240, 242, 245)', padding: '0px' }}
  //     >
  //       <NoAccess />
  //     </BaseLayout>
  //   );
  // }
  return (
    <div className="App">
      <BaseLayout
        contentStyles={{ background: 'rgb(240, 242, 245)', padding: '0px' }}
      >
        <StatisticsBedComponent>
          {({ data }) => {
            if (!data || !data.statistics) {
              return '';
            }
            return (
              <>
                {/* <Row className={styles.row} gutter={16}>
                  <Col span={6}>
                    <NumberCard
                      title="在寝"
                      // @ts-ignore
                      // number={data.countInStudent.count}
                      src={icon7}
                    />
                  </Col>
                  <Col span={6}>
                    <NumberCard
                      title="外出"
                      // @ts-ignore
                      // number={data.countOutStudent.count}
                      // number={data.countStudent.count - data.countInStudent.count}
                      src={icon8}
                    />
                  </Col>
                  <Col span={6}>
                    <NumberCard
                      title="未归人数(实时)"
                      number={data.statistics.online_no_return_count}
                      src={icon6}
                    />
                  </Col>
                  <Col span={6}>
                    <NumberCard
                      title="未出人数(实时)"
                      number={data.statistics.online_no_out_count}
                      src={icon1}
                    />
                  </Col>
                </Row> */}
                <Row className={styles.row} gutter={16}>
                  <Col span={6}>
                    <NumberCard
                      title="今日进入人数"
                      number={data.statistics.in_people_count}
                      src={icon6}
                    />
                  </Col>
                  <Col span={6}>
                    <NumberCard
                      title="今日离开人数"
                      number={data.statistics.out_people_count}
                      src={icon1}
                    />
                  </Col>
                  <Col span={6}>
                    <NumberCard title="总床数" number={bed_count} src={icon2} />
                  </Col>
                  <Col span={6}>
                    {/* <NumberCard
                      title="多日未出人数"
                      number={data.statistics.many_no_out_count}
                      src={icon3}
                    /> */}
                    <CountPeopleComponent>
                      {({ data }) => {
                        let count = 0;
                        // @ts-ignore
                        if (data && data.noPhotoPeople) {
                          // @ts-ignore
                          count = data.noPhotoPeople.count;
                        }
                        return (
                          <NumberCard
                            title="未上传照片人数"
                            number={count}
                            src={icon4}
                          />
                        );
                      }}
                    </CountPeopleComponent>
                  </Col>
                </Row>
              </>
            );
          }}
        </StatisticsBedComponent>
        <CountPeopleComponent>
          {({ data }) => {
            const {
              count,
              boy_count,
              girl_count,
              out_count,
              in_count,
            } = getStudentCount(data);
            return (
              <Row className={styles.row} gutter={16}>
                <Col span={18}>
                  <div className={styles.box}>
                    <div className={styles.all_number}>
                      <div className={styles.total_number_div}>
                        总人数{' '}
                        <span
                          className={`normal_number ${styles.total_number}`}
                        >
                          {Number(count).toLocaleString()}
                        </span>
                      </div>
                      <div className={styles.people_number}>
                        <img className={`people_icon`} alt="女" src={girl} />
                        <div>
                          <span className={`normal_number`}>
                            {Number(girl_count).toLocaleString()}
                          </span>{' '}
                          <br />
                          女士总人数
                        </div>
                      </div>
                      <div className={styles.people_number}>
                        <img className={`people_icon`} alt="男" src={boy} />
                        <div>
                          <span className={`normal_number`}>
                            {Number(boy_count).toLocaleString()}
                          </span>{' '}
                          <br />
                          男士总人数
                        </div>
                      </div>
                    </div>

                    <div className={styles.floor}>
                      <DormCountComponent>
                        {({ data }) => {
                          if (!data || !data.dorms) {
                            return '';
                          }
                          return data.dorms.list.map(
                            ({ id, number, people_count, people_in_count }) => {
                              return (
                                <Floor
                                  key={id}
                                  className={styles.floor_item}
                                  number={number}
                                >
                                  <FloorNumber
                                    people_in_count={people_in_count}
                                    people_count={people_count}
                                  />
                                </Floor>
                              );
                            },
                          );
                        }}
                      </DormCountComponent>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={`${styles.box} ${styles.card}`}>
                    在寝情况
                    <br />
                    <span className={`normal_number`}>
                      {Number(in_count).toLocaleString()}
                    </span>
                    <ChartFirst
                      data={[
                        {
                          item: '在寝',
                          count: in_count,
                        },
                        {
                          item: '外出',
                          count: out_count,
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            );
          }}
        </CountPeopleComponent>

        {/* <Row className={styles.row} gutter={16}>
          <Col span={12}>
            <div className={styles.box}>
              黑名单数量
              <span className={`normal_number`}>
                {Number(3).toLocaleString()}/{Number(268).toLocaleString()}
              </span>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              陌生人数量
              <span className={`normal_number`}>
                {Number(12980).toLocaleString()}
              </span>
            </div>
          </Col>
        </Row> */}
        {/* <Row className={styles.row}>
          <Col span={6}>
            <div className={styles.box}>1</div>
          </Col>
          <Col span={6}>
            <div className={styles.box}>2</div>
          </Col>
          <Col span={12}>
            <div className={styles.box}>3</div>
          </Col>
        </Row> */}
        {/* <Row className={styles.row} gutter={16}>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.last_title}>
                晚归人数（昨日）&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={`normal_number`}>
                  {Number(360).toLocaleString()}/
                  {Number(126560).toLocaleString()}
                </span>
              </div>
              <DormLateComponent>
                {({ data }) => {
                  if (!data || !data.dorms) {
                    return '';
                  }
                  const late_data = data.dorms.list.map(
                    ({ number, late_count }) => ({
                      floor: number,
                      count: late_count,
                    }),
                  );
                  return (
                    <ChartThird bottom="各宿舍晚归人数统计" data={late_data} />
                  );
                }}
              </DormLateComponent>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.last_title}>
                未归人数（昨日）&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={`normal_number`}>
                  {Number(360).toLocaleString()}/
                  {Number(126560).toLocaleString()}
                </span>
              </div>
              <DormNoReturnComponent>
                {({ data }) => {
                  if (!data || !data.dorms) {
                    return '';
                  }
                  const no_return_data = data.dorms.list.map(
                    ({ number, no_return_count }) => ({
                      floor: number,
                      count: no_return_count,
                    }),
                  );
                  return (
                    <ChartThird
                      bottom="各宿舍未归人数统计"
                      data={no_return_data}
                    />
                  );
                }}
              </DormNoReturnComponent>
            </div>
          </Col>
        </Row> */}
        <Row className={styles.row} gutter={16}>
          <Col span={24}>
            <div className={styles.box}>
              <StatisticsComponent>
                {({ data }) => {
                  if (!data || !data.statistics) {
                    return '';
                  }
                  return (
                    <>
                      <div className={styles.last_title}>
                        宿舍通行量&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={`normal_number`}>
                          {Number(
                            data.statistics.today_dorm_flow_count,
                          ).toLocaleString()}
                        </span>
                      </div>
                      <ChartSecond data={data.statistics.dorm_flow_counts} />
                    </>
                  );
                }}
              </StatisticsComponent>

              <DormFlowComponent>
                {({ data }) => {
                  if (!data || !data.dorms) {
                    return '';
                  }
                  return (
                    <div className={styles.floor}>
                      {data.dorms.list.map(dorm => {
                        return (
                          <Floor
                            key={dorm.id}
                            className={styles.floor_item}
                            number={dorm.number}
                          >
                            <FloorCount number={dorm.today_flow_count} />
                          </Floor>
                        );
                      })}
                    </div>
                  );
                }}
              </DormFlowComponent>
            </div>
          </Col>
          {/* <Col span={6}>
            <div
              className={styles.box}
              style={{ paddingTop: '160px', height: '500px' }}
            >
              <div style={{ margin: '0 auto', width: '250px' }}>
                <CountPeopleComponent>
                  {({ data }) => {
                    let count = 0;
                    // @ts-ignore
                    if (data && data.noPhotoStudent) {
                      // @ts-ignore
                      count = data.noPhotoStudent.count;
                    }
                    return (
                      <NumberCard
                        title="未上传照片人数"
                        number={count}
                        src={icon4}
                      />
                    );
                  }}
                </CountPeopleComponent>
              </div>
            </div>
          </Col> */}
        </Row>
      </BaseLayout>
    </div>
  );
};

export default Index;
