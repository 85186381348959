import axios from 'axios';
import { api_uri } from '../config';

export const api = axios.create({
  baseURL: api_uri,
  // baseURL: '/api/',
  timeout: 2000,
  headers: { 'Content-Type': 'application/json' },
  transformRequest(data, headers) {
    // const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    headers.common['Authorization'] = 'Bearer ' + token;
    return JSON.stringify(data);
  },
});
