import React from 'react';
import { Button, Modal, Form, Input } from 'antd';

import { CreateNoReturnRecordNoteComponent } from '../../apollo';
import { message } from '../../components/message';

class CreateNoteModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateNoReturnRecordNote: {
                note: values.note,
                id: this.props.id,
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'inline-block', marginLeft: '18px' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          添加备注
        </Button>
        <Modal
          title="备注"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="备注">
              {getFieldDecorator('note', {
                initialValue: this.props.note,
              })(
                <Input.TextArea autosize={{ minRows: 5 }} placeholder="备注" />,
              )}
            </Form.Item>
            <Form.Item style={{ marginTop: '12px' }}>
              <CreateNoReturnRecordNoteComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确定
                    </Button>
                  );
                }}
              </CreateNoReturnRecordNoteComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const CreateNoteModalForm: any = Form.create({
  name: 'create_student',
})(CreateNoteModal);
