import { getErrorColumns } from '../../lib';
import moment from 'moment';

export const original_columns = [
  {
    title: '错误原因',
    dataIndex: 'error_msg',
    key: 'error_msg',
    width: 200,
  },
  {
    title: '姓名',
    width: 80,
  },
  {
    title: '手机号码',
  },
  {
    title: '职工号',
  },
  {
    title: '入职时间',
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '宿舍楼号',
  },
  {
    title: '备注',
  },
];

export const columns = getErrorColumns(original_columns);
