import React from 'react';
import { Button, Modal, Checkbox, Row, Col, Input, message } from 'antd';
import styles from './account.module.css';
import { base, permission, findGroup } from './roles.db';
import NoAccess from '../../components/no-access';
import {
  RolesAllComponent,
  UpdateRoleComponent,
  CreateRoleComponent,
} from '../../apollo';
import { BaseLayout } from '../../layout/base-layout';

const CheckboxGroup = Checkbox.Group;

export class Roles extends React.Component<any, any> {
  state = {
    visible: false,
    checked: base,
    role_id: '',
    name: '',
  };

  onChange = (checkedList: any, key: string) => {
    this.setState({
      checked: {
        ...this.state.checked,
        [key]: checkedList,
      },
    });
  };

  onCheckAllChange = (e: any, key: string) => {
    const checked = e.target.checked;
    this.setState({
      checked: {
        ...this.state.checked,
        [key]: checked ? permission[key] : [],
      },
    });
  };
  setName = (e: any) => {
    if (e.target.value.length > 10) {
      return false;
    }
    this.setState({
      name: e.target.value,
    });
  };
  ok = async (send: any, update: any, refetch: any) => {
    const { role_id, checked, name } = this.state;
    // console.log('role_id', this.state.role_id);
    // console.log('checked', this.state.checked);
    const items: any = [];
    Object.values(checked).forEach((key: any) => {
      key.forEach((item: any) => {
        if (!items.includes(item)) {
          items.push(item);
        }
      });
    });
    if (items.length === 0) {
      return message.error('请选择权限');
    }
    if (!name) {
      return message.error('请输入角色名称');
    }
    try {
      if (role_id) {
        await update({
          variables: {
            UpdateRole: {
              id: role_id,
              name,
              items,
            },
          },
        });
      } else {
        await send({
          variables: {
            CreateRole: {
              name,
              items,
            },
          },
        });
      }
      await refetch();
      this.setState({
        visible: false,
        checked: base,
        role_id: '',
        name: '',
      });
      message.success('操作成功');
    } catch (e) {
      message.error(e.message.replace('GraphQL error:', ''));
    }
  };
  chooseItem = (role: any) => {
    const checked: any = {};
    role.permissions.forEach((permission: any) => {
      const group = findGroup(permission.name);
      if (checked[group] && !checked[group].includes(permission.name)) {
        checked[group].push(permission.name);
      } else {
        checked[group] = [permission.name];
      }
    });
    this.setState({
      visible: true,
      role_id: role.id,
      name: role.name,
      checked: {
        ...base,
        ...checked,
      },
    });
  };
  render() {
    return (
      <BaseLayout>
        <Button
          type="primary"
          onClick={() => this.setState({ visible: true, checked: base })}
        >
          添加角色
        </Button>
        <RolesAllComponent fetchPolicy="no-cache">
          {({ data, error, loading, refetch }) => {
            if (error) {
              if (error.message.includes('Access denied')) {
                return <NoAccess />;
              }
            }
            if (loading || error || !data) {
              return '';
            }
            const roles = data.roles.filter(role => {
              return role.name !== 'back_admin';
            });
            return (
              <div className={styles.account_list}>
                <CreateRoleComponent>
                  {(send, { loading }) => {
                    return (
                      <UpdateRoleComponent>
                        {(update, { loading }) => {
                          return (
                            <Modal
                              visible={this.state.visible}
                              width={1000}
                              onCancel={() =>
                                this.setState({
                                  visible: false,
                                  checked: base,
                                  role_id: '',
                                  name: '',
                                })
                              }
                              onOk={() => this.ok(send, update, refetch)}
                              maskClosable={false}
                              title={
                                this.state.role_id ? '修改角色' : '添加角色'
                              }
                            >
                              <div>
                                <Row gutter={8}>
                                  <Col span={4}>角色名称：</Col>
                                  <Col span={12}>
                                    <Input
                                      value={this.state.name}
                                      placeholder="请输入角色名称（不超过10个字）"
                                      onChange={this.setName}
                                    />
                                  </Col>
                                </Row>
                                {Object.keys(permission).map(key => {
                                  return (
                                    <Row
                                      key={key}
                                      className={styles.check_group}
                                    >
                                      <Col span={4}>
                                        <Checkbox
                                          indeterminate={
                                            this.state.checked[key].length >
                                              0 &&
                                            this.state.checked[key].length !==
                                              permission[key].length
                                          }
                                          onChange={e =>
                                            this.onCheckAllChange(e, key)
                                          }
                                          checked={
                                            this.state.checked[key].length ===
                                            permission[key].length
                                          }
                                          className={styles.check_first}
                                        >
                                          {key}
                                        </Checkbox>
                                      </Col>
                                      <Col span={20}>
                                        <CheckboxGroup
                                          options={permission[key]}
                                          value={this.state.checked[key]}
                                          onChange={value =>
                                            this.onChange(value, key)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </div>
                            </Modal>
                          );
                        }}
                      </UpdateRoleComponent>
                    );
                  }}
                </CreateRoleComponent>
                {roles.map(role => {
                  return (
                    <div key={role.id} className={styles.role_item}>
                      <span className={styles.phone}>{role.name}</span>
                      {role.name !== 'super_admin' && (
                        <Button
                          type="primary"
                          onClick={() => this.chooseItem(role)}
                        >
                          编辑
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }}
        </RolesAllComponent>
      </BaseLayout>
    );
  }
}
