import React from 'react';
import moment from 'moment';
import { api_uri } from '../../config';

export const columns = [
  {
    title: '头像',
    dataIndex: 'photo',
    key: 'photo',
    width: 160,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="头像"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/blacklist/' + text}
        />
      ),
  },
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    width: 180,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 260,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: 360,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
  },
];
