import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { StructuresComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './structure.columns';
import { mapData } from '../../lib';
import { CreateStructureModal } from './create-structure';
import { company_name } from '../../config';

function getChildren(data: any[], last_father_number: string) {
  let children = data.filter(
    ({ father_number }) => father_number === last_father_number,
  );
  if (children.length > 0) {
    children = children
      .map(child => {
        const new_children = getChildren(data, child.number);
        if (new_children.length > 0) {
          child.children = new_children;
        }
        child.key = child.id;
        return child;
      })
      .sort(
        ({ sort_number: sort_number1 }, { sort_number: sort_number2 }) =>
          sort_number1 - sort_number2,
      );
  }
  return children;
}

function getData(old_data: any) {
  const { data, count } = mapData(old_data, 'structures', (structure: any) => {
    const father = old_data['structures'].list.find(
      ({ number }: any) => number === structure.father_number,
    );
    let father_name = company_name;
    if (father) {
      father_name = father.name;
    }
    return {
      key: structure.id,
      father_name,
      ...structure,
    };
  });
  if (data.length > 0) {
    const new_data = getChildren(data, '0');
    return { data: new_data, count };
  }
  return { data, count };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;
// const base_titles = ['会议名称', '日期', '会议室', '创建者', '操作'];

export class Structure extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <StructuresComponent
          variables={{
            skip,
            take: 100,
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch, loading }) => {
            if (error) {
              return <>Access denied</>;
            }
            // 因为 table 默认展开只对首次加载有效
            if (!data || !data.structures) {
              return '';
            }
            const result = getData(data);
            return (
              <div className="table_container">
                <TableTitle name={`部门管理`} number={result.count} />
                <TableSearch
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 100,
                    ...this.state.query_params,
                  }}
                >
                  <CreateStructureModal refetch={refetch} />
                </TableSearch>
                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={columns(refetch)}
                  loading={data && data.structures ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 100,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                  defaultExpandAllRows={true}
                />
              </div>
            );
          }}
        </StructuresComponent>
      </BaseLayout>
    );
  }
}
