// import React from 'react';
// import { Card, Icon, List } from 'antd';
// import { BaseLayout } from '../../layout/base-layout';
// import { AddBlacklistModalForm } from './create-blacklist';
// import { BlacklistsComponent } from '../../apollo';
// import { oss_uri } from '../../config';
// import { ChangeBlacklistModalForm } from './update-blacklist';
// import { DeleteBlacklistModal } from './delete-blacklist';

// const { Meta } = Card;

// class Blacklist extends React.Component {
//   render() {
//     return (
//       <BaseLayout>
//         <BlacklistsComponent>
//           {({ data, error, loading, refetch }) => {
//             if (error || loading || !data) {
//               return '';
//             }
//             return (
//               <>
//                 <AddBlacklistModalForm refetch={refetch} />
//                 <br />
//                 <List
//                   grid={{ gutter: 16, column: 4 }}
//                   dataSource={data.blacklists.list}
//                   renderItem={item => (
//                     <List.Item>
//                       <Card
//                         cover={
//                           <img
//                             alt="example"
//                             style={{ height: '120px' }}
//                             src={oss_uri + item.photo}
//                           />
//                         }
//                         actions={[
//                           <ChangeBlacklistModalForm refetch={refetch} car={item} />,
//                           <DeleteBlacklistModal refetch={refetch} id={item.id}/>,
//                         ]}
//                       >
//                         <Meta
//                           title={item.name}
//                           description={
//                             <div>
//                               价格：2小时起约
//                               <br />
//                               单约2小时 135元/1小时*2=270元
//                               <br />
//                               在大众点评网团购优惠
//                             </div>
//                           }
//                         />
//                       </Card>
//                     </List.Item>
//                   )}
//                 />
//               </>
//             );
//           }}
//         </BlacklistsComponent>
//       </BaseLayout>
//     );
//   }
// }

// export default Blacklist;

import React from 'react';

import queryString from 'query-string';
import { Row, Col } from 'antd';

import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './blacklist.columns';

import { AddBlacklistModalForm } from './create-blacklist';
import { BlacklistsComponent, CountBlacklistComponent } from '../../apollo';
import { ChangeBlacklistModalForm } from './update-blacklist';
import { DeleteBlacklistModal } from './delete-blacklist';
import NoAccess from '../../components/no-access';

function getData(data: any) {
  const list_data = data.blacklists;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            key: item.id,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

export default class Blacklist extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <BlacklistsComponent
          variables={{
            skip,
            take: 10,
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              if (
                error.message.includes('Access denied') ||
                error.message.includes('Forbidden resource')
              ) {
                return <NoAccess />;
              }
              return <>Access denied</>;
            }
            const result = getData(data);

            const new_columns = columns.map(item => {
              if (item.key === 'action') {
                return {
                  title: '操作',
                  width: 80,
                  key: 'action',
                  render: (_: string, record: any) => (
                    <Row gutter={16}>
                      <Col className="gutter-row" span={12}>
                        <ChangeBlacklistModalForm
                          refetch={refetch}
                          item={record}
                          id={record.key}
                        />
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <DeleteBlacklistModal
                          refetch={refetch}
                          id={record.key}
                        />
                      </Col>
                    </Row>
                  ),
                };
              }
              return item;
            });
            return (
              <div className="table_container">
                <CountBlacklistComponent>
                  {({ data }) => {
                    return (
                      <TableTitle
                        name={`黑名单管理`}
                        number={
                          (data &&
                            data.countBlacklist &&
                            data.countBlacklist.count) ||
                          0
                        }
                      />
                    );
                  }}
                </CountBlacklistComponent>
                <TableSearch
                  style={{ marginTop: '60px' }}
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...this.state.query_params,
                  }}
                >
                  <AddBlacklistModalForm refetch={refetch} />
                </TableSearch>

                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={new_columns}
                  loading={data && data.blacklists ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 10,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </BlacklistsComponent>
      </BaseLayout>
    );
  }
}
