import React from 'react';
import { Modal, Button } from 'antd';

import { message } from '../../components/message';
import { BatchDeleteFloorComponent } from '../../apollo';

const { confirm } = Modal;

export class BatchDeleteFloorModal extends React.Component<any> {
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    const { ids, refetch } = this.props;
    if (!ids || ids.length === 0) {
      return message.info('请选择要删除的选项');
    }
    confirm({
      title: '删除提示',
      content: '确定删除吗？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        try {
          await send({
            variables: {
              ids,
            },
          });
          message.success('删除成功');
          refetch();
        } catch (e) {
          message.error(e);
        }
      },
    });
  };

  render() {
    return (
      <BatchDeleteFloorComponent>
        {send => {
          return (
            <Button
              type="primary"
              style={{
                marginRight: '12px',
                backgroundColor: 'rgb(253, 79, 84)',
                borderColor: 'rgb(253, 79, 84)',
              }}
              onClick={this.handleSubmit.bind(this, send)}
            >
              批量删除
            </Button>
          );
        }}
      </BatchDeleteFloorComponent>
    );
  }
}
