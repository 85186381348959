import React from 'react';
import moment from 'moment';
import { api_uri } from '../../config';

export const columns = [
  {
    title: '头像',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 80,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="头像"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/photo/' + text}
        />
      ),
  },
  {
    title: '底片',
    dataIndex: 'photo',
    key: 'photo',
    width: 80,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="底片"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/photo/' + text}
        />
      ),
    filters: [
      { text: '已上传', value: '已上传' },
      { text: '未上传', value: '未上传' },
    ],
  },
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    width: 80,
  },
  {
    title: '手机号码',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 120,
  },
  {
    title: '职工号',
    dataIndex: 'job_number',
    key: 'job_number',
    width: 120,
  },
  {
    title: '入职时间',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '宿舍楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: 120,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
  },
];
