import React from 'react';

import { BaseLayout } from '../../layout/base-layout';
import { Button, Form, Row, Col, TimePicker, Radio, Modal } from 'antd';
import moment from 'moment';

import { message } from '../../components/message';
import {
  GetFirstWarnRuleComponent,
  UpdateWarnRuleComponent,
} from '../../apollo';

class RuleSetting extends React.Component<any> {
  handleSubmit = (send: any, id: string, refetch: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        Modal.confirm({
          title: '提示',
          content: '确定修改吗？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk: async () => {
            try {
              await send({
                variables: {
                  UpdateWarnRule: {
                    id,
                    start_time: moment(values.start_time).format('HH:mm:ss'),
                    end_time: moment(values.end_time).format('HH:mm:ss'),
                    no_return_start_time: moment(
                      values.no_return_start_time,
                    ).format('HH:mm:ss'),

                    no_return_type: values.no_return_type,
                  },
                },
              });
              message.success('修改成功');
              const { form } = this.props;
              const { resetFields } = form;
              resetFields();
              refetch();
            } catch (e) {
              message.error(e);
            }
          },
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <BaseLayout>
        <GetFirstWarnRuleComponent>
          {({ data, refetch }) => {
            if (!data || !data.getFirstWarnRule) {
              return '';
            }
            const item = data.getFirstWarnRule;
            return (
              <Form>
                <Row gutter={16}>
                  <Col className="gutter-row" span={8}>
                    <Form.Item label="晚归开始计算时间">
                      {getFieldDecorator('start_time', {
                        rules: [
                          {
                            required: true,
                            message: '请输入晚归开始计算时间!',
                          },
                        ],
                        initialValue: moment(item.start_time, 'HH:mm:ss'),
                      })(
                        <TimePicker
                          style={{ marginLeft: '12px' }}
                          format={'HH:mm:ss'}
                          placeholder="选择开始时间"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Form.Item label="晚归结束计算时间">
                      {getFieldDecorator('end_time', {
                        rules: [
                          {
                            required: true,
                            message: '请输入晚归结束计算时间!',
                          },
                        ],
                        initialValue: moment(item.end_time, 'HH:mm:ss'),
                      })(
                        <TimePicker
                          style={{ marginLeft: '12px' }}
                          format={'HH:mm:ss'}
                          placeholder="选择结束时间"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: '30px' }}>
                  <Col className="gutter-row" span={8}>
                    <Form.Item label="未归计算时间">
                      {getFieldDecorator('no_return_start_time', {
                        rules: [
                          { required: true, message: '请输入未归计算时间!' },
                        ],
                        initialValue: moment(
                          item.no_return_start_time,
                          'HH:mm:ss',
                        ),
                      })(
                        <TimePicker
                          style={{ marginLeft: '12px' }}
                          format={'HH:mm:ss'}
                          placeholder="选择计算时间"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Form.Item label="未归计算方式">
                      {getFieldDecorator('no_return_type', {
                        rules: [
                          { required: true, message: '请输入未归计算方式!' },
                        ],
                        initialValue: item.no_return_type,
                      })(
                        <Radio.Group>
                          <Radio value={'前一天'}>前一天</Radio>
                          <Radio value={'当天'}>当天</Radio>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <UpdateWarnRuleComponent>
                    {(send, { loading }) => {
                      return (
                        <Button
                          type="primary"
                          style={{ width: '200px', marginTop: '30px' }}
                          loading={loading}
                          onClick={e =>
                            this.handleSubmit(send, item.id, refetch, e)
                          }
                          className="login-form-button"
                        >
                          确认修改
                        </Button>
                      );
                    }}
                  </UpdateWarnRuleComponent>
                </Form.Item>
              </Form>
            );
          }}
        </GetFirstWarnRuleComponent>
      </BaseLayout>
    );
  }
}

export const RuleSettingForm: any = Form.create({
  name: 'rule-setting',
})(RuleSetting);
