import React from 'react';

import queryString from 'query-string';
import { Row, Col, DatePicker, TimePicker } from 'antd';
import moment from 'moment';

import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import {
  PeopleOnlineComponent,
  OutPeopleOnlineComponent,
  CountPeopleComponent,
  CommonFilterComponent,
  ScreenListChooseComponent,
} from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './people.columns';
import NoAccess from '../../components/no-access';

function getData(data: any) {
  const list_data = data.peopleOnline;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            key: item.id,
            true_name: item.true_name,
            mobile: item.mobile,
            email: item.email,
            created_date: item.created_date,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

export default class People extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    selectedRowKeys: [],
    fixed: false,
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH-00-00'),
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { selectedRowKeys, query_params, date, time } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const new_params = {
      skip,
      take: 10,
      date,
      time,
      ...query_params,
    };
    return (
      <BaseLayout>
        <CommonFilterComponent variables={{ take: 100 }}>
          {({ data, error, loading, refetch }: any) => {
            if (error || loading || !data) {
              return '';
            }
            const dorms = data.dorms.list.map(({ number }: any) => ({
              text: number,
              value: number,
            }));
            const structures = data.structures.list.map(
              ({ number, name }: any) => ({
                text: name,
                value: number,
              }),
            );
            return (
              <ScreenListChooseComponent fetchPolicy="no-cache">
                {({ data, error, refetch, loading }) => {
                  if (error) {
                    return <>Access denied</>;
                  }
                  if (loading || !data) {
                    return '';
                  }
                  const list_choose = Array.from(
                    new Set(data.screen_list_choose.map(({ name }) => name)),
                  ).map(
                    (name: any) => ({
                      text: name,
                      value: name,
                    }),
                  );
                  return (
                    <PeopleOnlineComponent
                      variables={new_params}
                      fetchPolicy="no-cache"
                    >
                      {({ data, error, refetch }) => {
                        if (error) {
                          if (
                            error.message.includes('Access denied') ||
                            error.message.includes('Forbidden resource')
                          ) {
                            return <NoAccess />;
                          }
                          return <>Access denied</>;
                        }
                        const result = getData(data);
                        const new_columns = columns(
                          { dorms, structures, list_choose },
                          refetch,
                        );
                        return (
                          <div className="table_container">
                            {/* <CountPeopleComponent>
                              {({ data }) => {
                                return (
                                  <TableTitle
                                    name={`历史在寝记录`}
                                    number={
                                      (data &&
                                        data.countPeople &&
                                        data.countPeople.count) ||
                                      0
                                    }
                                  />
                                );
                              }}
                            </CountPeopleComponent> */}

                            <TableTitle
                              name={`历史在寝记录`}
                              number={(result && result.count) || 0}
                            />

                            <OutPeopleOnlineComponent>
                              {(send, { loading }) => {
                                return (
                                  <TableSearch
                                    style={{ marginTop: '60px' }}
                                    search={(e: any) =>
                                      this.search(e.target.value)
                                    }
                                    loading={loading}
                                    options={columns_titles}
                                    checked_options={this.state.checked_options}
                                    onCheckAllChange={this.onCheckAllChange}
                                    onColumnChange={this.onColumnChange}
                                    outData={send}
                                    columns={this.state.checked_options}
                                    fixed={this.state.fixed}
                                    fixedName={(fixed: any) => {
                                      this.setState({ fixed });
                                    }}
                                    params={new_params}
                                  >
                                    <DatePicker
                                      style={{ marginLeft: '12px' }}
                                      onChange={(date, dateString) =>
                                        this.setState({ date: dateString })
                                      }
                                      value={date ? moment(date) : undefined}
                                      placeholder="选择结束日期"
                                    />
                                    <TimePicker
                                      style={{ marginLeft: '12px' }}
                                      format={'HH:00:00'}
                                      onChange={(time, timeString) =>
                                        this.setState({
                                          time: moment(timeString, 'HH:00:00'),
                                        })
                                      }
                                      value={
                                        time
                                          ? moment(time, 'HH:00:00')
                                          : undefined
                                      }
                                      placeholder="选择开始时间"
                                    />
                                  </TableSearch>
                                );
                              }}
                            </OutPeopleOnlineComponent>
                            <AllTable
                              scroll={{ x: true }}
                              rowSelection={rowSelection}
                              onChange={(
                                pagination: any,
                                filters: any,
                                sorter: any,
                              ) =>
                                this.onTableChange(
                                  pagination,
                                  filters,
                                  sorter,
                                  refetch,
                                )
                              }
                              columns={new_columns}
                              loading={data && data.peopleOnline ? false : true}
                              dataSource={result.data}
                              pagination={{
                                total: result.count,
                                pageSize: 10,
                                // pageSize: 20,
                              }}
                              checked_options={this.state.checked_options}
                              fixed={this.state.fixed}
                            />
                          </div>
                        );
                      }}
                    </PeopleOnlineComponent>
                  );
                }}
              </ScreenListChooseComponent>
            );
          }}
        </CommonFilterComponent>
      </BaseLayout>
    );
  }
}
