import React from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  TreeSelect,
  Icon,
} from 'antd';

import {
  UpdateStructureComponent,
  StructuresTreeComponent,
} from '../../apollo';
import { message } from '../../components/message';
import { company_name } from '../../config';

const button_name = '更新部门';
const form_name = 'update_structure';

export function getChildren(
  data: any[],
  last_father_number: string,
  item_number: string,
) {
  let children = data.filter(
    ({ father_number, number }) =>
      father_number === last_father_number && number !== item_number,
  );
  if (children.length > 0) {
    children = children.map(child => {
      const new_children = getChildren(data, child.number, item_number);
      if (new_children.length > 0) {
        child.children = new_children;
      }
      child.key = child.id;
      child.title = child.name;
      child.value = child.number;
      return child;
    });
  }
  return children;
}

export function getData(old_data: any, item_number: string) {
  const data = old_data['structures'].list.map((structure: any) => {
    return {
      key: structure.id,
      title: structure.name,
      value: structure.number,
      ...structure,
    };
  });
  if (data.length > 0) {
    const new_data = getChildren(data, '0', item_number);
    return [
      {
        key: '0',
        title: company_name,
        value: '0',
        children: new_data,
      },
    ];
  }
  return data;
}

class UpdateStructureModalForm extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              UpdateStructure: {
                ...values,
                id: this.props.item.id,
                father_number:
                  this.props.item.father_number || '0',
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          form.resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { form, item } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        {/* <Button onClick={() => this.setState({ visible: true })}>
          {button_name}
        </Button> */}
        <Icon
          style={{ marginRight: '12px', color: 'rgb(37, 147, 252)' }}
          type="edit"
          onClick={() => this.setState({ visible: true })}
        />
        <UpdateStructureComponent>
          {(send, { loading }) => {
            return (
              <Modal
                title={button_name}
                visible={visible}
                onCancel={() => this.setState({ visible: false })}
                onOk={this.handleSubmit.bind(this, send)}
                centered
                okText="确认"
                cancelText="取消"
              >
                <Form>
                  {/* <StructuresTreeComponent fetchPolicy="no-cache">
                    {({ data, error, refetch, loading }) => {
                      if (error) {
                        return <>Access denied</>;
                      }
                      if (loading || !data) {
                        return '';
                      }
                      const new_data = getData(data, item.number);
                      return (
                        <Form.Item label="上级部门">
                          {getFieldDecorator('father_number', {
                            rules: [
                              { required: true, message: '请选择上级部门!' },
                            ],
                            initialValue: item.father_number,
                          })(
                            <TreeSelect
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              treeData={new_data}
                              placeholder="请选择"
                              treeDefaultExpandAll
                            />,
                          )}
                        </Form.Item>
                      );
                    }}
                  </StructuresTreeComponent> */}
                  <Form.Item label="部门名称">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: '请输入部门名称!' }],
                      initialValue: item.name,
                    })(<Input placeholder="部门名称" />)}
                  </Form.Item>
                  <Form.Item label="负责人">
                    {getFieldDecorator('leading_cadre', {
                      rules: [{ required: true, message: '请输入负责人!' }],
                      initialValue: item.leading_cadre,
                    })(<Input placeholder="负责人" />)}
                  </Form.Item>
                  <Form.Item label="负责人手机号">
                    {getFieldDecorator('mobile', {
                      rules: [
                        { required: true, message: '请输入负责人手机号!' },
                      ],
                      initialValue: item.mobile,
                    })(<Input placeholder="负责人手机号" />)}
                  </Form.Item>
                  <Form.Item label="排序">
                    {getFieldDecorator('sort_number', {
                      rules: [{ required: true, message: '请输入排序!' }],
                      initialValue: item.sort_number,
                    })(<InputNumber placeholder="排序" />)}
                  </Form.Item>
                </Form>
              </Modal>
            );
          }}
        </UpdateStructureComponent>
      </div>
    );
  }
}

export const UpdateStructureModal: any = Form.create({ name: form_name })(
  UpdateStructureModalForm,
);
