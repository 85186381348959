import React from 'react';
import {
  FullScreenContainer,
  Loading,
  BorderBox1,
  Decoration9,
  Decoration7,
  BorderBox10,
} from '@jiaminghi/data-view-react';
import styles from './index.module.css';
import './index.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Row, Col, Icon, Button } from 'antd';

import { DataV1Component } from '../../apollo';
// import header_img from './header2.png';
import header_img from '../../images/header.png';
import worker from '../../images/worker.png';
import visitor from '../../images/visitor.png';
import { megvii_uri, system_name } from '../../config';
import logo from '../../images/logo.png';

export class DataV1 extends React.Component {
  refetch: any;
  interval: NodeJS.Timeout = setInterval(() => {}, 1000);

  state = {
    date: moment().format('YYYY年MM月DD日 HH:mm:ss'),
    show: true,
    isFullScreen: false,
  };

  setTimeOut = () => {
    setTimeout(async () => {
      if (this.refetch) {
        this.refetch();
      }
      this.setTimeOut();
    }, 3000);
  };

  componentDidMount() {
    this.watchFullScreen();
    // this.setTimeOut();
    this.interval = setInterval(() => {
      this.setState({
        date: moment().format('YYYY年MM月DD日 HH:mm:ss'),
      });
      if (this.refetch) {
        this.refetch();
      }
    }, 1000);
  }

  fullScreen = () => {
    console.log('fullscreen:', this.state.isFullScreen);
    if (!this.state.isFullScreen) {
      this.requestFullScreen();
    } else {
      this.exitFullscreen();
    }
    this.setState({
      isFullScreen: this.state.isFullScreen,
    });
  };

  //进入全屏
  requestFullScreen = () => {
    console.log('requestFullScreen');
    var de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
      // @ts-ignore
    } else if (de.mozRequestFullScreen) {
      // @ts-ignore
      de.mozRequestFullScreen();
      // @ts-ignore
    } else if (de.webkitRequestFullScreen) {
      // @ts-ignore
      de.webkitRequestFullScreen();
    }
  };

  //退出全屏
  exitFullscreen = () => {
    console.log('exitFullscreen');
    var de = document;
    if (de.exitFullscreen) {
      de.exitFullscreen();
      // @ts-ignore
    } else if (de.mozCancelFullScreen) {
      // @ts-ignore
      de.mozCancelFullScreen();
      // @ts-ignore
    } else if (de.webkitCancelFullScreen) {
      // @ts-ignore
      de.webkitCancelFullScreen();
    }
  };

  //监听fullscreenchange事件
  watchFullScreen = () => {
    const _self = this;
    document.addEventListener(
      'webkitfullscreenchange',
      function() {
        _self.setState({
          // @ts-ignore
          isFullScreen: document.webkitIsFullScreen,
        });
      },
      false,
    );
  };

  //清除定时器
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <FullScreenContainer>
        <DataV1Component>
          {({ data, refetch }: any) => {
            if (!data || !data.datav) {
              return <Loading>加载中</Loading>;
            }
            const {
              last_five_in_people,
              last_five_out_people,
              in_people_count,
              out_people_count,
            } = data.datav;
            if (!this.refetch) {
              this.refetch = refetch;
            }
            const last_in_people = last_five_in_people[0];
            const last_out_people = last_five_out_people[0];
            return (
              <div>
                {/* {!this.state.isFullScreen && (
                  <button
                    onClick={this.fullScreen}
                    style={{ position: 'absolute', right: 0, top: 0 }}
                  ></button>
                )} */}
                <div className={styles.header}>
                  <img src={logo} className={styles.logo} />
                  <div className={styles.title}>
                    行政办公区{system_name}公寓入住人员信息统计
                  </div>
                  <img className={styles.header_img} src={header_img} />
                  <div className={styles.date}>{this.state.date}</div>
                </div>
                <div className={styles.hover_link}>
                  <div className={styles.hover_empty}>
                    <div className={styles.link_animated}>
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.fullScreen}
                      >
                        全屏
                      </Button>
                      <Button type="primary" size="large">
                        <Link to="/datav3">在寝人员</Link>
                      </Button>
                      <Button type="primary" size="large">
                        <Link to="/datav2">登记人员</Link>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.middle_line}>
                  <span className={styles.blue_line} />
                  <Decoration7 style={{ width: '260px', height: '30px' }}>
                    <span className={styles.middle_line_title}>
                      &nbsp;&nbsp;&nbsp;实时情况&nbsp;&nbsp;&nbsp;
                    </span>
                  </Decoration7>
                  <span className={styles.blue_line} />
                </div>
                <Row
                  gutter={32}
                  style={{
                    marginTop: '30px',
                    height: '400px',
                    padding: '0 20px',
                  }}
                >
                  <Col className="gutter-row" span={12}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        <div className={styles.people_all_left}>进</div>
                        <img
                          src={visitor}
                          alt="今日进入总人数"
                          className={styles.count_icon}
                        />
                        今日进入总人数{' '}
                        <span
                          style={{
                            fontSize: '38px',
                            color: 'rgb(33, 187, 255)',
                            paddingLeft: '8px',
                          }}
                        >
                          {in_people_count}
                        </span>
                      </div>
                    </BorderBox10>
                    <br />
                    <BorderBox1
                      style={{
                        height: '500px',
                        marginTop: '30px',
                      }}
                    >
                      <div className={styles.first_middle}>
                        <Decoration9
                          style={{ width: '360px', height: '360px' }}
                          className={styles.first_middle_bg}
                        >
                          <img
                            className={styles.photo}
                            src={megvii_uri + last_in_people.megvii_image_uri}
                          />
                        </Decoration9>
                        <Row gutter={16} className={styles.true_name_c}>
                          <Col span={12}>
                            <div className={styles.true_name2}>
                              {last_in_people.people.true_name}{' '}
                              {last_in_people.people.room_number}-
                              {last_in_people.people.bed_number}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className={styles.true_name2}>
                              {last_in_people.people.department}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </BorderBox1>
                    <div className={styles.photo_little_c}>
                      {last_five_in_people.map(
                        ({ megvii_image_uri, id }: any, index: number) => {
                          if (index === 0) {
                            return '';
                          }
                          return (
                            <img
                              key={id}
                              className={styles.photo_little}
                              src={megvii_uri + megvii_image_uri}
                            />
                          );
                        },
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        <div className={styles.people_all_left}>出</div>
                        <img
                          src={worker}
                          alt="今日离开总人数"
                          className={styles.count_icon}
                        />
                        今日离开总人数
                        <span
                          style={{
                            fontSize: '38px',
                            color: 'rgb(33, 187, 255)',
                            paddingLeft: '8px',
                          }}
                        >
                          {out_people_count}
                        </span>
                      </div>
                    </BorderBox10>
                    <br />
                    <BorderBox1
                      style={{
                        height: '500px',
                        marginTop: '30px',
                      }}
                    >
                      <div className={styles.first_middle}>
                        <Decoration9
                          style={{ width: '360px', height: '360px' }}
                          className={styles.first_middle_bg}
                        >
                          <img
                            className={styles.photo}
                            src={megvii_uri + last_out_people.megvii_image_uri}
                          />
                        </Decoration9>
                        <Row gutter={16} className={styles.true_name_c}>
                          <Col span={12}>
                            <div className={styles.true_name2}>
                              {last_out_people.people.true_name}{' '}
                              {last_out_people.people.room_number}-
                              {last_out_people.people.bed_number}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className={styles.true_name2}>
                              {last_out_people.people.department}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </BorderBox1>
                    <div className={styles.photo_little_c}>
                      {last_five_out_people.map(
                        ({ megvii_image_uri, id }: any, index: number) => {
                          if (index === 0) {
                            return '';
                          }
                          return (
                            <img
                              key={id}
                              className={styles.photo_little}
                              src={megvii_uri + megvii_image_uri}
                            />
                          );
                        },
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          }}
        </DataV1Component>
      </FullScreenContainer>
    );
  }
}
