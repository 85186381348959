import React from 'react';
import { withRouter } from 'react-router';

import queryString from 'query-string';
import moment from 'moment';

import { BaseLayout } from '../../layout/base-layout';
import { GetPeopleByIdComponent } from '../../apollo';
import { PeopleRecordTable } from './people-record-table';
import { Row, Col } from 'antd';
import { api_uri } from '../../config';
import empty from '../../images/empty.jpg';
import styles from './index.module.css';
import { ChangePeopleModalForm } from '../people/update-people';
import NoAccess from '../../components/no-access';

class PeopleDetailComponent extends React.Component<any> {
  render() {
    const params = queryString.parse(this.props.location.search);
    return (
      <BaseLayout>
        <GetPeopleByIdComponent
          variables={{
            id: String(params.id),
          }}
        >
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              if (error) {
                if (
                  error.message.includes('Access denied') ||
                  error.message.includes('Forbidden resource')
                ) {
                  return <NoAccess />;
                }
              }
              return '';
            }
            const user = data.getPeopleById;
            return (
              <>
                <div>
                  <Row gutter={16}>
                    <Col className="gutter-row" span={3}>
                      <img
                        alt="头像"
                        style={{ width: 80, height: 80 }}
                        src={
                          user.photo
                            ? api_uri + '/file/photo/' + user.photo
                            : empty
                        }
                      />
                    </Col>
                    <Col
                      className="gutter-row"
                      span={6}
                      style={{ paddingTop: '12px' }}
                    >
                      <span style={{ fontSize: '18px' }}>{user.true_name}</span>
                      <br />
                    性别：{user.sex}
                    </Col>
                    <Col
                      className="gutter-row"
                      span={6}
                      style={{ paddingTop: '18px' }}
                    >
                      <br />
                    手机号：{user.mobile}
                    </Col>
                    <Col
                      className="gutter-row"
                      span={9}
                      style={{ textAlign: 'right' }}
                    >
                      <ChangePeopleModalForm
                        refetch={refetch}
                        item={user}
                        id={user.id}
                        button={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: '20px' }}>
                    <Col className="gutter-row" span={6}>
                      部门：
                    <br />
                      {user.father_department || '无'}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      分部门：
                    <br />
                      {user.department}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      服务部门：
                    <br />
                      {user.to_department}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      入住时间：
                    <br />
                      {user.start_date &&
                        moment(user.start_date).format('YYYY年MM月DD日')}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    <Col className="gutter-row" span={6}>
                      宿舍楼号：
                    <br />
                      {user.dorm_number}
                    </Col>
                    {/* <Col className="gutter-row" span={6}>
                    宿舍类型：
                    <br />
                    {user.dorm_type}
                  </Col> */}
                    <Col className="gutter-row" span={6}>
                      宿舍号：
                    <br />
                      {user.room_number}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      床号：
                    <br />
                      {user.bed_number}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      在寝状态：
                    <br />
                      {user.in_status || '外出'}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    <Col className="gutter-row" span={6}>
                      人员类型：
                    <br />
                      {user.type}
                    </Col>
                    <Col className="gutter-row" span={6}>
                      房间卡号：
                    <br />
                      {user.room_card_number}
                    </Col>
                    <Col className="gutter-row" span={12}>
                      身份证号：
                    <br />
                      {user.id_card}
                    </Col>
                  </Row>
                </div>
                <PeopleRecordTable
                  user_id={params.id}
                  subject_id={user.subject_id}
                />
              </>
            );
          }}
        </GetPeopleByIdComponent>
      </BaseLayout>
    );
  }
}

export const PeopleDetail = withRouter(PeopleDetailComponent);
