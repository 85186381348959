import React from 'react';
import { Modal } from 'antd';

import { api_uri } from '../../config';
import styles from './index.module.css';
import floor from './floor.png';
import ReactZmage from 'react-zmage';

export class CatModal extends React.Component<any> {
  state = {
    visible: false,
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { uri } = this.props;
    return (
      <>
        <div
          className={`${styles.statistics_view} hand`}
          onClick={() => this.setState({ visible: true })}
        >
          <img
            alt="住户"
            style={{
              width: '24px',
              height: '24px',
            }}
            src={floor}
          />
          <span style={{ color: 'rgb(0, 138, 255)' }}>平面图</span>
        </div>
        <Modal
          title="楼层图"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={1180}
        >
          {/* <img
            style={{ width: '1140px' }}
            src={api_uri + '/file/floor/' + uri}
          /> */}
          <ReactZmage
            // style={{ width: '1140px' }}
            src={api_uri + '/file/floor/' + uri}
          />
        </Modal>
      </>
    );
  }
}
