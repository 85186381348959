import React from 'react';

import queryString from 'query-string';

import {
  BaseTable,
  TableTitle,
  TableTimeSearch,
  OutSearch,
} from '../../components/base-table';
import {
  RecognitionResultsComponent,
  CommonFilterComponent,
  OutRecognitionResultsComponent,
} from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './people-record.columns';
import NoAccess from '../../components/no-access';

function getData(data: any) {
  const list_data = data.recognitionResults;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            user_id: item.people && item.people.id,
            key: item.id,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

export default class PeopleRecord extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
    },
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { start_date, end_date, start_time, end_time } = this.state;
    return (
      <BaseLayout>
        <CommonFilterComponent variables={{ take: 100 }}>
          {({ data, error, loading, refetch }: any) => {
            if (error || loading || !data) {
              return '';
            }
            const dorms = data.dorms.list.map(({ number }: any) => ({
              text: number,
              value: number,
            }));
            const structures = data.structures.list.map(
              ({ number, name }: any) => ({
                text: name,
                value: number,
              }),
            );
            return (
              <RecognitionResultsComponent
                variables={{
                  skip,
                  take: 10,
                  type: '人员',
                  ...this.state.query_params,
                }}
                fetchPolicy="no-cache"
              >
                {({ data, error, refetch }) => {
                  if (error) {
                    if (
                      error.message.includes('Access denied') ||
                      error.message.includes('Forbidden resource')
                    ) {
                      return <NoAccess />;
                    }
                    return <>Access denied</>;
                  }
                  const result = getData(data);

                  const new_columns = columns({ dorms, structures });
                  return (
                    <div className="table_container">
                      {/* <CountPeopleComponent>
                  {({ data }) => { */}
                      {/* return  */}
                      <TableTitle name={`出入记录`} />
                      {/* }}
                </CountPeopleComponent> */}
                      <TableTimeSearch
                        start_date={start_date}
                        end_date={end_date}
                        start_time={start_time}
                        end_time={end_time}
                        onChange={(name, value) =>
                          this.setState({ [name]: value })
                        }
                        reset={() =>
                          this.setState({
                            query_params: {
                              ...this.state.query_params,
                              start_date: null,
                              end_date: null,
                              start_time: null,
                              end_time: null,
                            },
                            start_date: null,
                            end_date: null,
                            start_time: null,
                            end_time: null,
                          })
                        }
                        search={() =>
                          this.setState({
                            query_params: {
                              ...this.state.query_params,
                              start_date,
                              end_date,
                              start_time,
                              end_time,
                              type: '人员',
                            },
                          })
                        }
                      >
                        <OutRecognitionResultsComponent>
                          {(send, { loading }) => {
                            return (
                              <OutSearch
                                // style={{ marginTop: '60px' }}
                                options={columns_titles}
                                checked_options={this.state.checked_options}
                                onCheckAllChange={this.onCheckAllChange}
                                outData={send}
                                loading={loading}
                                columns={this.state.checked_options}
                                params={{
                                  skip,
                                  take: 10,
                                  ...this.state.query_params,
                                  start_date,
                                  end_date,
                                  start_time,
                                  end_time,
                                  type: '人员',
                                }}
                              />
                            );
                          }}
                        </OutRecognitionResultsComponent>
                        <OutRecognitionResultsComponent>
                          {(send, { loading }) => {
                            return (
                              <OutSearch
                                // style={{ marginTop: '60px' }}
                                options={columns_titles}
                                checked_options={this.state.checked_options}
                                onCheckAllChange={this.onCheckAllChange}
                                outData={send}
                                loading={loading}
                                group
                                columns={this.state.checked_options}
                                params={{
                                  skip,
                                  take: 10,
                                  ...this.state.query_params,
                                  is_group: true,
                                  start_date,
                                  end_date,
                                  start_time,
                                  end_time,
                                  type: '人员',
                                }}
                              />
                            );
                          }}
                        </OutRecognitionResultsComponent>
                      </TableTimeSearch>

                      {/* <TableSearch
                  style={{ marginTop: '60px' }}
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  // outData={send}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...this.state.query_params,
                  }}
                ></TableSearch> */}
                      <AllTable
                        scroll={{ x: true }}
                        onChange={(
                          pagination: any,
                          filters: any,
                          sorter: any,
                        ) =>
                          this.onTableChange(
                            pagination,
                            filters,
                            sorter,
                            refetch,
                          )
                        }
                        columns={new_columns}
                        loading={data && data.recognitionResults ? false : true}
                        dataSource={result.data}
                        pagination={{
                          total: result.count,
                          pageSize: 10,
                          // current: skip / 10
                        }}
                        checked_options={this.state.checked_options}
                        fixed={this.state.fixed}
                      />
                    </div>
                  );
                }}
              </RecognitionResultsComponent>
            );
          }}
        </CommonFilterComponent>
      </BaseLayout>
    );
  }
}
