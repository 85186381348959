import React from 'react';
import { Row, Col, Button } from 'antd';
import moment from 'moment';

import ExportExcel from '../../lib/xlsx';

export const columns = [
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
    width: 160,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '总人数',
    dataIndex: 'people_count',
    key: 'people_count',
    width: 120,
  },
  {
    title: '入住人员总人数',
    dataIndex: 'common_people_count',
    key: 'common_people_count',
    width: 120,
  },
  {
    title: '内部员工总人数',
    dataIndex: 'work_people_count',
    key: 'work_people_count',
    width: 120,
  },
  {
    title: '女士总人数',
    dataIndex: 'girl_count',
    key: 'girl_count',
    width: 120,
  },
  {
    title: '男士总人数',
    dataIndex: 'boy_count',
    key: 'boy_count',
    width: 120,
  },
  {
    title: '总床数',
    dataIndex: 'bed_count',
    key: 'bed_count',
    width: 120,
  },
  {
    title: '在寝人数',
    dataIndex: 'now_in_count',
    key: 'now_in_count',
    width: 120,
  },
  {
    title: '未在寝人数',
    dataIndex: 'now_out_count',
    key: 'now_out_count',
    width: 120,
  },
  {
    title: '入住人员在寝人数',
    dataIndex: 'common_now_in_count',
    key: 'common_now_in_count',
    width: 120,
  },
  {
    title: '入住人员未在寝人数',
    dataIndex: 'common_now_out_count',
    key: 'common_now_out_count',
    width: 120,
  },
  {
    title: '进入人数',
    dataIndex: 'in_people_count',
    key: 'in_people_count',
    width: 120,
  },
  {
    title: '离开人数',
    dataIndex: 'out_people_count',
    key: 'out_people_count',
    width: 120,
  },
  {
    title: '进入总次数',
    dataIndex: 'in_count',
    key: 'in_count',
    width: 120,
  },
  {
    title: '离开次数',
    dataIndex: 'out_count',
    key: 'out_count',
    width: 120,
  },
  {
    title: '未上传照片人数',
    dataIndex: 'no_photo_count',
    key: 'no_photo_count',
    width: 120,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
        <Button
            type="primary"
            onClick={() =>
              ExportExcel(columns, [record], `${record.date}-日报`)
            }
          >
            下载
          </Button>
        </Col>
      </Row>
    ),
  },
];
