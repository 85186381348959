import React from 'react';

import queryString from 'query-string';

import XLSX2 from 'xlsx';
import XLSX from 'xlsx-style';

import { Button } from 'antd';

import {
  BaseTable,
} from '../../components/base-table';

import { DailiesComponent } from '../../apollo';
import { BaseLayout } from '../../layout/base-layout';

import styles from './index.module.css'

declare global {
  interface Window {
    MouseEvent: typeof MouseEvent;
  }
}

export default class ShiftExport extends BaseTable {

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  sheet2blob = (sheet: any, sheetName?: any) => {
    sheetName = sheetName || 'sheet1';
    var workbook: any = {
      SheetNames: [sheetName],
      Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

    var wopts: any = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream"
    }); // 字符串转ArrayBuffer
    function s2ab(s: any) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    return blob;
  }

  openDownloadDialog = (url: any, saveName: any) => {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
  }

  exportExcel = () => {
    const sheet = XLSX2.utils.table_to_sheet(document.getElementById('table-to-xls'))
    Object.keys(sheet).forEach((key, index) => {
      if (/^[A-Z]\d{1,}$/g.test(key)) {
        sheet[key].s = {
          font: key === 'A1'
            ? { sz: 16, bold: true }
            : { sz: 11 },
          alignment: {
            horizontal: "center", vertical: "center", wrapText: true
          },
        };
      }
    })
    const back_cells: any = {
      'ace0a1': ['A1'],
    }
    Object.keys(back_cells).forEach(color => {
      if (back_cells[color]) {
        back_cells[color].map((cell: string) => {
          if (!sheet[cell]) return
          sheet[cell].s.fill = {
            fgColor: {
              rgb: color
            },
            bgColor: {
              indexed: 64
            },
            patternType: 'solid'
          }
          sheet[cell].s.border = {
            top: {
              style: 'thin',
              color: { rgb: 'aaa9a9' }
            },
            bottom: {
              style: 'thin',
              color: { rgb: 'aaa9a9' }
            },
            left: {
              style: 'thin',
              color: { rgb: 'aaa9a9' }
            },
            right: {
              style: 'thin',
              color: { rgb: 'aaa9a9' }
            }
          }
        })
      }
    })
    sheet['!cols'] = Array(10).fill(16).map((item, index) => {
      return { wpx: 650 / 16 };
    }) //单元格列宽
    let row_count: any = sheet['!ref'] && sheet['!ref'].split(':')[1];
    row_count = Number(row_count.slice(1, row_count.length));
    sheet['!rows'] = Array.from(new Array(row_count)).map((item, index) => {
      return { hpx: 30 };
    }); //单元格列高
    this.openDownloadDialog(this.sheet2blob(sheet), '下载.xlsx');
  }

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <DailiesComponent
          variables={{
            skip,
            take: 10,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            return (
              <div className="table_container">
                <Button
                  type="primary"
                  onClick={this.exportExcel}
                >
                  导出
                </Button>
                <table
                  id='table-to-xls'
                  className={styles.shift_table}
                >
                  <thead>
                    <tr>
                      <th className={styles.sec_title} colSpan={16}>11层加值班入住统计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={7}>单位</td>
                      <td colSpan={3}>实际入住人数</td>
                      <td colSpan={3}>身体健康情况</td>
                      <td colSpan={3}>入住时间</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr>
                      <td colSpan={7}>发改委</td>
                      <td colSpan={2}>88</td>
                      <td>人</td>
                      <td colSpan={3}>正常</td>
                      <td colSpan={3}>2020.4.12</td>
                    </tr>
                    <tr className={styles.statistic}>
                      <td colSpan={7}>累计入住人数</td>
                      <td colSpan={6}>50</td>
                      <td colSpan={3}>人</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          }}
        </DailiesComponent>
      </BaseLayout>
    );
  }
}
