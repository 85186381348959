import React from 'react';

import queryString from 'query-string';

import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { DailiesComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './daily.columns';

function getData(data: any) {
  const list_data = data.dailies;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          true_name: item.true_name,
          mobile: item.mobile,
          email: item.email,
          created_date: item.created_date,
        };
      })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

export default class Dailies extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    selectedRowKeys: [],
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { selectedRowKeys, query_params } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <BaseLayout>
        <DailiesComponent
          variables={{
            skip,
            take: 10,
            ...query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            const result = getData(data);
            const new_columns = columns;
            return (
              <div className="table_container">
                <TableTitle name={`日报管理`} />
                <TableSearch
                  hideSearch
                  style={{ marginTop: '60px' }}
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...query_params,
                  }}
                ></TableSearch>
                <AllTable
                  scroll={{ x: true }}
                  // rowSelection={rowSelection}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={new_columns}
                  loading={data && data.dailies ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 10,
                    // pageSize: 20,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </DailiesComponent>
      </BaseLayout>
    );
  }
}
