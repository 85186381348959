import React from 'react';
import { DeleteFloorModal } from './delete-floor';
import { Row, Col } from 'antd';
import moment from 'moment';
import { CatModal } from './cat-modal';
import { ChangeFloorModalForm } from './update-floor';

export const columns = (refetch?: any, filter?: any) => [
  {
    title: '楼号',
    dataIndex: 'dorm_numbers',
    key: 'dorm_numbers',
    width: 80,
    filters: filter && filter.dorms,
    render: (_: any, record: any) => record.dorm_number,
  },
  {
    title: '编号',
    dataIndex: 'number',
    key: 'number',
    width: 120,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '楼层图',
    dataIndex: 'uri',
    key: 'uri',
    width: 60,
    render: (text: string) => text && <CatModal uri={text} />,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <ChangeFloorModalForm item={record} refetch={refetch} />
        </Col>
        <Col className="gutter-row" span={12}>
          <DeleteFloorModal id={record.key} refetch={refetch} />
        </Col>
      </Row>
    ),
  },
];
