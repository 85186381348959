export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 80,
  },
  {
    title: '开始时间',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 120,
  },
  {
    title: '结束时间',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 120,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
  },
];
