import React from 'react';
import { Button, Modal, Form, InputNumber, Row, Col } from 'antd';

import { CreateRoomComponent } from '../../apollo';
import { message } from '../../components/message';

class AddRoomModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateRoom: {
                ...values,
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          新增房间
        </Button>
        <Modal
          title="新增房间"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label="宿舍楼号">
                  {getFieldDecorator('dorm_number', {
                    rules: [{ required: true, message: '请输入宿舍楼号!' }],
                  })(
                    <InputNumber
                      style={{ width: 220 }}
                      placeholder="宿舍楼号"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label="楼层">
                  {getFieldDecorator('floor_number', {
                    rules: [{ required: true, message: '请输入楼层!' }],
                  })(<InputNumber style={{ width: 220 }} placeholder="楼层" />)}
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={8}>
                <Form.Item label="床号">
                  {getFieldDecorator('bed_number', {
                    rules: [{ required: false, message: '请输入床号!' }],
                  })(<InputNumber style={{ width: 180 }} placeholder="床号" />)}
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label="宿舍号">
                  {getFieldDecorator('number', {
                    rules: [{ required: true, message: '请输入宿舍号!' }],
                  })(
                    <InputNumber style={{ width: 220 }} placeholder="宿舍号" />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label="可住人数">
                  {getFieldDecorator('people_count', {
                    rules: [{ required: true, message: '请输入可住人数!' }],
                  })(
                    <InputNumber
                      style={{ width: 220 }}
                      placeholder="可住人数"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ marginTop: '12px' }}>
              <CreateRoomComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateRoomComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddRoomModalForm: any = Form.create({ name: 'create_Room' })(
  AddRoomModal,
);
