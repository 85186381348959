import React from 'react';
import { Button, Modal, Select, Input, Row, Col, message } from 'antd';
import styles from './account.module.css';

import NoAccess from '../../components/no-access';
import {
  SetRoleComponent,
  RoleItemsComponent,
  RolesComponent,
  DeleteRoleItemComponent,
} from '../../apollo';
import { BaseLayout } from '../../layout/base-layout';
import { filter_roles } from './roles.db';

const Option = Select.Option;
const confirm = Modal.confirm;

export class Accounts extends React.Component {
  state = {
    visible: false,
    role: 1,
    search: '',
    role_id: '',
    mobile: '',
  };
  search = (e: any) => {
    this.setState({
      search: e.target.value,
    });
  };
  changeRole = async (
    value: any,
    owner_id: any,
    send: any,
    deleteSend: any,
    refetch: any,
  ) => {
    if (value === '1') {
      return confirm({
        title: '您确定要移除此成员吗?',
        content: '一旦移除，不可撤销',
        okText: '移除',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          try {
            await deleteSend({
              variables: {
                id: owner_id,
              },
            });
            await refetch();
            message.success('移除成员成功');
          } catch (e) {
            message.error(e.message.replace('GraphQL error:', ''));
          }
        },
        onCancel() {
          // alert('cancel');
        },
      });
    }
    try {
      await send({
        variables: {
          SetRoleInput: {
            owner_id,
            role_id: value,
          },
        },
      });
      await refetch();
      message.success('修改成功');
    } catch (e) {
      message.error(e.message.replace('GraphQL error:', ''));
    }
  };
  changeNewRole = (value: any) => {
    this.setState({
      role_id: value,
    });
  };
  setPhone = (value: any) => {
    this.setState({
      mobile: value.target.value,
    });
  };
  createRoleItem = async (send: any, refetch: any) => {
    try {
      await send({
        variables: {
          SetRoleInput: {
            role_id: this.state.role_id,
            mobile: this.state.mobile,
          },
        },
      });
      await refetch();
      this.setState({
        visible: false,
        role_id: '',
        mobile: '',
      });
      message.success('创建成功');
    } catch (e) {
      message.error(e.message.replace('GraphQL error:', ''));
    }
  };
  render() {
    return (
      <BaseLayout>
        <Row gutter={8}>
          <Col span={8}>
            <Input placeholder="请输入手机号进行搜索" onChange={this.search} />
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => this.setState({ visible: true })}
            >
              添加成员
            </Button>
          </Col>
        </Row>
        <RoleItemsComponent fetchPolicy="no-cache">
          {({ data, error, loading, refetch: roleItemReFetch }) => {
            if (error) {
              if (error.message.includes('Access denied')) {
                return <NoAccess />;
              }
            }
            if (loading || error || !data) {
              return '';
            }
            const accounts = data.roleItems.map((roleItem: any) => {
              return {
                id: roleItem.id,
                mobile: roleItem.owner.mobile,
                true_name: roleItem.owner.true_name,
                username: roleItem.owner.username,
                owner_id: roleItem.owner.id,
                role: roleItem.role.name,
                role_id: roleItem.role.id,
              };
            });
            return (
              <div className={styles.account_list}>
                <SetRoleComponent>
                  {(send, { loading }) => {
                    return (
                      <Modal
                        visible={this.state.visible}
                        onCancel={() =>
                          this.setState({
                            visible: false,
                            role_id: '',
                          })
                        }
                        centered
                        onOk={() => this.createRoleItem(send, roleItemReFetch)}
                        title="添加成员"
                      >
                        <Row gutter={8}>
                          <Col span={4}>手机号：</Col>
                          <Col span={12}>
                            <Input
                              value={this.state.mobile}
                              placeholder="请输入成员手机号"
                              onChange={this.setPhone}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8} style={{ marginTop: '20px' }}>
                          <Col span={4}>手机号：</Col>
                          <Col span={12}>
                            <RolesComponent fetchPolicy="no-cache">
                              {({ data, error, loading }) => {
                                if (loading || error || !data) {
                                  return '';
                                }
                                const roles = data.roles.filter(role => {
                                  return !filter_roles.includes(role.name);
                                });
                                return (
                                  <Select
                                    value={this.state.role_id || '请选择角色'}
                                    placeholder="请选择角色"
                                    style={{ width: 160 }}
                                    onChange={(value: any) =>
                                      this.changeNewRole(value)
                                    }
                                  >
                                    {roles.map(role => {
                                      return (
                                        <Option
                                          key={String(role)}
                                          value={role.id}
                                        >
                                          {role.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                );
                              }}
                            </RolesComponent>
                          </Col>
                        </Row>
                      </Modal>
                    );
                  }}
                </SetRoleComponent>
                {accounts.map(account => {
                  if (
                    this.state.search &&
                    !account.mobile.includes(this.state.search)
                  ) {
                    return '';
                  }
                  if (account.role === 'super_admin') {
                    return (
                      <div key={account.id} className={styles.account_item}>
                        <span className={styles.phone}>{account.mobile}</span>
                        <span>总负责人</span>
                      </div>
                    );
                  }
                  return (
                    <div key={account.id} className={styles.account_item}>
                      <span className={styles.phone}>{account.true_name || account.mobile || account.username}</span>
                      <SetRoleComponent>
                        {(send, { loading }) => {
                          return (
                            <DeleteRoleItemComponent>
                              {deleteSend => {
                                return (
                                  <RolesComponent>
                                    {({ data, error, loading }) => {
                                      if (loading || error || !data) {
                                        return '';
                                      }
                                      const roles = data.roles.filter(role => {
                                        return !filter_roles.includes(role.name);
                                      });
                                      roles.push({ id: '1', name: '移除成员' });
                                      return (
                                        <Select
                                          value={account.role_id}
                                          style={{ width: 160 }}
                                          onChange={(value: any) =>
                                            this.changeRole(
                                              value,
                                              account.owner_id,
                                              send,
                                              deleteSend,
                                              roleItemReFetch,
                                            )
                                          }
                                        >
                                          {roles.map(role => {
                                            return (
                                              <Option
                                                key={role.id}
                                                style={
                                                  role.name === '移除成员'
                                                    ? { color: 'red' }
                                                    : {}
                                                }
                                                value={role.id}
                                              >
                                                {role.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      );
                                    }}
                                  </RolesComponent>
                                );
                              }}
                            </DeleteRoleItemComponent>
                          );
                        }}
                      </SetRoleComponent>
                    </div>
                  );
                })}
              </div>
            );
          }}
        </RoleItemsComponent>
      </BaseLayout>
    );
  }
}
