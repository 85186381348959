import React from 'react';
import { Icon, Modal } from 'antd';

import { message } from '../../components/message';
import { DeleteTimingComponent } from '../../apollo';

const { confirm } = Modal;

export class DeleteTimingModal extends React.Component<any> {
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    confirm({
      title: '删除提示',
      content: '确定删除吗？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const { id, refetch } = this.props;
        try {
          await send({
            variables: {
              id,
            },
          });
          message.success('删除成功');
          refetch();
        } catch (e) {
          message.error(e);
        }
      },
    });
  };

  render() {
    return (
      <DeleteTimingComponent>
        {send => {
          return (
            <Icon
              style={{ color: 'red' }}
              onClick={this.handleSubmit.bind(this, send)}
              type="delete" />
          );
        }}
      </DeleteTimingComponent>
    );
  }
}
