import React from 'react';
import { Chart, Geom, Axis, Tooltip } from 'bizcharts';
import styles from './index.module.css';

interface Item {
  count: number;
  key: number;
}

interface ChartSecondProps {
  data: Item[];
}

export class ChartSecond extends React.Component<ChartSecondProps> {
  render() {
    const { data } = this.props;
    const cols = {
      count: {
        min: 0,
      },
      key: {
        range: [0, 1],
      },
    };
    return (
      <div style={{ position: 'relative' }}>
        <Chart
          height={300}
          data={data.map(({ key, count }) => ({
            key: String(key),
            count,
          }))}
          scale={cols}
          forceFit
        >
          <Axis name="key" />
          <Axis
            name="count"
            label={{
              formatter: (val: any) => {
                return (val / 1000).toFixed(1) + 'k';
              },
            }}
          />
          <Tooltip
            // @ts-ignore
            crosshairs={{
              type: 'line',
            }}
          />
          <Geom type="area" position="key*count" />
          <Geom type="line" position="key*count" size={2} />
        </Chart>
        <div className={styles.bottom}>近7天宿舍流量趋势</div>
      </div>
    );
  }
}
