import React from 'react';

import { Button, Modal, Input, Icon } from 'antd';

import { BaseTable } from '../../components/base-table';

import { api } from '../../request';

declare global {
  interface Window {
    MouseEvent: typeof MouseEvent;
  }
}

export default class AddItem extends BaseTable {
  refetch: any;

  state = {
    date: '',
    query_params: {},
    edit: true,
    add: false,
    name: '',
    // edit: false,
  };

  addItem = async () => {
    const { name } = this.state;
    try {
      const center = await api.put(`/statistics/department`, {
        id: this.props.id,
        name,
      });
      if (this.props.refetch) {
        this.props.refetch();
      }
      this.setState({ name: '', add: false });
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    const { add, name } = this.state;
    return (
      <>
        <Icon type="plus-circle" onClick={() => this.setState({ add: true })} />
        <Modal
          title="新增服务部门"
          centered
          visible={add}
          onCancel={() => this.setState({ add: false, name: '' })}
          footer={null}
        >
          <Input
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
            placeholder="名称"
          />
          <br />
          <br />
          <Button
            type="primary"
            onClick={this.addItem}
            className="login-form-button"
          >
            增加
          </Button>
        </Modal>
      </>
    );
  }
}
