import React from 'react';
import { Button, Icon, Modal, Form, Input, InputNumber, Select } from 'antd';

import { UpdateCameraComponent } from '../../apollo';
import { message } from '../../components/message';

const { Option } = Select;

class ChangeCameraModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateCamera: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        {this.props.button ? (
          <Button
            type="primary"
            onClick={() => this.setState({ visible: true })}
          >
            修改信息
          </Button>
        ) : (
          <Icon
            style={{ marginRight: '12px', color: 'rgb(37, 147, 252)' }}
            onClick={() => this.setState({ visible: true })}
            type="edit"
          />
        )}
        <Modal
          title="修改摄像头信息"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <Form.Item label="相机名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入相机名称!' }],
                initialValue: this.props.item.name,
              })(<Input placeholder="相机名称" />)}
            </Form.Item>
            <Form.Item label="类型">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: '请选择类型!' }],
                initialValue: this.props.item.type,
              })(
                <Select placeholder="类型">
                  <Option value="入口">入口</Option>
                  <Option value="出口">出口</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="ip 地址">
              {getFieldDecorator('camera_address', {
                rules: [{ required: true, message: '请输入ip 地址!' }],
                initialValue: this.props.item.camera_address,
              })(<Input placeholder="ip 地址" />)}
            </Form.Item>
            <Form.Item label="楼号">
              {getFieldDecorator('dorm_number', {
                rules: [{ required: true, message: '请输入楼号!' }],
                initialValue: this.props.item.dorm_number,
              })(<InputNumber style={{ width: '100%' }} placeholder="楼号" />)}
            </Form.Item>
            <Form.Item style={{ marginTop: '12px' }}>
              <UpdateCameraComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdateCameraComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeCameraModalForm: any = Form.create({
  name: 'change_student',
})(ChangeCameraModal);
