import React from 'react';

import { BaseLayout } from '../../layout/base-layout';
import styles from './index.module.css';
import {
  DormsFloorComponent,
  RoomsComponent,
  RoomEntity,
  FloorsComponent,
} from '../../apollo';
import { Col, Row, Spin, Progress } from 'antd';
import { api_uri, is_zhaoli } from '../../config';
import empty from '../../images/empty.jpg';
import dorm from '../../images/dorm.png';
import empty_svg from './empty.svg';
import home from './home.png';
import bed from './bed.png';
import moon from './moon.png';
import register from './register.png';
import { Link } from 'react-router-dom';
import { CatModal } from './cat-modal';

const room_status = ['全部', '满员', '未满', '无人'];

function getRooms(rooms: { list: any[] }, active_room_status: any) {
  return rooms.list.filter(({ people_count, people_in_count }) => {
    if (room_status[active_room_status] === '全部') {
      return true;
    } else if (room_status[active_room_status] === '满员') {
      return people_count <= people_in_count;
    } else if (room_status[active_room_status] === '无人') {
      return people_in_count === 0;
    } else if (room_status[active_room_status] === '未满') {
      const result = people_count > people_in_count && people_in_count > 0;
      return result;
    }
    return false;
  });
}
class BlueLine extends React.Component<any, any> {
  render() {
    const { total, use } = this.props;
    const left_width = Math.ceil((use / total) * 100);
    const right_width = 100 - left_width;
    return (
      <div className={styles.blue_line}>
        <div
          className={styles.blue_line_left}
          style={{ width: `${left_width}%` }}
        ></div>
        <div
          className={styles.blue_line_middle}
          style={{ left: `${left_width}%` }}
        ></div>
        <div
          className={styles.blue_line_right}
          style={{ width: `${right_width}%` }}
        ></div>
      </div>
    );
  }
}

class BlueLineC extends React.Component<any, any> {
  render() {
    const { total, use, left_string, right_string } = this.props;
    return (
      <Row gutter={16} className={styles.blue_line_c}>
        <Col span={6}>{left_string}</Col>
        <Col span={12}>
          <BlueLine total={total} use={use} />
        </Col>
        <Col span={6}>{right_string}</Col>
      </Row>
    );
  }
}

class FloorDetail extends React.Component<any, any> {
  render() {
    const {
      number,
      count_people,
      count_in_people,
      room_count,
      room_full_count,
      room_use_count,
      room_empty_count,
      uri,
    } = this.props.floor;
    return (
      <div
        style={{
          marginLeft: '38px',
          display: 'flex',
        }}
      >
        <div className={styles.statistics_item}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img alt="房间" className={styles.statistics_icon} src={home} />
            <span style={{ marginRight: '5px' }}>总房间数</span>
            <span className={styles.blue_number}>{room_count}</span>
          </div>
          <div className={styles.statistics_progress}>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>
                {room_count - room_empty_count}
              </span>
              <span>使用</span>
            </div>
            <div className={styles.statistics_blue_line}>
              <BlueLine
                total={room_count}
                use={room_count - room_empty_count}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>
                {room_empty_count}
              </span>
              <span>空置</span>
            </div>
          </div>
        </div>
        <div className={styles.statistics_item}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img alt="床位" className={styles.statistics_icon} src={bed} />
            <span style={{ marginRight: '5px' }}>总床位数</span>
            <span className={styles.blue_number}>{room_count * 4}</span>
          </div>
          <div className={styles.statistics_progress}>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>{count_people}</span>
              <span>使用</span>
            </div>
            <div className={styles.statistics_blue_line}>
              <BlueLine total={room_count * 4} use={count_people} />
            </div>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>
                {room_count * 4 - count_people}
              </span>
              <span>空置</span>
            </div>
          </div>
        </div>
        <div className={styles.statistics_item}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img alt="住户" className={styles.statistics_icon} src={moon} />
            <span style={{ marginRight: '5px' }}>登记人数</span>
            <span className={styles.blue_number}>{count_people}</span>
          </div>
          <div className={styles.statistics_progress}>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>
                {count_in_people}
              </span>
              <span>在寝</span>
            </div>
            <div className={styles.statistics_blue_line}>
              <BlueLine total={count_people} use={count_in_people} />
            </div>
            <div style={{ textAlign: 'center' }}>
              <span className={styles.statistics_number}>
                {count_people - count_in_people}
              </span>
              <span>外出</span>
            </div>
          </div>
        </div>
        <div className={styles.statistics_count_people}>
          <span className={styles.blue_number_large}>{count_people}</span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              alt="登记人数"
              src={register}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            登记人数
          </div>
        </div>
        <CatModal uri={uri}></CatModal>
      </div>
    );
  }
}

class DormItem extends React.Component<any, any> {
  render() {
    const { active_dorm, item, onClick } = this.props;
    const { number } = item;
    return (
      <Col span={3} className={`gutter-row`} style={{ position: 'relative' }}>
        <div
          onClick={onClick}
          className={`${
            active_dorm === number || (!active_dorm && number === 1)
              ? styles.active_title_item
              : styles.title_item
          }`}
        >
          <img
            alt="宿舍楼"
            style={{ width: '30px', height: '30px', marginBottom: '8px' }}
            src={dorm}
          />
          <br />
          {number}号宿舍楼
        </div>
      </Col>
    );
  }
}

class FloorItem extends React.Component<any, any> {
  render() {
    const { active_floor, item, onClick } = this.props;
    const {
      number,
      count_people,
      count_in_people,
      room_count,
      room_full_count,
      room_use_count,
      room_empty_count,
    } = item;
    return (
      <div
        onClick={onClick}
        className={`${
          active_floor === number || (!active_floor && number === 1)
            ? styles.active_floor_item
            : styles.floor_item
        }`}
      >
        <div className={styles.floor_item_title}>{number}F</div>
        <div style={{ flex: 1 }}>
          <div className={styles.floor_number}>
            <div style={{ marginBottom: '3px' }}>
              <span style={{ marginRight: '3px' }}>房间总数</span>
              <span className={styles.blue_number}>{room_count}</span>
            </div>
            <div className={styles.room_progress}>
              <span className={styles.blue_number}>
                {room_count - room_empty_count}
              </span>
              <div style={{ flex: 1, marginLeft: '10px', marginRight: '10px' }}>
                <BlueLine
                  total={room_count}
                  use={room_count - room_empty_count}
                />
              </div>
              <span className={styles.blue_number}>{room_empty_count}</span>
            </div>
            {/* 满员：{room_full_count} &nbsp;&nbsp;&nbsp; 未满员：{room_use_count}
            &nbsp;&nbsp;&nbsp;空置：
            {room_empty_count} */}
          </div>
          <div className={styles.floor_number}>
            <div style={{ marginBottom: '3px' }}>
              <span style={{ marginRight: '3px' }}>总床位数</span>
              <span className={styles.blue_number}>{room_count * 4}</span>
            </div>
            <div className={styles.room_progress}>
              <span className={styles.blue_number}>{count_people}</span>
              <div style={{ flex: 1, marginLeft: '10px', marginRight: '10px' }}>
                <BlueLine total={room_count * 4} use={count_people} />
              </div>
              <span className={styles.blue_number}>
                {room_count * 4 - count_people}
              </span>
            </div>
          </div>
          {/* <div className={styles.floor_number}>
            <div style={{ marginBottom: "3px" }}>
              总人数
              <span className={styles.blue_number}>0</span>
            </div>
            <div className={styles.room_progress}>
              <span className={styles.blue_number}>
                {room_full_count - room_empty_count}
              </span>
              <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                <BlueLine total={100} use={80} />
              </div>
              <span className={styles.blue_number}>
                {room_use_count}
              </span>
            </div>
          </div> */}
          <div className={styles.floor_number}>
            <div style={{ marginBottom: '3px' }}>
              <span style={{ marginRight: '3px' }}>登记人数</span>
              <span className={styles.blue_number}>{count_people}</span>
            </div>
          </div>
          {/* <div className={styles.floor_number}>
            可容纳人数： {room_count * 4}
            <br />
            登记人数：{count_people} &nbsp;&nbsp;&nbsp; 可增人数：
            {room_count * 4 - count_people}
            <BlueLineC
              left_string={`登记：${count_people}`}
              right_string={`空置：${room_count * 4 - count_people}`}
              total={room_count * 4}
              use={count_people}
            />
          </div> */}
          <div className={styles.floor_right}>
            <span style={{ color: 'rgb(0, 138, 255)' }}>
              在寝：{count_in_people}
            </span>{' '}
            / {count_people}
            <Progress
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#108ee9',
              }}
              // strokeColor={{
              //   '0%': '#108ee9',
              //   '100%': '#87d068',
              // }}
              className={styles.progress}
              showInfo={false}
              percent={(count_in_people / count_people) * 100}
            />
          </div>
        </div>
      </div>
    );
  }
}

interface BedItemProps {
  room: RoomEntity;
}

class BedItem extends React.Component<BedItemProps> {
  render() {
    const { room } = this.props;
    return (
      <div className={styles.room_card}>
        <div className={styles.room_number}>
          {room.number}
          <div className={styles.room_use_number}>
            已住人数：
            <span style={{ color: 'rgb(57, 152, 247)' }}>
              {room.people.length}
            </span>
          </div>
        </div>
        <Row gutter={16}>
          {room.people.length === 0 ? (
            <div className={styles.empty_bed}>暂时无人入住</div>
          ) : (
            room.people.map(
              ({ id, in_status, photo, true_name, bed_number }) => {
                let to_uri = `/people-detail?id=${id}`;
                return (
                  <Col
                    key={id}
                    span={8}
                    className={
                      in_status === '在寝'
                        ? styles.bed_user_card
                        : styles.no_bed_user_card
                    }
                  >
                    <img
                      alt="头像"
                      src={photo ? api_uri + '/file/photo/' + photo : empty}
                      className={styles.bed_user_photo}
                    />
                    <br />
                    <Link
                      target="view_window"
                      to={to_uri}
                      className={
                        in_status === '在寝'
                          ? styles.user_card_name
                          : styles.no_user_card_name
                      }
                    >
                      {true_name}-{bed_number}
                    </Link>
                  </Col>
                );
              },
            )
          )}
        </Row>
      </div>
    );
  }
}

export class RealTime extends React.Component<any> {
  state = {
    active_dorm: 1,
    active_floor: 1,
    active_room_status: 0,
  };

  refetch: any;
  second_refetch: any;
  refetch_floor: any;
  time: any;

  componentDidMount() {
    this.time = setInterval(() => {
      if (this.refetch) {
        this.refetch();
      }
      if (this.second_refetch) {
        this.second_refetch();
      }
      if (this.refetch_floor) {
        this.refetch_floor();
      }
    }, 2000);
  }

  filterRoomStatus = (index: number) => {
    this.setState({ active_room_status: index });
  };

  componentWillUnmount() {
    if (this.time) {
      clearInterval(this.time);
    }
  }

  render() {
    const { active_dorm, active_floor, active_room_status } = this.state;
    return (
      <BaseLayout>
        <DormsFloorComponent variables={{ take: 100 }}>
          {({ data, error, loading }) => {
            if (error || !data || !data.dorms) {
              return <div>{error && error.message}</div>;
            }
            const dorms = data.dorms.list;
            // const dorm = dorms.find(({ number }) => number === active_dorm);
            // let floors: any = [];
            // if (dorm) {
            //   floors = dorm.floors;
            // }
            return (
              <>
                <Row gutter={2} style={{ background: '#FFFFFF' }}>
                  {dorms.map(dorm => {
                    return (
                      <DormItem
                        key={dorm.id}
                        item={dorm}
                        active_dorm={active_dorm}
                        onClick={() =>
                          this.setState({
                            active_dorm: dorm.number,
                            floor_count: dorm.floor_count,
                          })
                        }
                      />
                    );
                  })}
                </Row>
                <FloorsComponent variables={{ dorm_number: active_dorm }}>
                  {({ data, refetch: refetch_floor }) => {
                    if (!data || !data.floors) {
                      return '';
                    }
                    if (refetch_floor) {
                      this.refetch_floor = refetch_floor;
                    }
                    const now_active_floor = data.floors.list.find(
                      ({ number }) => number === active_floor,
                    );
                    return (
                      <Row gutter={16} style={{ marginTop: '50px' }}>
                        <Col span={6}>
                          {data.floors.list.map((floor: any) => {
                            return (
                              <FloorItem
                                item={floor}
                                active_floor={active_floor}
                                onClick={() =>
                                  this.setState({ active_floor: floor.number })
                                }
                                key={String(floor.number)}
                              >
                                {floor.number}
                              </FloorItem>
                            );
                          })}
                        </Col>

                        <RoomsComponent
                          variables={{
                            dorm_number: active_dorm,
                            floor_number: active_floor,
                            take: 24,
                          }}
                        >
                          {({ data, loading, error, refetch }) => {
                            if (loading || !data) {
                              return (
                                <div
                                  style={{
                                    textAlign: 'center',
                                    paddingTop: '80px',
                                  }}
                                >
                                  <Spin size="large" />
                                </div>
                              );
                            }
                            if (error) {
                              return error.message;
                            }
                            this.refetch = refetch;
                            // const array = Array.from(
                            //   new Array(Math.ceil((data.rooms.count - 12) / 9)),
                            // );
                            if (!data.rooms || data.rooms.list.length === 0) {
                              // return (
                              //   <div
                              //     style={{
                              //       textAlign: 'center',
                              //       fontSize: '22px',
                              //       fontWeight: 600,
                              //       paddingTop: '60px',
                              //     }}
                              //   >
                              //     <img
                              //       style={{
                              //         width: '50%',
                              //         height: '50%',
                              //         marginBottom: '20px',
                              //       }}
                              //       alt="empty"
                              //       src={empty_svg}
                              //     />
                              //     <p>当前楼层没有相关人员</p>
                              //   </div>
                              // );
                              data.rooms.list = [];
                              // return '';
                            }
                            const new_rooms = getRooms(
                              data.rooms,
                              active_room_status,
                            );
                            return (
                              <Col span={18}>
                                <FloorDetail floor={now_active_floor} />
                                <div className={styles.room_filter}>
                                  {room_status.map((item, index) => (
                                    <span
                                      onClick={() =>
                                        this.filterRoomStatus(index)
                                      }
                                      key={item}
                                      className={
                                        active_room_status === index
                                          ? styles.active_filter_item
                                          : styles.filter_item
                                      }
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </div>
                                <Row gutter={16} style={{ marginLeft: '30px' }}>
                                  {new_rooms.map(room => {
                                    return (
                                      <Col span={8} key={room.id}>
                                        <BedItem room={room} />
                                      </Col>
                                    );
                                  })}

                                  {!is_zhaoli && (
                                    <RoomsComponent
                                      variables={{
                                        dorm_number: active_dorm,
                                        floor_number: active_floor,
                                        skip: 24,
                                        take: data.rooms.count - 24,
                                      }}
                                    >
                                      {({
                                        data: data2,
                                        loading,
                                        error,
                                        refetch: second_refetch,
                                      }) => {
                                        if (loading || !data2 || error) {
                                          return '';
                                        }
                                        this.second_refetch = second_refetch;
                                        if (
                                          !data2.rooms ||
                                          data2.rooms.list.length === 0
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                fontSize: '22px',
                                                fontWeight: 600,
                                                paddingTop: '60px',
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: '50%',
                                                  height: '50%',
                                                  marginBottom: '20px',
                                                }}
                                                alt="empty"
                                                src={empty_svg}
                                              />
                                              <p>当前楼层没有相关人员</p>
                                            </div>
                                          );
                                        }
                                        const new_rooms2 = getRooms(
                                          data2.rooms,
                                          active_room_status,
                                        );
                                        if (
                                          new_rooms.length === 0 &&
                                          new_rooms2.length === 0
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                fontSize: '22px',
                                                fontWeight: 600,
                                                paddingTop: '60px',
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: '50%',
                                                  height: '50%',
                                                  marginBottom: '20px',
                                                }}
                                                alt="empty"
                                                src={empty_svg}
                                              />
                                              <p>当前楼层没有相关人员</p>
                                            </div>
                                          );
                                        }
                                        return new_rooms2.map(room => {
                                          return (
                                            <Col span={8} key={room.id}>
                                              <BedItem room={room} />
                                            </Col>
                                          );
                                        });
                                      }}
                                    </RoomsComponent>
                                  )}
                                </Row>
                              </Col>
                            );
                          }}
                        </RoomsComponent>
                      </Row>
                    );
                  }}
                </FloorsComponent>
              </>
            );
          }}
        </DormsFloorComponent>
      </BaseLayout>
    );
  }
}
