import React from 'react';
import { Icon, Modal, Form, Button, Upload } from 'antd';

import { message } from '../../components/message';
import { file_multiple_props } from '../../lib/upload';

const { Dragger } = Upload;

class UploadPeopleModal extends React.Component<any> {
  state = {
    visible: false,
    visible_upload: false,
    message_first: false,
  };

  close = () => this.setState({ visible: false, uri: '', message_first: false });

  render() {
    const { visible, visible_upload, message_first } = this.state;
    return (
      <div style={{ display: 'inline-block', marginRight: '12px' }}>
        <Button onClick={() => this.setState({ visible: true })} type="primary">
          上传底片
        </Button>
        <Modal
          title="上传底片"
          centered
          visible={visible}
          onCancel={() =>
            this.setState({ visible: false, visible_upload: false })
          }
          footer={null}
        >
          {visible && (
            <Dragger
              disabled={visible_upload}
              {...file_multiple_props('people/upload')}
              // onChange={() => {
              //   this.setState({ visible_upload: true });
              // }}
              onChange={(info: any) => {
                const { status, response } = info.file;
                if (status === 'error' && response && !message_first) {
                  if (response.message &&
                    (response.message.includes('Access denied') ||
                      response.message.includes('Forbidden resource'))
                  ) {
                    message.error(new Error(`无权限`));
                  } else {
                    message.error(new Error(`上传失败.`));
                  }
                  this.setState({ message_first: true })
                }
                this.setState({ visible_upload: true });
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或者拖动到此处上传</p>
              <p className="ant-upload-hint">
                支持文件夹上传，通过文件名和学号匹配，如果学号相同则覆盖原有底片
              </p>
            </Dragger>
          )}
        </Modal>
      </div>
    );
  }
}

export const UploadPeopleModalForm: any = Form.create({
  name: 'upload_people',
})(UploadPeopleModal);
