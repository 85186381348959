import React from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
} from 'antd';

import { CreateDormComponent } from '../../apollo';
import { message } from '../../components/message';

const { Option } = Select;

class AddDormModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateDorm: { ...values },
              // CreateDorm: { ...values, photo: this.state.uri },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>
          新增宿舍楼
        </Button>
        <Modal
          title="新增宿舍楼"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            {/* <div className="clearfix">
              <Form.Item
                label="宿舍楼照片（⽀持拓展名: .jpg, .jpeg, .png）"
                style={{ marginBottom: '0px' }}
              >
                {getFieldDecorator('photo', {
                  // getValueFromEvent: this.photoNormFile,
                  rules: [
                    {
                      required: true,
                      message: '请上传文件',
                    },
                  ],
                })(
                  <Upload
                    {...up_load_life_photo_options(uri =>
                      this.setState({
                        uri,
                      }),
                    )}
                    accept=".jpg,.jpeg,.png"
                    listType="picture-card"
                    showUploadList={false}
                    className="hand"
                  >
                    {uri ? (
                      <img
                        style={{ width: '100%' }}
                        src={oss_uri + uri}
                        alt="avatar"
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>,
                )}
              </Form.Item>
            </div> */}
            <Form.Item label="宿舍楼名称">
              {getFieldDecorator('name', {
                rules: [{ required: false, message: '请输入宿舍楼名称!' }],
              })(<Input placeholder="宿舍楼名称" />)}
            </Form.Item>
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="宿舍楼编号">
                  {getFieldDecorator('number', {
                    rules: [{ required: true, message: '请输入宿舍楼编号!' }],
                  })(
                    <InputNumber
                      style={{ width: '120px' }}
                      placeholder="宿舍楼编号"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="楼层数量">
                  {getFieldDecorator('floor_count', {
                    rules: [{ required: true, message: '请输入楼层数量!' }],
                  })(
                    <InputNumber
                      style={{ width: '120px' }}
                      placeholder="楼层数量"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="每层楼房间数">
                  {getFieldDecorator('floor_room_count', {
                    rules: [{ required: true, message: '每层楼房间数!' }],
                  })(
                    <InputNumber
                      style={{ width: '120px' }}
                      placeholder="每层楼房间数"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* <Form.Item label="宿舍楼类型">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: '请选择宿舍楼类型!' }],
                initialValue: '男生宿舍',
              })(
                <Select placeholder="宿舍楼类型">
                  <Option value="男生宿舍">男生宿舍</Option>
                  <Option value="女生宿舍">女生宿舍</Option>
                  <Option value="职工宿舍">职工宿舍</Option>
                  <Option value="混合宿舍">混合宿舍</Option>
                </Select>,
              )}
            </Form.Item> */}
            <Form.Item style={{ marginTop: '30px' }}>
              <CreateDormComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateDormComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddDormModalForm: any = Form.create({ name: 'create_dorm' })(
  AddDormModal,
);
