import React from 'react';
import { Button, Icon, Modal, Form, Input } from 'antd';

import { UpdateJobComponent } from '../../apollo';
import { message } from '../../components/message';

class ChangeJobModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateJob: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Icon onClick={() => this.setState({ visible: true })} type="edit" />
        <Modal
          title="修改部门"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入名称!' }],
                initialValue: this.props.item.name,
              })(<Input placeholder="名称" />)}
            </Form.Item>
            <Form.Item>
              <UpdateJobComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdateJobComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeJobModalForm: any = Form.create({
  name: 'change_job',
})(ChangeJobModal);
