import React from 'react';
import { Form, Icon, Input, Button, Modal } from 'antd';

import styles from './change-password.module.css';
import { withRouter } from 'react-router-dom';
import { message } from '../../components/message';
import { ChangePasswordByOldPasswordComponent } from '../../apollo';
import { BaseLayout } from '../../layout/base-layout';

class ChangePassword extends React.Component<any, any> {
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        Modal.confirm({
          title: '您确定要修改密码吗?',
          content: '一旦修改，不可撤销',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk: async () => {
            try {
              await send({
                variables: {
                  old_password: values.old_password,
                  password: values.password,
                },
              });
              message.success('修改成功');
              localStorage.session = null;
              localStorage.token = null;
              this.props.history.push('/login');
            } catch (e) {
              message.error(e);
            }
          },
          onCancel() {
            // alert('cancel');
          },
        });
      }
    });
  };

  compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('俩次密码不一样!');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <BaseLayout>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.main}>
              <Form>
                <Form.Item label="旧密码" hasFeedback>
                  {getFieldDecorator('old_password', {
                    rules: [{ required: true, message: '请输入旧密码!' }],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="旧密码"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="新密码" hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入新密码!' }],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="新密码"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="新密码" hasFeedback>
                  {getFieldDecorator('re_password', {
                    rules: [
                      { required: true, message: '请输入新密码!' },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="新密码"
                    />,
                  )}
                </Form.Item>
                <Form.Item style={{ marginTop: '12px' }}>
                  <ChangePasswordByOldPasswordComponent>
                    {(send, { loading }) => {
                      return (
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={this.handleSubmit.bind(this, send)}
                          className="login-form-button"
                        >
                          确认修改
                        </Button>
                      );
                    }}
                  </ChangePasswordByOldPasswordComponent>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </BaseLayout>
    );
  }
}

const WrappedNormalChangePasswordForm = Form.create({
  name: 'normal_change_password',
})(withRouter(ChangePassword));

export default WrappedNormalChangePasswordForm;
