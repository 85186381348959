import React from 'react';

import queryString from 'query-string';

import { BaseTable, TableTimeSearch, OutSearch } from '../../components/base-table';
import { RecognitionResultsComponent, OutRecognitionResultsComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { columns } from './people-record.columns';
import { withRouter } from 'react-router';

function getData(data: any) {
  const list_data = data.recognitionResults;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            user_id: item.people && item.people.id,
            key: item.id,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

class PeopleRecordTableComponent extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
    },
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { start_date, end_date, start_time, end_time } = this.state;
    return (
      <RecognitionResultsComponent
        variables={{
          skip,
          take: 10,
          type: '人员',
          user_id: this.props.user_id,
          // subject_id: String(this.props.subject_id) || '0',
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error, refetch }) => {
          if (error) {
            return <>Access denied</>;
          }
          const result = getData(data);

          const new_columns = columns;
          return (
            <div className="table_container">
              <TableTimeSearch
                start_date={start_date}
                end_date={end_date}
                start_time={start_time}
                end_time={end_time}
                onChange={(name, value) => this.setState({ [name]: value })}
                reset={() =>
                  this.setState({
                    query_params: {
                      ...this.state.query_params,
                      start_date: null,
                      end_date: null,
                      start_time: null,
                      end_time: null,
                    },
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                  })
                }
                search={() =>
                  this.setState({
                    query_params: {
                      ...this.state.query_params,
                      start_date,
                      end_date,
                      start_time,
                      end_time,
                    },
                  })
                }
              >
                <OutRecognitionResultsComponent>
                  {(send, { loading }) => {
                    return (
                      <OutSearch
                        // style={{ marginTop: '60px' }}
                        options={columns_titles}
                        checked_options={this.state.checked_options}
                        onCheckAllChange={this.onCheckAllChange}
                        outData={send}
                        loading={loading}
                        columns={this.state.checked_options}
                        params={{
                          skip,
                          take: 10,
                          ...this.state.query_params,
                          start_date,
                          end_date,
                          start_time,
                          end_time,
                          user_id: this.props.user_id,
                        }}
                      />
                    );
                  }}
                </OutRecognitionResultsComponent>
              </TableTimeSearch>
              <AllTable
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={new_columns}
                loading={data && data.recognitionResults ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 10,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </RecognitionResultsComponent>
    );
  }
}

export const PeopleRecordTable = withRouter(PeopleRecordTableComponent);
