import * as ReactApollo from "react-apollo";
import * as React from "react";
import gql from "graphql-tag";

export type Maybe<T> = T | null;

export interface LogFilterOrder {
  created_date?: Maybe<string>;
}

export interface UsersFilterOrder {
  created_date?: Maybe<string>;
}

export interface DormFilterOrder {
  created_date?: Maybe<string>;
}

export interface RoomFilterOrder {
  created_date?: Maybe<string>;
}

export interface RoleFilterOrder {
  created_date?: Maybe<string>;
}

export interface RoleItemFilterOrder {
  created_date?: Maybe<string>;
}

export interface RecognitionResultFilterOrder {
  created_date?: Maybe<string>;
}

export interface FloorFilterOrder {
  created_date?: Maybe<string>;
}

export interface HousemasterFilterOrder {
  created_date?: Maybe<string>;
}

export interface DepartmentFilterOrder {
  created_date?: Maybe<string>;
}

export interface JobFilterOrder {
  created_date?: Maybe<string>;
}

export interface WarnRuleFilterOrder {
  created_date?: Maybe<string>;
}

export interface NoReturnRecordFilterOrder {
  created_date?: Maybe<string>;
}

export interface BlacklistFilterOrder {
  created_date?: Maybe<string>;
}

export interface LateReturnRecordFilterOrder {
  created_date?: Maybe<string>;
}

export interface PeopleFilterOrder {
  created_date?: Maybe<string>;

  no_return_count?: Maybe<string>;

  no_out_count?: Maybe<string>;
}

export interface StructureFilterOrder {
  created_date?: Maybe<string>;

  sort_number?: Maybe<string>;
}

export interface CameraFilterOrder {
  created_date?: Maybe<string>;
}

export interface DailyFilterOrder {
  created_date?: Maybe<string>;
}

export interface TimingFilterOrder {
  created_date?: Maybe<string>;
}

export interface LeaveFilterOrder {
  created_date?: Maybe<string>;
}

export interface ScreenFilterOrder {
  created_date?: Maybe<string>;
}

export interface LoginByAdmin {
  mobile: string;

  password: string;
}

export interface CreateUser {
  username: string;

  password: string;

  mobile: string;

  email: string;
}

export interface SignWeChatInput {
  /** url */
  url: string;
  /** 这个字段特殊 使用大写 */
  jsApiList: string[];
}

export interface CreateDorm {
  name: string;
  /** 校区名称 */
  school_name?: Maybe<string>;
  /** 宿舍楼号 */
  number: number;
  /** 楼层高度 如 2 */
  floor_count: number;
  /** 类型 如 男生宿舍 女生宿舍 职工宿舍 混合宿舍 */
  type?: Maybe<string>;
  /** 每层房间数 */
  floor_room_count: number;
}

export interface UpdateDorm {
  name: string;
  /** 校区名称 */
  school_name?: Maybe<string>;
  /** 宿舍楼号 */
  number: number;
  /** 楼层高度 如 2 */
  floor_count: number;
  /** 类型 如 男生宿舍 女生宿舍 职工宿舍 混合宿舍 */
  type?: Maybe<string>;
  /** 每层房间数 */
  floor_room_count: number;
  /** id */
  id: string;
}

export interface CreateRoom {
  /** 编号 */
  number: number;
  /** 宿舍楼编号 */
  dorm_number: number;
  /** 楼层号 */
  floor_number: number;
  /** 可住人数 */
  people_count: number;
}

export interface UpdateRoom {
  /** 编号 */
  number: number;
  /** 宿舍楼编号 */
  dorm_number: number;
  /** 楼层号 */
  floor_number: number;
  /** 可住人数 */
  people_count: number;
  /** id */
  id: string;
}

export interface CreateRole {
  /** 角色名称 */
  name: string;
  /** 权限 */
  items: string[];
}

export interface UpdateRole {
  /** 角色名称 */
  name: string;
  /** 权限 */
  items: string[];
  /** id */
  id: string;
}

export interface SetRoleInput {
  /** 用户手机号 */
  mobile?: Maybe<string>;
  /** 用户 id */
  owner_id?: Maybe<string>;
  /** 角色 id */
  role_id: string;
}

export interface CreateRoleItem {
  name: string;
}

export interface UpdateRoleItem {
  name: string;
  /** id */
  id: string;
}

export interface CreateRecognitionResult {
  name: string;
}

export interface UpdateRecognitionResult {
  name: string;
  /** id */
  id: string;
}

export interface CreateFloor {
  dorm_number: number;

  number: number;

  uri: string;
}

export interface UpdateFloor {
  dorm_number: number;

  number: number;

  uri: string;
  /** id */
  id: string;
}

export interface CreateHousemaster {
  /** 真实姓名 */
  true_name: string;
  /** 手机号 */
  mobile: string;
  /** 职工号 */
  job_number: string;
  /** 入职时间 */
  start_date?: Maybe<DateTime>;
  /** 宿舍楼号 */
  dorm_number: number;
  /** 备注 */
  remark: string;
}

export interface UpdateHousemaster {
  /** 真实姓名 */
  true_name: string;
  /** 手机号 */
  mobile: string;
  /** 职工号 */
  job_number: string;
  /** 入职时间 */
  start_date?: Maybe<DateTime>;
  /** 宿舍楼号 */
  dorm_number: number;
  /** 备注 */
  remark: string;
  /** id */
  id: string;
}

export interface CreateDepartment {
  name: string;
}

export interface UpdateDepartment {
  name: string;
  /** id */
  id: string;
}

export interface CreateJob {
  name: string;
}

export interface UpdateJob {
  name: string;
  /** id */
  id: string;
}

export interface CreateWarnRule {
  /** 晚归开始计算时间 */
  start_time: string;
  /** 晚归结束计算时间 */
  end_time: string;
  /** 未归开始计算时间 */
  no_return_start_time: string;
  /** 当天 前一天 */
  no_return_type: string;
}

export interface UpdateWarnRule {
  /** 晚归开始计算时间 */
  start_time: string;
  /** 晚归结束计算时间 */
  end_time: string;
  /** 未归开始计算时间 */
  no_return_start_time: string;
  /** 当天 前一天 */
  no_return_type: string;
  /** id */
  id: string;
}

export interface CreateNoReturnRecord {
  name: string;
}

export interface UpdateNoReturnRecord {
  name: string;
  /** id */
  id: string;
}

export interface CreateNoReturnRecordNote {
  /** 记录id */
  id: string;
  /** 日记 */
  note: string;
}

export interface CreateBlacklist {
  /** 姓名 */
  true_name: string;
  /** 照片 */
  photo: string;
  /** 备注 */
  remark?: Maybe<string>;
}

export interface UpdateBlacklist {
  /** 姓名 */
  true_name: string;
  /** 照片 */
  photo: string;
  /** 备注 */
  remark?: Maybe<string>;
  /** id */
  id: string;
}

export interface CreateStatistics {
  name: string;
}

export interface UpdateStatistics {
  name: string;
  /** id */
  id: string;
}

export interface CreateLateReturnRecord {
  name: string;
}

export interface UpdateLateReturnRecord {
  name: string;
  /** id */
  id: string;
}

export interface CreateLateReturnRecordNote {
  /** 记录id */
  id: string;
  /** 日记 */
  note: string;
}

export interface CreatePeople {
  /** 姓名 */
  true_name: string;
  /** 性别 */
  sex: string;
  /** 人员类型 */
  type: string;
  /** 身份证号 */
  id_card: string;
  /** 工号 */
  number?: Maybe<string>;
  /** 手机号 */
  mobile?: Maybe<string>;
  /** 分部门 */
  department_detail?: Maybe<string>;
  /** 部门 */
  department: string;
  /** 部门 */
  to_department?: Maybe<string>;
  /** 房间卡号 */
  room_card_number?: Maybe<string>;
  /** 入住时间 */
  start_date?: Maybe<string>;
  /** 宿舍楼号 */
  dorm_number: number;
  /** 宿舍号 */
  room_number: number;
  /** 床号 */
  bed_number: number;
}

export interface UpdatePeople {
  /** 姓名 */
  true_name: string;
  /** 性别 */
  sex: string;
  /** 人员类型 */
  type: string;
  /** 身份证号 */
  id_card: string;
  /** 工号 */
  number?: Maybe<string>;
  /** 手机号 */
  mobile?: Maybe<string>;
  /** 分部门 */
  department_detail?: Maybe<string>;
  /** 部门 */
  department: string;
  /** 部门 */
  to_department?: Maybe<string>;
  /** 房间卡号 */
  room_card_number?: Maybe<string>;
  /** 入住时间 */
  start_date?: Maybe<string>;
  /** 宿舍楼号 */
  dorm_number: number;
  /** 宿舍号 */
  room_number: number;
  /** 床号 */
  bed_number: number;
  /** id */
  id: string;
}

export interface CreateStructure {
  name: string;

  father_number: string;

  leading_cadre: string;

  mobile: string;

  sort_number: number;
}

export interface UpdateStructure {
  name: string;

  father_number: string;

  leading_cadre: string;

  mobile: string;

  sort_number: number;
  /** id */
  id: string;
}

export interface CreateCamera {
  name?: Maybe<string>;

  type: string;

  camera_address: string;

  dorm_number: number;
}

export interface UpdateCamera {
  name?: Maybe<string>;

  type: string;

  camera_address: string;

  dorm_number: number;
  /** id */
  id: string;
}

export interface CreateDaily {
  name: string;
}

export interface UpdateDaily {
  name: string;
  /** id */
  id: string;
}

export interface CreateTiming {
  name: string;

  start_date: string;

  end_date: string;
}

export interface UpdateTiming {
  name: string;

  start_date: string;

  end_date: string;
  /** id */
  id: string;
}

export interface CreateLeave {
  people_id: string;

  start_date: string;

  end_date: string;
}

export interface UpdateLeave {
  people_id: string;

  start_date: string;

  end_date: string;
  /** id */
  id: string;
}

export interface CreateScreen {
  name: string;

  structure_number: string;

  structure_name?: Maybe<string>;

  is_show: boolean;

  room_count: number;

  sort_count: number;
}

export interface UpdateScreen {
  name: string;

  structure_number: string;

  structure_name?: Maybe<string>;

  is_show: boolean;

  room_count: number;

  sort_count: number;
  /** id */
  id: string;
}

export interface ChangeMobileInput {
  old_mobile: string;

  mobile: string;
  /** 短信验证码 */
  code: string;
  /** 旧手机短信验证码 */
  old_code: string;
}

export interface ChangePasswordInput {
  mobile: string;
  /** 短信验证码 */
  code: string;

  password: string;
}

export interface CreateLog {
  name: string;

  type: string;

  ip: string;

  user_id?: Maybe<string>;
}

export interface CreateNoticeInput {
  content: string;

  status: string;
}

export interface CreateNoticeRecordInput {
  title: string;

  content: string;
}

export interface CreatePeopleNote {
  /** 学生id */
  id: string;
  /** 日记 */
  note: string;
}

export interface DeleteRoleInput {
  /** 用户 id */
  owner_id: string;
}

export interface LoginByMobileInput {
  mobile: string;

  password: string;
}

export interface PageArgs {
  before?: Maybe<string>;

  after?: Maybe<string>;

  fullTextSearch?: Maybe<string>;

  first?: Maybe<number>;

  last?: Maybe<number>;

  sort?: Maybe<Sort[]>;
}

export interface Sort {
  field?: Maybe<string>;

  input?: Maybe<string>;
}

export interface RegisterInput {
  mobile: string;
  /** 短信验证码 */
  code: string;

  password: string;
}

export interface StatisticsFilterOrder {
  created_date?: Maybe<string>;
}

export interface UpdatePasswordInput {
  mobile: string;

  code: string;

  password: string;
}

export interface UpdateUserMoreInput {
  sex: string;

  true_name: string;

  email: string;

  we_chat: string;

  birthday: string;
}

export interface VerifyCodeInput {
  mobile: string;

  code: string;
}

/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
export type DateTime = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
  getLogById: LogEntity;
  /** 列表 */
  logs: LogList;

  viewer: UserEntity;
  /** 用户管理 */
  users: UserList;

  getFileById: FileEntity;
  /** 查询导出结果 */
  seeXlsx: OutUriResult;

  getDormById: DormEntity;
  /** 列表 */
  dorms: DormList;

  getRoomById: RoomEntity;
  /** 列表 */
  rooms: RoomList;

  getRoleById: Role;
  /** 列表 */
  rolesList: RoleList;
  /** 查询角色 */
  role?: Maybe<Role>;
  /** 所有角色 */
  roles: Role[];

  getRoleItemById: RoleItem;
  /** 列表 */
  roleItemList: RoleItemList;
  /** 所有管理员 */
  roleItems: RoleItem[];

  getRecognitionResultById: RecognitionResultEntity;
  /** 列表 */
  recognitionResults: RecognitionResultList;
  /** 列表 */
  recognitionResultsByNumber: RecognitionResultList;

  getFloorById: FloorEntity;
  /** 列表 */
  floors: FloorList;

  getHousemasterById: HousemasterEntity;
  /** 列表 */
  housemasters: HousemasterList;
  /** 统计总人数 */
  countHousemaster: Count;

  getDepartmentById: DepartmentEntity;
  /** 列表 */
  departments: DepartmentList;
  /** 统计总人数 */
  countDepartment: Count;

  getJobById: JobEntity;
  /** 列表 */
  jobs: JobList;
  /** 统计总人数 */
  countJob: Count;

  getWarnRuleById: WarnRuleEntity;

  getFirstWarnRule: WarnRuleEntity;
  /** 列表 */
  warnRules: WarnRuleList;

  getNoReturnRecordById: NoReturnRecordEntity;
  /** 列表 */
  noReturnRecords: NoReturnRecordList;

  getBlacklistById: BlacklistEntity;
  /** 列表 */
  blacklists: BlacklistList;
  /** 统计总人数 */
  countBlacklist: Count;

  getStatisticsById: StatisticsEntity;
  /** 首页统计 */
  statistics?: Maybe<Statistics>;
  /** datav */
  datav?: Maybe<Statistics>;

  getLateReturnRecordById: LateReturnRecordEntity;
  /** 列表 */
  lateReturnRecords: LateReturnRecordList;

  getPeopleById: PeopleEntity;

  getPeopleByNumber: PeopleEntity;
  /** 列表 */
  people: PeopleList;
  /** 列表 */
  peopleOnline: PeopleList;
  /** 统计总人数 */
  countPeople: Count;

  getStructureById: StructureEntity;
  /** 列表 */
  structures: StructureList;
  /** 列表 */
  structure_list: StructureEntity[];
  /** 列表 */
  structure_number: StructureList;

  getCameraById: CameraEntity;
  /** 列表 */
  cameras: CameraList;

  getDailyById: DailyEntity;
  /** 列表 */
  dailies: DailyList;

  getTimingById: TimingEntity;
  /** 列表 */
  timings: TimingList;

  getLeaveById: LeaveEntity;
  /** 列表 */
  leaves: LeaveList;

  getScreenById: ScreenEntity;
  /** 列表 */
  screen_list: ScreenEntity[];
  /** 列表 */
  screen_list_choose: ScreenEntity[];
  /** 列表 */
  screens: ScreenList;
}

export interface LogEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 日志类型 登录 新增 删除 修改 导出 导入 */
  type: string;
  /** ip */
  ip: string;
}

export interface LogList {
  count: number;

  list: LogEntity[];
}

/** 用户 */
export interface UserEntity {
  id: string;

  username?: Maybe<string>;

  email?: Maybe<string>;

  job_number?: Maybe<string>;

  true_name?: Maybe<string>;

  mobile?: Maybe<string>;
  /** 头像 */
  headimgurl?: Maybe<string>;
  /** 系 */
  department?: Maybe<string>;
  /** 专业 */
  major?: Maybe<string>;
  /** 年级 */
  grade?: Maybe<string>;
  /** 年级 */
  student_grade?: Maybe<number>;
  /** 备注 */
  remark?: Maybe<string>;

  permissions: string[];

  roles: string[];
}

export interface UserList {
  count: number;

  list: UserEntity[];
}

export interface FileEntity {
  id: string;
}

export interface OutUriResult {
  uri?: Maybe<string>;
}

export interface DormEntity {
  id: string;
  /** 名称 */
  name?: Maybe<string>;
  /** 宿舍楼号 */
  number: number;
  /** 楼层高度 如 2 */
  floor_count: number;
  /** 每层房间数 */
  floor_room_count?: Maybe<number>;
  /** 类型 如 男生宿舍 女生宿舍 职工宿舍 */
  type?: Maybe<string>;

  dorm_count: number;

  floors: FloorEntity[];

  people_in_count: number;

  people_out_count: number;

  people_count: number;

  flow_count: number;

  today_flow_count: number;

  late_count: number;

  no_return_count: number;
}

export interface FloorEntity {
  id: string;
  /** 名称 */
  name?: Maybe<string>;
  /** 编号 */
  number: number;
  /** 宿舍楼编号 */
  dorm_number: number;
  /** 楼层 */
  floor: string;
  /** 图片地址 */
  uri: string;
  /** 所属宿舍楼 */
  dorm_id?: Maybe<string>;

  rooms: RoomEntity[];

  room_count: number;

  room_full_count: number;

  room_use_count: number;

  room_empty_count: number;

  count_people: number;

  count_in_people: number;

  count_out_people: number;
}

export interface RoomEntity {
  id: string;
  /** 名称 */
  name?: Maybe<string>;
  /** 编号 */
  number: number;
  /** 宿舍楼编号 */
  dorm_number: number;
  /** 楼层编号 */
  floor_number: number;
  /** 楼层 */
  floor: number;
  /** 可住人员 */
  people_count: number;
  /** 已住人员 */
  people_in_count?: Maybe<number>;
  /** 状态：满员 空置 使用 */
  status?: Maybe<string>;
  /** 所属宿舍楼 */
  dorm_id?: Maybe<string>;
  /** 所属楼层楼 */
  floor_id?: Maybe<string>;

  people: PeopleEntity[];

  created_date?: Maybe<DateTime>;
}

export interface PeopleEntity {
  id: string;
  /** 姓名 */
  true_name: string;
  /** 性别 */
  sex?: Maybe<string>;
  /** 身份证号 */
  id_card?: Maybe<string>;
  /** 工号 */
  number?: Maybe<string>;
  /** 人员类型：入住人员、内部员工、其他 */
  type?: Maybe<string>;
  /** 手机号 */
  mobile?: Maybe<string>;
  /** 房间卡号 */
  room_card_number?: Maybe<string>;
  /** 部门 */
  department?: Maybe<string>;
  /** 分部门 */
  department_detail?: Maybe<string>;
  /** 服务部门 */
  to_department?: Maybe<string>;
  /** 入住时间 */
  start_date?: Maybe<DateTime>;
  /** 宿舍楼号 */
  dorm_number?: Maybe<number>;
  /** 宿舍号 */
  room_number?: Maybe<number>;
  /** 楼层号 */
  floor_number?: Maybe<number>;
  /** 床号 */
  bed_number?: Maybe<number>;
  /** 照片 */
  photo?: Maybe<string>;
  /** 头像 */
  avatar?: Maybe<string>;
  /** 状态 */
  status?: Maybe<string>;
  /** 在寝状态 */
  in_status?: Maybe<string>;
  /** 最后进入时间 */
  last_in_date?: Maybe<DateTime>;
  /** 最后离开时间 */
  last_out_date?: Maybe<DateTime>;
  /** 人脸识别 id */
  subject_id?: Maybe<number>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 晚归次数 */
  late_count?: Maybe<number>;
  /** 未归次数 */
  no_return_count?: Maybe<number>;
  /** 未出次数 */
  no_out_count?: Maybe<number>;

  created_date?: Maybe<DateTime>;

  no_return_start_date?: Maybe<string>;

  no_return_end_date?: Maybe<string>;

  admin?: Maybe<UserEntity>;

  father_department?: Maybe<string>;

  in_time_status?: Maybe<string>;
}

export interface DormList {
  count: number;

  list: DormEntity[];
}

export interface RoomList {
  count: number;

  list: RoomEntity[];
}

export interface Role {
  id: string;
  /** 名称 */
  name: string;

  permissions?: Maybe<RolePermission[]>;
}

/** 权限 */
export interface RolePermission {
  id: string;
  /** 权限名称 admin */
  name: string;
}

export interface RoleList {
  count: number;

  list: Role[];
}

/** 角色 权限 */
export interface RoleItem {
  id: string;

  owner?: Maybe<UserEntity>;

  role?: Maybe<Role>;
}

export interface RoleItemList {
  count: number;

  list: RoleItem[];
}

export interface RecognitionResultEntity {
  id: string;
  /** 识别主体id 用户id */
  subject_id?: Maybe<string>;
  /** 匹配到的底片 id */
  origin_photo_id?: Maybe<string>;
  /** 用户id */
  user_id?: Maybe<string>;
  /** 用户身份证号 */
  job_number?: Maybe<string>;
  /** 识别到的图片 */
  image_uri?: Maybe<string>;
  /** 识别到的图片 */
  megvii_image_uri?: Maybe<string>;
  /** 身份 title */
  title?: Maybe<string>;
  /** 身份 description */
  description?: Maybe<string>;
  /** 姓名 */
  true_name?: Maybe<string>;
  /** 宿舍楼 */
  dorm_number?: Maybe<number>;
  /** 方向 */
  direction?: Maybe<string>;
  /** 人员 陌生人 */
  type?: Maybe<string>;
  /** 识别类型 */
  see_type?: Maybe<string>;
  /** 日期 */
  date?: Maybe<string>;
  /** 时间 */
  time?: Maybe<string>;
  /** 时间戳 */
  timestamp?: Maybe<number>;
  /** 陌生人 id */
  uuid?: Maybe<string>;
  /** 陌生人识别 id */
  result_id?: Maybe<string>;
  /** 部门 */
  department?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  people?: Maybe<PeopleEntity>;

  father_department?: Maybe<string>;
}

export interface RecognitionResultList {
  count: number;

  list: RecognitionResultEntity[];
}

export interface FloorList {
  count: number;

  list: FloorEntity[];
}

export interface HousemasterEntity {
  id: string;
  /** 真实姓名 */
  true_name: string;
  /** 手机号 */
  mobile: string;
  /** 职工号 */
  job_number: string;
  /** 入职时间 */
  start_date?: Maybe<DateTime>;
  /** 宿舍楼号 */
  dorm_number: number;
  /** 照片 */
  photo?: Maybe<string>;
  /** 状态 上班 下班 */
  status?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 头像 */
  avatar?: Maybe<string>;
}

export interface HousemasterList {
  count: number;

  list: HousemasterEntity[];
}

export interface Count {
  count: number;
}

export interface DepartmentEntity {
  id: string;
  /** 名称 */
  name: string;

  created_date?: Maybe<DateTime>;

  updated_date?: Maybe<DateTime>;
}

export interface DepartmentList {
  count: number;

  list: DepartmentEntity[];
}

export interface JobEntity {
  id: string;
  /** 名称 */
  name: string;

  created_date?: Maybe<DateTime>;

  updated_date?: Maybe<DateTime>;
}

export interface JobList {
  count: number;

  list: JobEntity[];
}

export interface WarnRuleEntity {
  id: string;
  /** 晚归开始计算时间 */
  start_time: string;
  /** 晚归结束计算时间 */
  end_time: string;
  /** 未归开始计算时间 */
  no_return_start_time: string;
  /** 当天 前一天 */
  no_return_type: string;
}

export interface WarnRuleList {
  count: number;

  list: WarnRuleEntity[];
}

export interface NoReturnRecordEntity {
  id: string;
  /** 日期 */
  date: string;
  /** 状态 */
  status: string;
  /** 真实姓名 */
  true_name?: Maybe<string>;
  /** 楼编号 */
  dorm_number: string;
  /** 部门 */
  department?: Maybe<string>;
  /** 日记 */
  note?: Maybe<string>;

  people?: Maybe<PeopleEntity>;

  father_department?: Maybe<string>;
}

export interface NoReturnRecordList {
  count: number;

  list: NoReturnRecordEntity[];
}

export interface BlacklistEntity {
  id: string;
  /** 姓名 */
  true_name: string;
  /** 照片 */
  photo: string;
  /** 备注 */
  remark?: Maybe<string>;

  created_date?: Maybe<DateTime>;
}

export interface BlacklistList {
  count: number;

  list: BlacklistEntity[];
}

export interface StatisticsEntity {
  id: string;
  /** 名称 */
  name: string;
}

export interface Statistics {
  date: DateTime;

  people_count: number;

  work_people_count: number;

  common_people_count: number;

  people_boy_count: number;

  people_girl_count: number;

  last_people: RecognitionResultEntity;

  last_five_in_people: RecognitionResultEntity[];

  last_five_out_people: RecognitionResultEntity[];

  people_in_count: number;

  work_people_in_count: number;

  common_people_in_count: number;

  people_out_count: number;

  today_visitor_count: number;

  today_visitor_in_count: number;

  people_girl_in_count: number;

  people_girl_out_count: number;

  people_boy_in_count: number;

  people_boy_out_count: number;

  in_people_count: number;

  out_people_count: number;

  dorm_flow_counts: DormFlowCount[];

  today_dorm_flow_count: number;

  online_no_return_count: number;

  many_no_return_count: number;

  online_no_out_count: number;

  many_no_out_count: number;

  late_count: number;

  no_return_count: number;

  no_out_count: number;
}

export interface DormFlowCount {
  key: number;

  count: number;
}

export interface LateReturnRecordEntity {
  id: string;
  /** 识别主体id 用户id */
  subject_id?: Maybe<string>;
  /** 匹配到的底片 id */
  origin_photo_id?: Maybe<string>;
  /** 识别库中的用户 学号或者工号 */
  job_number?: Maybe<string>;
  /** 识别到的图片 */
  image_uri?: Maybe<string>;
  /** 身份 title */
  title?: Maybe<string>;
  /** 身份 description */
  description?: Maybe<string>;
  /** 姓名 */
  name?: Maybe<string>;
  /** 姓名 */
  true_name?: Maybe<string>;
  /** 宿舍楼 */
  dorm_number?: Maybe<number>;
  /** 方向 */
  direction?: Maybe<string>;
  /** 老师 学生 陌生人 宿舍管理员 */
  type?: Maybe<string>;
  /** 识别类型 */
  see_type?: Maybe<string>;
  /** 时间 */
  time?: Maybe<string>;
  /** 时间戳 */
  timestamp?: Maybe<number>;
  /** 系 */
  department?: Maybe<string>;
  /** 专业 */
  major?: Maybe<string>;
  /** 年级 */
  grade?: Maybe<number>;
  /** 日记 */
  note?: Maybe<string>;

  created_date?: Maybe<DateTime>;
}

export interface LateReturnRecordList {
  count: number;

  list: LateReturnRecordEntity[];
}

export interface PeopleList {
  count: number;

  list: PeopleEntity[];
}

export interface StructureEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 编号 */
  number: string;
  /** 负责人 */
  leading_cadre: string;
  /** 负责人手机号 */
  mobile: string;
  /** 自己的编号 */
  owner_number: number;
  /** 父级编号 */
  father_number: string;
  /** 排序 */
  sort_number?: Maybe<number>;

  created_date?: Maybe<DateTime>;

  people_count?: Maybe<number>;

  people_in_count?: Maybe<number>;
}

export interface StructureList {
  count: number;

  list: StructureEntity[];
}

export interface CameraEntity {
  id: string;
  /** 相机名称 */
  name?: Maybe<string>;
  /** 入口 出口 */
  type: string;
  /** ip 地址 */
  camera_address: string;
  /** 所属楼 */
  dorm_number: number;
}

export interface CameraList {
  count: number;

  list: CameraEntity[];
}

export interface DailyEntity {
  id: string;
  /** 日期 */
  date: string;
  /** 总人数 */
  people_count: number;
  /** 入住人员数量 */
  common_people_count?: Maybe<number>;
  /** 内部员工总数量 */
  work_people_count?: Maybe<number>;
  /** 女士总人数 */
  girl_count: number;
  /** 男士人数 */
  boy_count: number;
  /** 总床数 */
  bed_count: number;
  /** 在寝人数 */
  now_in_count: number;
  /** 未在寝人数 */
  now_out_count: number;
  /** 入住人员在寝人数 */
  common_now_in_count?: Maybe<number>;
  /** 入住人员未在寝人数 */
  common_now_out_count?: Maybe<number>;
  /** 今日进入人数 */
  in_people_count: number;
  /** 今日离开人数 */
  out_people_count: number;
  /** 今日进入次数 */
  in_count: number;
  /** 今日离开次数 */
  out_count: number;
  /** 未上传照片人数 */
  no_photo_count: number;
}

export interface DailyList {
  count: number;

  list: DailyEntity[];
}

export interface TimingEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 开始时间 */
  start_date: string;
  /** 结束时间 */
  end_date: string;
}

export interface TimingList {
  count: number;

  list: TimingEntity[];
}

export interface LeaveEntity {
  id: string;
  /** 开始日期 */
  start_date: string;
  /** 结束日期 */
  end_date: string;
  /** 部门 */
  department?: Maybe<string>;
  /** 姓名 */
  true_name?: Maybe<string>;

  people?: Maybe<PeopleEntity>;

  father_department?: Maybe<string>;
}

export interface LeaveList {
  count: number;

  list: LeaveEntity[];
}

export interface ScreenEntity {
  id: string;
  /** 管理部门 */
  name: string;
  /** 所属单位 */
  structure_number: string;
  /** 所属单位名称 */
  structure_name?: Maybe<string>;
  /** 房间数量 */
  room_count?: Maybe<number>;
  /** 排序 */
  sort_count?: Maybe<number>;
  /** 是否显示 */
  is_show: boolean;

  created_date?: Maybe<DateTime>;

  department?: Maybe<string>;

  people_count?: Maybe<string>;

  people_in_count?: Maybe<string>;
}

export interface ScreenList {
  count: number;

  list: ScreenEntity[];
}

export interface Mutation {
  /** 管理员登录 */
  loginByAdmin: UserAccessToken;
  /** 创建 */
  createUser: UserEntity;

  changePasswordByOldPassword: UserEntity;
  /** 微信签名 */
  signWeChat: SignWeChatResult;
  /** 创建 */
  createDorm: DormEntity;
  /** 更新 */
  updateDorm: DormEntity;
  /** 删除 */
  deleteDorm: DormEntity;
  /** 创建 */
  createRoom: RoomEntity;
  /** 更新 */
  updateRoom: RoomEntity;
  /** 删除 */
  deleteRoom: RoomEntity;
  /** 批量删除 */
  batchDeleteRoom: Result;
  /** 创建 */
  createRole: Role;
  /** 更新 */
  updateRole: Role;
  /** 删除 */
  deleteRole: Role;
  /** 分配角色 */
  setRole: Role;
  /** 创建 */
  createRoleItem: RoleItem;
  /** 更新 */
  updateRoleItem: RoleItem;
  /** 删除 */
  deleteRoleItem: RoleItem;
  /** 创建 */
  createRecognitionResult: RecognitionResultEntity;
  /** 更新 */
  updateRecognitionResult: RecognitionResultEntity;
  /** 删除 */
  deleteRecognitionResult: RecognitionResultEntity;
  /** 列表 */
  outRecognitionResults: OutResult;
  /** 创建 */
  createFloor: FloorEntity;
  /** 更新 */
  updateFloor: FloorEntity;
  /** 删除 */
  deleteFloor: FloorEntity;
  /** 批量删除 */
  batchDeleteFloor: Result;
  /** 创建 */
  createHousemaster: HousemasterEntity;
  /** 更新 */
  updateHousemaster: HousemasterEntity;
  /** 删除 */
  deleteHousemaster: HousemasterEntity;
  /** 导出 */
  outHousemasters: OutResult;
  /** 创建 */
  createDepartment: DepartmentEntity;
  /** 更新 */
  updateDepartment: DepartmentEntity;
  /** 删除 */
  deleteDepartment: DepartmentEntity;
  /** 创建 */
  createJob: JobEntity;
  /** 更新 */
  updateJob: JobEntity;
  /** 删除 */
  deleteJob: JobEntity;
  /** 创建 */
  createWarnRule: WarnRuleEntity;
  /** 更新 */
  updateWarnRule: WarnRuleEntity;
  /** 删除 */
  deleteWarnRule: WarnRuleEntity;
  /** 创建 */
  createNoReturnRecord: NoReturnRecordEntity;
  /** 更新 */
  updateNoReturnRecord: NoReturnRecordEntity;
  /** 删除 */
  deleteNoReturnRecord: NoReturnRecordEntity;
  /** 导出 */
  outNoReturnRecords: OutResult;
  /** 创建 */
  createNoReturnRecordNote: NoReturnRecordEntity;
  /** 创建 */
  createBlacklist: BlacklistEntity;
  /** 更新 */
  updateBlacklist: BlacklistEntity;
  /** 删除 */
  deleteBlacklist: BlacklistEntity;
  /** 创建 */
  createStatistics: StatisticsEntity;
  /** 更新 */
  updateStatistics: StatisticsEntity;
  /** 删除 */
  deleteStatistics: StatisticsEntity;
  /** 创建 */
  createLateReturnRecord: LateReturnRecordEntity;
  /** 更新 */
  updateLateReturnRecord: LateReturnRecordEntity;
  /** 删除 */
  deleteLateReturnRecord: LateReturnRecordEntity;
  /** 导出 */
  outLateReturnRecords: OutResult;
  /** 创建 */
  createLateReturnRecordNote: LateReturnRecordEntity;
  /** 创建 */
  createPeople: PeopleEntity;
  /** 更新 */
  updatePeople: PeopleEntity;
  /** 删除 */
  deletePeople: PeopleEntity;
  /** 停用 */
  stopPeople: PeopleEntity;
  /** 恢复 */
  reopenPeople: PeopleEntity;
  /** 批量删除 */
  batchDeletePeople: Result;
  /** 导出 */
  outPeople: OutResult;
  /** 导出 */
  outPeopleOnline: OutResult;
  /** 创建部门 */
  createStructure: StructureEntity;
  /** 更新部门 */
  updateStructure: StructureEntity;
  /** 删除部门 */
  deleteStructure: StructureEntity;
  /** 创建 */
  createCamera: CameraEntity;
  /** 更新 */
  updateCamera: CameraEntity;
  /** 删除 */
  deleteCamera: CameraEntity;
  /** 批量删除 */
  batchDeleteCamera: Result;
  /** 创建 */
  createDaily: DailyEntity;
  /** 更新 */
  updateDaily: DailyEntity;
  /** 删除 */
  deleteDaily: DailyEntity;
  /** 创建 */
  createTiming: TimingEntity;
  /** 修改 */
  updateTiming: TimingEntity;
  /** 删除 */
  deleteTiming: TimingEntity;
  /** 创建请假 */
  createLeave: LeaveEntity;
  /** 更新 */
  updateLeave: LeaveEntity;
  /** 删除请假 */
  deleteLeave: LeaveEntity;
  /** 创建 */
  createScreen: ScreenEntity;
  /** 更新 */
  updateScreen: ScreenEntity;
  /** 删除 */
  deleteScreen: ScreenEntity;
}

export interface UserAccessToken {
  user_id?: Maybe<string>;

  expires_in?: Maybe<number>;

  access_token?: Maybe<string>;

  roles: string[];

  permissions: string[];
}

export interface SignWeChatResult {
  debug?: Maybe<boolean>;

  appId?: Maybe<string>;

  timestamp?: Maybe<string>;

  nonceStr?: Maybe<string>;

  signature?: Maybe<string>;

  jsApiList?: Maybe<string[]>;

  mobile?: Maybe<string>;

  true_name?: Maybe<string>;
}

export interface Result {
  success: boolean;
}

export interface OutResult {
  uri?: Maybe<string>;

  token?: Maybe<string>;
}

export interface AccessToken {
  user_id?: Maybe<string>;

  expires_in?: Maybe<number>;

  access_token?: Maybe<string>;
}

export interface Base {
  id: string;
}

export interface BaseMongo {
  id: string;
}

export interface CenterEntity {
  id: string;
  /** 日期 */
  date: string;
  /** 单位名称 */
  name: string;
  /** 服务名称 */
  center?: Maybe<string>;
  /** 总数 */
  count?: Maybe<number>;
  /** 在寝人数 */
  in_count?: Maybe<number>;
  /** 外出人数 */
  out_count?: Maybe<number>;
}

export interface ChartItem {
  key: DateTime;

  value: number;
}

export interface Connection {
  pageInfo?: Maybe<PageInfo>;
}

export interface PageInfo {
  startCursor?: Maybe<string>;

  endCursor?: Maybe<string>;

  hasPreviousPage: boolean;

  hasNextPage: boolean;

  totalCount: boolean;
}

export interface CursorEntity {
  id: string;
  /** 最后一次 cursor */
  last_cursor: string;
  /** 名称 */
  name: string;
}

export interface FieldLogEntity {
  id: string;
  /** 名称 */
  name: string;
}

export interface List {
  count: number;
}

/** 通知 📣 */
export interface NoticeEntity {
  id: string;

  content: string;

  status: string;
}

/** 通知记录 📣 */
export interface NoticeRecordEntity {
  id: string;

  title: string;

  content: string;
}

/** 推荐记录 🚗 */
export interface ReferrerRecordEntity {
  id: string;
}

export interface ScheduleEntity {
  id: string;
  /** 最后一次同步时间 */
  last_date: DateTime;
}

export interface ScheduleEntity2 {
  id: string;
  /** 最后一次同步时间 */
  last_date: DateTime;
}

export interface StatisticsList {
  count: number;

  list: StatisticsEntity[];
}

export interface StsTokenType {
  access_key_id: string;

  policy: string;

  signature: string;

  key: string;

  host: string;

  expiration: DateTime;

  dir?: Maybe<string>;

  callback: string;
}

export interface UnitEntity {
  id: string;
  /** 日期 */
  date: string;
  /** 单位名称 */
  name: string;
  /** 总数 */
  count?: Maybe<number>;
  /** 在寝人数 */
  in_count?: Maybe<number>;
  /** 外出人数 */
  out_count?: Maybe<number>;
}

export interface Uri {
  uri: string;
}

export interface VisitorEntity {
  id: string;
  /** 名称 */
  name: string;
  /** id */
  sid: number;
  /** subject_type */
  subject_type?: Maybe<number>;
  /** visitor_type */
  visitor_type?: Maybe<number>;
  /** 创建时间 */
  create_time?: Maybe<number>;
  /** 创建日期 */
  create_date?: Maybe<string>;
  /** 开始时间 */
  start_time?: Maybe<number>;
  /** 开始日期 */
  start_date?: Maybe<string>;
  /** 结束时间 */
  end_time?: Maybe<number>;
  /** 结束日期 */
  end_date?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 状态 */
  in_status?: Maybe<string>;
}

// ====================================================
// Arguments
// ====================================================

export interface GetLogByIdQueryArgs {
  id: string;
}
export interface LogsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<LogFilterOrder>;
}
export interface UsersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;
}
export interface GetFileByIdQueryArgs {
  id: string;
}
export interface SeeXlsxQueryArgs {
  token: string;
}
export interface GetDormByIdQueryArgs {
  id: string;
}
export interface DormsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DormFilterOrder>;
}
export interface GetRoomByIdQueryArgs {
  id: string;
}
export interface RoomsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RoomFilterOrder>;

  dorm_number?: Maybe<number>;

  dorm_numbers?: Maybe<number[]>;

  floor_number?: Maybe<number>;

  floor_numbers?: Maybe<number[]>;
}
export interface GetRoleByIdQueryArgs {
  id: string;
}
export interface RolesListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RoleFilterOrder>;
}
export interface RoleQueryArgs {
  /** id */
  id: string;
}
export interface GetRoleItemByIdQueryArgs {
  id: string;
}
export interface RoleItemListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RoleItemFilterOrder>;
}
export interface GetRecognitionResultByIdQueryArgs {
  id: string;
}
export interface RecognitionResultsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RecognitionResultFilterOrder>;

  type?: Maybe<string>;

  role?: Maybe<string>;

  job_number?: Maybe<string>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  start_time?: Maybe<string>;

  end_time?: Maybe<string>;

  later?: Maybe<boolean>;

  is_group?: Maybe<boolean>;

  subject_id?: Maybe<number>;

  user_id?: Maybe<string>;

  department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  direction?: Maybe<string[]>;
}
export interface RecognitionResultsByNumberQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RecognitionResultFilterOrder>;

  type?: Maybe<string>;

  role?: Maybe<string>;

  job_number?: Maybe<string>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  start_time?: Maybe<string>;

  end_time?: Maybe<string>;

  later?: Maybe<boolean>;

  is_group?: Maybe<boolean>;

  subject_id?: Maybe<number>;

  user_id?: Maybe<string>;

  department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  direction?: Maybe<string[]>;
}
export interface GetFloorByIdQueryArgs {
  id: string;
}
export interface FloorsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<FloorFilterOrder>;

  dorm_number?: Maybe<number>;

  dorm_numbers?: Maybe<number[]>;
}
export interface GetHousemasterByIdQueryArgs {
  id: string;
}
export interface HousemastersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<HousemasterFilterOrder>;

  photo?: Maybe<string[]>;
}
export interface CountHousemasterQueryArgs {
  job_number?: Maybe<string>;
}
export interface GetDepartmentByIdQueryArgs {
  id: string;
}
export interface DepartmentsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DepartmentFilterOrder>;
}
export interface GetJobByIdQueryArgs {
  id: string;
}
export interface JobsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<JobFilterOrder>;
}
export interface GetWarnRuleByIdQueryArgs {
  id: string;
}
export interface WarnRulesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<WarnRuleFilterOrder>;
}
export interface GetNoReturnRecordByIdQueryArgs {
  id: string;
}
export interface NoReturnRecordsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<NoReturnRecordFilterOrder>;

  role?: Maybe<string>;

  status?: Maybe<string>;

  true_name?: Maybe<string[]>;

  number?: Maybe<string[]>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  major?: Maybe<string[]>;

  department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  grade?: Maybe<number[]>;
}
export interface GetBlacklistByIdQueryArgs {
  id: string;
}
export interface BlacklistsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<BlacklistFilterOrder>;
}
export interface GetStatisticsByIdQueryArgs {
  id: string;
}
export interface GetLateReturnRecordByIdQueryArgs {
  id: string;
}
export interface LateReturnRecordsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<LateReturnRecordFilterOrder>;

  type?: Maybe<string>;

  job_number?: Maybe<string>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  start_time?: Maybe<string>;

  end_time?: Maybe<string>;

  major?: Maybe<string[]>;

  department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  grade?: Maybe<number[]>;

  direction?: Maybe<string[]>;
}
export interface GetPeopleByIdQueryArgs {
  id: string;
}
export interface GetPeopleByNumberQueryArgs {
  number: string;
}
export interface PeopleQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PeopleFilterOrder>;

  grade?: Maybe<number[]>;

  status?: Maybe<string[]>;

  in_status?: Maybe<string[]>;

  photo?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  avatar?: Maybe<string[]>;

  major?: Maybe<string[]>;

  type?: Maybe<string[]>;

  department?: Maybe<string[]>;

  to_department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  no_return_count?: Maybe<number[]>;

  no_out_count?: Maybe<number[]>;

  dorm_numbers?: Maybe<number[]>;

  floor_numbers?: Maybe<number[]>;

  more_no_return_count?: Maybe<number>;
}
export interface PeopleOnlineQueryArgs {
  order?: Maybe<PeopleFilterOrder>;

  grade?: Maybe<number[]>;

  status?: Maybe<string[]>;

  in_status?: Maybe<string[]>;

  photo?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  avatar?: Maybe<string[]>;

  major?: Maybe<string[]>;

  type?: Maybe<string[]>;

  department?: Maybe<string[]>;

  to_department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  no_return_count?: Maybe<number[]>;

  no_out_count?: Maybe<number[]>;

  dorm_numbers?: Maybe<number[]>;

  floor_numbers?: Maybe<number[]>;

  more_no_return_count?: Maybe<number>;

  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  date?: Maybe<string>;

  time?: Maybe<string>;
}
export interface CountPeopleQueryArgs {
  sex?: Maybe<string>;

  status?: Maybe<string>;

  in_status?: Maybe<string>;

  photo?: Maybe<string>;

  major?: Maybe<string[]>;

  department?: Maybe<string[]>;

  dorm_numbers?: Maybe<string[]>;
}
export interface GetStructureByIdQueryArgs {
  id: string;
}
export interface StructuresQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<StructureFilterOrder>;

  father_number?: Maybe<string>;
}
export interface GetCameraByIdQueryArgs {
  id: string;
}
export interface CamerasQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<CameraFilterOrder>;
}
export interface GetDailyByIdQueryArgs {
  id: string;
}
export interface DailiesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DailyFilterOrder>;
}
export interface GetTimingByIdQueryArgs {
  id: string;
}
export interface TimingsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<TimingFilterOrder>;
}
export interface GetLeaveByIdQueryArgs {
  id: string;
}
export interface LeavesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<LeaveFilterOrder>;
}
export interface GetScreenByIdQueryArgs {
  id: string;
}
export interface ScreensQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<ScreenFilterOrder>;
}
export interface InTimeStatusPeopleEntityArgs {
  date?: Maybe<string>;

  time?: Maybe<string>;
}
export interface LoginByAdminMutationArgs {
  LoginByAdmin: LoginByAdmin;
}
export interface CreateUserMutationArgs {
  CreateUser: CreateUser;
}
export interface ChangePasswordByOldPasswordMutationArgs {
  old_password: string;

  password: string;
}
export interface SignWeChatMutationArgs {
  SignWeChatInput: SignWeChatInput;
}
export interface CreateDormMutationArgs {
  CreateDorm: CreateDorm;
}
export interface UpdateDormMutationArgs {
  UpdateDorm: UpdateDorm;
}
export interface DeleteDormMutationArgs {
  /** id */
  id: string;
}
export interface CreateRoomMutationArgs {
  CreateRoom: CreateRoom;
}
export interface UpdateRoomMutationArgs {
  UpdateRoom: UpdateRoom;
}
export interface DeleteRoomMutationArgs {
  /** id */
  id: string;
}
export interface BatchDeleteRoomMutationArgs {
  /** ids */
  ids: string[];
}
export interface CreateRoleMutationArgs {
  CreateRole: CreateRole;
}
export interface UpdateRoleMutationArgs {
  UpdateRole: UpdateRole;
}
export interface DeleteRoleMutationArgs {
  /** id */
  id: string;
}
export interface SetRoleMutationArgs {
  SetRoleInput: SetRoleInput;
}
export interface CreateRoleItemMutationArgs {
  CreateRoleItem: CreateRoleItem;
}
export interface UpdateRoleItemMutationArgs {
  UpdateRoleItem: UpdateRoleItem;
}
export interface DeleteRoleItemMutationArgs {
  /** id */
  id: string;
}
export interface CreateRecognitionResultMutationArgs {
  CreateRecognitionResult: CreateRecognitionResult;
}
export interface UpdateRecognitionResultMutationArgs {
  UpdateRecognitionResult: UpdateRecognitionResult;
}
export interface DeleteRecognitionResultMutationArgs {
  /** id */
  id: string;
}
export interface OutRecognitionResultsMutationArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<RecognitionResultFilterOrder>;

  type?: Maybe<string>;

  role?: Maybe<string>;

  job_number?: Maybe<string>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  start_time?: Maybe<string>;

  end_time?: Maybe<string>;

  later?: Maybe<boolean>;

  is_group?: Maybe<boolean>;

  subject_id?: Maybe<number>;

  user_id?: Maybe<string>;

  department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  direction?: Maybe<string[]>;
}
export interface CreateFloorMutationArgs {
  CreateFloor: CreateFloor;
}
export interface UpdateFloorMutationArgs {
  UpdateFloor: UpdateFloor;
}
export interface DeleteFloorMutationArgs {
  /** id */
  id: string;
}
export interface BatchDeleteFloorMutationArgs {
  /** ids */
  ids: string[];
}
export interface CreateHousemasterMutationArgs {
  CreateHousemaster: CreateHousemaster;
}
export interface UpdateHousemasterMutationArgs {
  UpdateHousemaster: UpdateHousemaster;
}
export interface DeleteHousemasterMutationArgs {
  /** id */
  id: string;
}
export interface OutHousemastersMutationArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<HousemasterFilterOrder>;

  photo?: Maybe<string[]>;
}
export interface CreateDepartmentMutationArgs {
  CreateDepartment: CreateDepartment;
}
export interface UpdateDepartmentMutationArgs {
  UpdateDepartment: UpdateDepartment;
}
export interface DeleteDepartmentMutationArgs {
  /** id */
  id: string;
}
export interface CreateJobMutationArgs {
  CreateJob: CreateJob;
}
export interface UpdateJobMutationArgs {
  UpdateJob: UpdateJob;
}
export interface DeleteJobMutationArgs {
  /** id */
  id: string;
}
export interface CreateWarnRuleMutationArgs {
  CreateWarnRule: CreateWarnRule;
}
export interface UpdateWarnRuleMutationArgs {
  UpdateWarnRule: UpdateWarnRule;
}
export interface DeleteWarnRuleMutationArgs {
  /** id */
  id: string;
}
export interface CreateNoReturnRecordMutationArgs {
  CreateNoReturnRecord: CreateNoReturnRecord;
}
export interface UpdateNoReturnRecordMutationArgs {
  UpdateNoReturnRecord: UpdateNoReturnRecord;
}
export interface DeleteNoReturnRecordMutationArgs {
  /** id */
  id: string;
}
export interface OutNoReturnRecordsMutationArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<NoReturnRecordFilterOrder>;

  role?: Maybe<string>;

  status?: Maybe<string>;

  true_name?: Maybe<string[]>;

  number?: Maybe<string[]>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  major?: Maybe<string[]>;

  department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  grade?: Maybe<number[]>;
}
export interface CreateNoReturnRecordNoteMutationArgs {
  CreateNoReturnRecordNote: CreateNoReturnRecordNote;
}
export interface CreateBlacklistMutationArgs {
  CreateBlacklist: CreateBlacklist;
}
export interface UpdateBlacklistMutationArgs {
  UpdateBlacklist: UpdateBlacklist;
}
export interface DeleteBlacklistMutationArgs {
  /** id */
  id: string;
}
export interface CreateStatisticsMutationArgs {
  CreateStatistics: CreateStatistics;
}
export interface UpdateStatisticsMutationArgs {
  UpdateStatistics: UpdateStatistics;
}
export interface DeleteStatisticsMutationArgs {
  /** id */
  id: string;
}
export interface CreateLateReturnRecordMutationArgs {
  CreateLateReturnRecord: CreateLateReturnRecord;
}
export interface UpdateLateReturnRecordMutationArgs {
  UpdateLateReturnRecord: UpdateLateReturnRecord;
}
export interface DeleteLateReturnRecordMutationArgs {
  /** id */
  id: string;
}
export interface OutLateReturnRecordsMutationArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<LateReturnRecordFilterOrder>;

  type?: Maybe<string>;

  job_number?: Maybe<string>;

  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  start_time?: Maybe<string>;

  end_time?: Maybe<string>;

  major?: Maybe<string[]>;

  department?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  grade?: Maybe<number[]>;

  direction?: Maybe<string[]>;
}
export interface CreateLateReturnRecordNoteMutationArgs {
  CreateLateReturnRecordNote: CreateLateReturnRecordNote;
}
export interface CreatePeopleMutationArgs {
  CreatePeople: CreatePeople;
}
export interface UpdatePeopleMutationArgs {
  UpdatePeople: UpdatePeople;
}
export interface DeletePeopleMutationArgs {
  /** id */
  id: string;
}
export interface StopPeopleMutationArgs {
  /** id */
  id: string;
}
export interface ReopenPeopleMutationArgs {
  /** id */
  id: string;
}
export interface BatchDeletePeopleMutationArgs {
  /** ids */
  ids: string[];
}
export interface OutPeopleMutationArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PeopleFilterOrder>;

  grade?: Maybe<number[]>;

  status?: Maybe<string[]>;

  in_status?: Maybe<string[]>;

  photo?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  avatar?: Maybe<string[]>;

  major?: Maybe<string[]>;

  type?: Maybe<string[]>;

  department?: Maybe<string[]>;

  to_department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  no_return_count?: Maybe<number[]>;

  no_out_count?: Maybe<number[]>;

  dorm_numbers?: Maybe<number[]>;

  floor_numbers?: Maybe<number[]>;

  more_no_return_count?: Maybe<number>;
}
export interface OutPeopleOnlineMutationArgs {
  order?: Maybe<PeopleFilterOrder>;

  grade?: Maybe<number[]>;

  status?: Maybe<string[]>;

  in_status?: Maybe<string[]>;

  photo?: Maybe<string[]>;

  dorm_number?: Maybe<number[]>;

  avatar?: Maybe<string[]>;

  major?: Maybe<string[]>;

  type?: Maybe<string[]>;

  department?: Maybe<string[]>;

  to_department?: Maybe<string[]>;

  father_department?: Maybe<string[]>;

  no_return_count?: Maybe<number[]>;

  no_out_count?: Maybe<number[]>;

  dorm_numbers?: Maybe<number[]>;

  floor_numbers?: Maybe<number[]>;

  more_no_return_count?: Maybe<number>;

  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  date?: Maybe<string>;

  time?: Maybe<string>;
}
export interface CreateStructureMutationArgs {
  CreateStructure: CreateStructure;
}
export interface UpdateStructureMutationArgs {
  UpdateStructure: UpdateStructure;
}
export interface DeleteStructureMutationArgs {
  /** id */
  id: string;
}
export interface CreateCameraMutationArgs {
  CreateCamera: CreateCamera;
}
export interface UpdateCameraMutationArgs {
  UpdateCamera: UpdateCamera;
}
export interface DeleteCameraMutationArgs {
  /** id */
  id: string;
}
export interface BatchDeleteCameraMutationArgs {
  /** ids */
  ids: string[];
}
export interface CreateDailyMutationArgs {
  CreateDaily: CreateDaily;
}
export interface UpdateDailyMutationArgs {
  UpdateDaily: UpdateDaily;
}
export interface DeleteDailyMutationArgs {
  /** id */
  id: string;
}
export interface CreateTimingMutationArgs {
  CreateTiming: CreateTiming;
}
export interface UpdateTimingMutationArgs {
  UpdateTiming: UpdateTiming;
}
export interface DeleteTimingMutationArgs {
  /** id */
  id: string;
}
export interface CreateLeaveMutationArgs {
  CreateLeave: CreateLeave;
}
export interface UpdateLeaveMutationArgs {
  UpdateLeave: UpdateLeave;
}
export interface DeleteLeaveMutationArgs {
  /** id */
  id: string;
}
export interface CreateScreenMutationArgs {
  CreateScreen: CreateScreen;
}
export interface UpdateScreenMutationArgs {
  UpdateScreen: UpdateScreen;
}
export interface DeleteScreenMutationArgs {
  /** id */
  id: string;
}






// ====================================================
// Components
// ====================================================

export const BlacklistsDocument = gql`
  query Blacklists($search: String, $skip: Int = 0, $take: Int = 25) {
    blacklists(take: $take, search: $search, skip: $skip) {
      count
      list {
        id
        true_name
        photo
        remark
      }
    }
  }
`;
export interface BlacklistsQuery {
  blacklists: Query["blacklists"];
}
export class BlacklistsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<BlacklistsQuery, BlacklistsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<BlacklistsQuery, BlacklistsQueryArgs>
        query={BlacklistsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BlacklistsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<BlacklistsQuery, BlacklistsQueryArgs>
> &
  TChildProps;
export function BlacklistsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BlacklistsQuery,
        BlacklistsQueryArgs,
        BlacklistsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BlacklistsQuery,
    BlacklistsQueryArgs,
    BlacklistsProps<TChildProps>
  >(BlacklistsDocument, operationOptions);
}
export const CountBlacklistDocument = gql`
  query CountBlacklist {
    countBlacklist {
      count
    }
  }
`;
export interface CountBlacklistQuery {
  countBlacklist: Query["countBlacklist"];
}
export class CountBlacklistComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CountBlacklistQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<CountBlacklistQuery, any>
        query={CountBlacklistDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CountBlacklistProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CountBlacklistQuery, any>
> &
  TChildProps;
export function CountBlacklistHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CountBlacklistQuery,
        any,
        CountBlacklistProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CountBlacklistQuery,
    any,
    CountBlacklistProps<TChildProps>
  >(CountBlacklistDocument, operationOptions);
}
export const CreateBlacklistDocument = gql`
  mutation CreateBlacklist($CreateBlacklist: CreateBlacklist!) {
    createBlacklist(CreateBlacklist: $CreateBlacklist) {
      id
    }
  }
`;
export interface CreateBlacklistMutation {
  createBlacklist: Mutation["createBlacklist"];
}
export class CreateBlacklistComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateBlacklistMutation,
      CreateBlacklistMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateBlacklistMutation,
        CreateBlacklistMutationArgs
      >
        mutation={CreateBlacklistDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateBlacklistProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateBlacklistMutation, CreateBlacklistMutationArgs>
> &
  TChildProps;
export type CreateBlacklistMutationFn = ReactApollo.MutationFn<
  CreateBlacklistMutation,
  CreateBlacklistMutationArgs
>;
export function CreateBlacklistHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateBlacklistMutation,
        CreateBlacklistMutationArgs,
        CreateBlacklistProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateBlacklistMutation,
    CreateBlacklistMutationArgs,
    CreateBlacklistProps<TChildProps>
  >(CreateBlacklistDocument, operationOptions);
}
export const DeleteBlacklistDocument = gql`
  mutation DeleteBlacklist($id: String!) {
    deleteBlacklist(id: $id) {
      id
    }
  }
`;
export interface DeleteBlacklistMutation {
  deleteBlacklist: Mutation["deleteBlacklist"];
}
export class DeleteBlacklistComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteBlacklistMutation,
      DeleteBlacklistMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteBlacklistMutation,
        DeleteBlacklistMutationArgs
      >
        mutation={DeleteBlacklistDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteBlacklistProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteBlacklistMutation, DeleteBlacklistMutationArgs>
> &
  TChildProps;
export type DeleteBlacklistMutationFn = ReactApollo.MutationFn<
  DeleteBlacklistMutation,
  DeleteBlacklistMutationArgs
>;
export function DeleteBlacklistHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteBlacklistMutation,
        DeleteBlacklistMutationArgs,
        DeleteBlacklistProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteBlacklistMutation,
    DeleteBlacklistMutationArgs,
    DeleteBlacklistProps<TChildProps>
  >(DeleteBlacklistDocument, operationOptions);
}
export const UpdateBlacklistDocument = gql`
  mutation UpdateBlacklist($UpdateBlacklist: UpdateBlacklist!) {
    updateBlacklist(UpdateBlacklist: $UpdateBlacklist) {
      id
    }
  }
`;
export interface UpdateBlacklistMutation {
  updateBlacklist: Mutation["updateBlacklist"];
}
export class UpdateBlacklistComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateBlacklistMutation,
      UpdateBlacklistMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateBlacklistMutation,
        UpdateBlacklistMutationArgs
      >
        mutation={UpdateBlacklistDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateBlacklistProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateBlacklistMutation, UpdateBlacklistMutationArgs>
> &
  TChildProps;
export type UpdateBlacklistMutationFn = ReactApollo.MutationFn<
  UpdateBlacklistMutation,
  UpdateBlacklistMutationArgs
>;
export function UpdateBlacklistHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateBlacklistMutation,
        UpdateBlacklistMutationArgs,
        UpdateBlacklistProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateBlacklistMutation,
    UpdateBlacklistMutationArgs,
    UpdateBlacklistProps<TChildProps>
  >(UpdateBlacklistDocument, operationOptions);
}
export const BatchDeleteCameraDocument = gql`
  mutation BatchDeleteCamera($ids: [String!]!) {
    batchDeleteCamera(ids: $ids) {
      success
    }
  }
`;
export interface BatchDeleteCameraMutation {
  batchDeleteCamera: Mutation["batchDeleteCamera"];
}
export class BatchDeleteCameraComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BatchDeleteCameraMutation,
      BatchDeleteCameraMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BatchDeleteCameraMutation,
        BatchDeleteCameraMutationArgs
      >
        mutation={BatchDeleteCameraDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BatchDeleteCameraProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    BatchDeleteCameraMutation,
    BatchDeleteCameraMutationArgs
  >
> &
  TChildProps;
export type BatchDeleteCameraMutationFn = ReactApollo.MutationFn<
  BatchDeleteCameraMutation,
  BatchDeleteCameraMutationArgs
>;
export function BatchDeleteCameraHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BatchDeleteCameraMutation,
        BatchDeleteCameraMutationArgs,
        BatchDeleteCameraProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BatchDeleteCameraMutation,
    BatchDeleteCameraMutationArgs,
    BatchDeleteCameraProps<TChildProps>
  >(BatchDeleteCameraDocument, operationOptions);
}
export const CamerasDocument = gql`
  query Cameras($take: Int, $skip: Int, $search: String) {
    cameras(take: $take, skip: $skip, search: $search) {
      count
      list {
        id
        name
        type
        camera_address
        dorm_number
      }
    }
  }
`;
export interface CamerasQuery {
  cameras: Query["cameras"];
}
export class CamerasComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CamerasQuery, CamerasQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<CamerasQuery, CamerasQueryArgs>
        query={CamerasDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CamerasProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CamerasQuery, CamerasQueryArgs>
> &
  TChildProps;
export function CamerasHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CamerasQuery,
        CamerasQueryArgs,
        CamerasProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CamerasQuery,
    CamerasQueryArgs,
    CamerasProps<TChildProps>
  >(CamerasDocument, operationOptions);
}
export const CreateCameraDocument = gql`
  mutation CreateCamera($CreateCamera: CreateCamera!) {
    createCamera(CreateCamera: $CreateCamera) {
      id
    }
  }
`;
export interface CreateCameraMutation {
  createCamera: Mutation["createCamera"];
}
export class CreateCameraComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateCameraMutation, CreateCameraMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateCameraMutation, CreateCameraMutationArgs>
        mutation={CreateCameraDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCameraProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateCameraMutation, CreateCameraMutationArgs>
> &
  TChildProps;
export type CreateCameraMutationFn = ReactApollo.MutationFn<
  CreateCameraMutation,
  CreateCameraMutationArgs
>;
export function CreateCameraHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCameraMutation,
        CreateCameraMutationArgs,
        CreateCameraProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCameraMutation,
    CreateCameraMutationArgs,
    CreateCameraProps<TChildProps>
  >(CreateCameraDocument, operationOptions);
}
export const DeleteCameraDocument = gql`
  mutation DeleteCamera($id: String!) {
    deleteCamera(id: $id) {
      id
    }
  }
`;
export interface DeleteCameraMutation {
  deleteCamera: Mutation["deleteCamera"];
}
export class DeleteCameraComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteCameraMutation, DeleteCameraMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteCameraMutation, DeleteCameraMutationArgs>
        mutation={DeleteCameraDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCameraProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteCameraMutation, DeleteCameraMutationArgs>
> &
  TChildProps;
export type DeleteCameraMutationFn = ReactApollo.MutationFn<
  DeleteCameraMutation,
  DeleteCameraMutationArgs
>;
export function DeleteCameraHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCameraMutation,
        DeleteCameraMutationArgs,
        DeleteCameraProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCameraMutation,
    DeleteCameraMutationArgs,
    DeleteCameraProps<TChildProps>
  >(DeleteCameraDocument, operationOptions);
}
export const UpdateCameraDocument = gql`
  mutation UpdateCamera($UpdateCamera: UpdateCamera!) {
    updateCamera(UpdateCamera: $UpdateCamera) {
      id
    }
  }
`;
export interface UpdateCameraMutation {
  updateCamera: Mutation["updateCamera"];
}
export class UpdateCameraComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateCameraMutation, UpdateCameraMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateCameraMutation, UpdateCameraMutationArgs>
        mutation={UpdateCameraDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCameraProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateCameraMutation, UpdateCameraMutationArgs>
> &
  TChildProps;
export type UpdateCameraMutationFn = ReactApollo.MutationFn<
  UpdateCameraMutation,
  UpdateCameraMutationArgs
>;
export function UpdateCameraHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCameraMutation,
        UpdateCameraMutationArgs,
        UpdateCameraProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCameraMutation,
    UpdateCameraMutationArgs,
    UpdateCameraProps<TChildProps>
  >(UpdateCameraDocument, operationOptions);
}
export const CommonFilterDocument = gql`
  query CommonFilter {
    dorms(take: 100) {
      count
      list {
        id
        number
      }
    }
    structures(take: 100, father_number: "0") {
      count
      list {
        id
        name
        father_number
        number
      }
    }
  }
`;
export interface CommonFilterQuery {
  dorms: Query["dorms"];
}
export class CommonFilterComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CommonFilterQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<CommonFilterQuery, any>
        query={CommonFilterDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CommonFilterProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CommonFilterQuery, any>
> &
  TChildProps;
export function CommonFilterHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CommonFilterQuery,
        any,
        CommonFilterProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CommonFilterQuery,
    any,
    CommonFilterProps<TChildProps>
  >(CommonFilterDocument, operationOptions);
}
export const DailiesDocument = gql`
  query Dailies($take: Int, $skip: Int) {
    dailies(take: $take, skip: $skip) {
      count
      list {
        id
        date
        people_count
        girl_count
        boy_count
        bed_count
        now_in_count
        now_out_count
        in_people_count
        out_people_count
        in_count
        out_count
        no_photo_count
        common_people_count
        work_people_count
        common_now_in_count
        common_now_out_count
      }
    }
  }
`;
export interface DailiesQuery {
  dailies: Query["dailies"];
}
export class DailiesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DailiesQuery, DailiesQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<DailiesQuery, DailiesQueryArgs>
        query={DailiesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DailiesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DailiesQuery, DailiesQueryArgs>
> &
  TChildProps;
export function DailiesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DailiesQuery,
        DailiesQueryArgs,
        DailiesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DailiesQuery,
    DailiesQueryArgs,
    DailiesProps<TChildProps>
  >(DailiesDocument, operationOptions);
}
export const CountDepartmentDocument = gql`
  query CountDepartment {
    countDepartment {
      count
    }
  }
`;
export interface CountDepartmentQuery {
  countDepartment: Query["countDepartment"];
}
export class CountDepartmentComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<CountDepartmentQuery, any>
  >
> {
  render() {
    return (
      <ReactApollo.Query<CountDepartmentQuery, any>
        query={CountDepartmentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CountDepartmentProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CountDepartmentQuery, any>
> &
  TChildProps;
export function CountDepartmentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CountDepartmentQuery,
        any,
        CountDepartmentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CountDepartmentQuery,
    any,
    CountDepartmentProps<TChildProps>
  >(CountDepartmentDocument, operationOptions);
}
export const CreateDepartmentDocument = gql`
  mutation CreateDepartment($CreateDepartment: CreateDepartment!) {
    createDepartment(CreateDepartment: $CreateDepartment) {
      id
    }
  }
`;
export interface CreateDepartmentMutation {
  createDepartment: Mutation["createDepartment"];
}
export class CreateDepartmentComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateDepartmentMutation,
      CreateDepartmentMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateDepartmentMutation,
        CreateDepartmentMutationArgs
      >
        mutation={CreateDepartmentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDepartmentProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateDepartmentMutation,
    CreateDepartmentMutationArgs
  >
> &
  TChildProps;
export type CreateDepartmentMutationFn = ReactApollo.MutationFn<
  CreateDepartmentMutation,
  CreateDepartmentMutationArgs
>;
export function CreateDepartmentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDepartmentMutation,
        CreateDepartmentMutationArgs,
        CreateDepartmentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDepartmentMutation,
    CreateDepartmentMutationArgs,
    CreateDepartmentProps<TChildProps>
  >(CreateDepartmentDocument, operationOptions);
}
export const DeleteDepartmentDocument = gql`
  mutation DeleteDepartment($id: String!) {
    deleteDepartment(id: $id) {
      id
    }
  }
`;
export interface DeleteDepartmentMutation {
  deleteDepartment: Mutation["deleteDepartment"];
}
export class DeleteDepartmentComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteDepartmentMutation,
      DeleteDepartmentMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteDepartmentMutation,
        DeleteDepartmentMutationArgs
      >
        mutation={DeleteDepartmentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteDepartmentProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteDepartmentMutation,
    DeleteDepartmentMutationArgs
  >
> &
  TChildProps;
export type DeleteDepartmentMutationFn = ReactApollo.MutationFn<
  DeleteDepartmentMutation,
  DeleteDepartmentMutationArgs
>;
export function DeleteDepartmentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteDepartmentMutation,
        DeleteDepartmentMutationArgs,
        DeleteDepartmentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteDepartmentMutation,
    DeleteDepartmentMutationArgs,
    DeleteDepartmentProps<TChildProps>
  >(DeleteDepartmentDocument, operationOptions);
}
export const DepartmentsDocument = gql`
  query Departments($search: String, $skip: Int = 0, $take: Int = 25) {
    departments(take: $take, search: $search, skip: $skip) {
      count
      list {
        id
        name
        created_date
        updated_date
      }
    }
  }
`;
export interface DepartmentsQuery {
  departments: Query["departments"];
}
export class DepartmentsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DepartmentsQuery, DepartmentsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<DepartmentsQuery, DepartmentsQueryArgs>
        query={DepartmentsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DepartmentsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DepartmentsQuery, DepartmentsQueryArgs>
> &
  TChildProps;
export function DepartmentsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DepartmentsQuery,
        DepartmentsQueryArgs,
        DepartmentsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DepartmentsQuery,
    DepartmentsQueryArgs,
    DepartmentsProps<TChildProps>
  >(DepartmentsDocument, operationOptions);
}
export const UpdateDepartmentDocument = gql`
  mutation UpdateDepartment($UpdateDepartment: UpdateDepartment!) {
    updateDepartment(UpdateDepartment: $UpdateDepartment) {
      id
    }
  }
`;
export interface UpdateDepartmentMutation {
  updateDepartment: Mutation["updateDepartment"];
}
export class UpdateDepartmentComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateDepartmentMutation,
      UpdateDepartmentMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateDepartmentMutation,
        UpdateDepartmentMutationArgs
      >
        mutation={UpdateDepartmentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateDepartmentProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationArgs
  >
> &
  TChildProps;
export type UpdateDepartmentMutationFn = ReactApollo.MutationFn<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationArgs
>;
export function UpdateDepartmentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateDepartmentMutation,
        UpdateDepartmentMutationArgs,
        UpdateDepartmentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateDepartmentMutation,
    UpdateDepartmentMutationArgs,
    UpdateDepartmentProps<TChildProps>
  >(UpdateDepartmentDocument, operationOptions);
}
export const CreateDormDocument = gql`
  mutation CreateDorm($CreateDorm: CreateDorm!) {
    createDorm(CreateDorm: $CreateDorm) {
      id
    }
  }
`;
export interface CreateDormMutation {
  createDorm: Mutation["createDorm"];
}
export class CreateDormComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateDormMutation, CreateDormMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateDormMutation, CreateDormMutationArgs>
        mutation={CreateDormDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDormProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateDormMutation, CreateDormMutationArgs>
> &
  TChildProps;
export type CreateDormMutationFn = ReactApollo.MutationFn<
  CreateDormMutation,
  CreateDormMutationArgs
>;
export function CreateDormHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDormMutation,
        CreateDormMutationArgs,
        CreateDormProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDormMutation,
    CreateDormMutationArgs,
    CreateDormProps<TChildProps>
  >(CreateDormDocument, operationOptions);
}
export const DeleteDormDocument = gql`
  mutation DeleteDorm($id: String!) {
    deleteDorm(id: $id) {
      id
    }
  }
`;
export interface DeleteDormMutation {
  deleteDorm: Mutation["deleteDorm"];
}
export class DeleteDormComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteDormMutation, DeleteDormMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteDormMutation, DeleteDormMutationArgs>
        mutation={DeleteDormDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteDormProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteDormMutation, DeleteDormMutationArgs>
> &
  TChildProps;
export type DeleteDormMutationFn = ReactApollo.MutationFn<
  DeleteDormMutation,
  DeleteDormMutationArgs
>;
export function DeleteDormHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteDormMutation,
        DeleteDormMutationArgs,
        DeleteDormProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteDormMutation,
    DeleteDormMutationArgs,
    DeleteDormProps<TChildProps>
  >(DeleteDormDocument, operationOptions);
}
export const DormCountDocument = gql`
  query DormCount {
    dorms {
      count
      list {
        id
        number
        people_count
        people_in_count
      }
    }
  }
`;
export interface DormCountQuery {
  dorms: Query["dorms"];
}
export class DormCountComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormCountQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DormCountQuery, any>
        query={DormCountDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormCountProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormCountQuery, any>
> &
  TChildProps;
export function DormCountHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormCountQuery,
        any,
        DormCountProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormCountQuery,
    any,
    DormCountProps<TChildProps>
  >(DormCountDocument, operationOptions);
}
export const DormFlowDocument = gql`
  query DormFlow {
    dorms {
      count
      list {
        id
        number
        today_flow_count
      }
    }
  }
`;
export interface DormFlowQuery {
  dorms: Query["dorms"];
}
export class DormFlowComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormFlowQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DormFlowQuery, any>
        query={DormFlowDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormFlowProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormFlowQuery, any>
> &
  TChildProps;
export function DormFlowHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormFlowQuery,
        any,
        DormFlowProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormFlowQuery,
    any,
    DormFlowProps<TChildProps>
  >(DormFlowDocument, operationOptions);
}
export const DormLateDocument = gql`
  query DormLate {
    dorms {
      count
      list {
        id
        number
        late_count
      }
    }
  }
`;
export interface DormLateQuery {
  dorms: Query["dorms"];
}
export class DormLateComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormLateQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DormLateQuery, any>
        query={DormLateDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormLateProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormLateQuery, any>
> &
  TChildProps;
export function DormLateHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormLateQuery,
        any,
        DormLateProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormLateQuery,
    any,
    DormLateProps<TChildProps>
  >(DormLateDocument, operationOptions);
}
export const DormNoReturnDocument = gql`
  query DormNoReturn {
    dorms {
      count
      list {
        id
        number
        no_return_count
      }
    }
  }
`;
export interface DormNoReturnQuery {
  dorms: Query["dorms"];
}
export class DormNoReturnComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormNoReturnQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DormNoReturnQuery, any>
        query={DormNoReturnDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormNoReturnProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormNoReturnQuery, any>
> &
  TChildProps;
export function DormNoReturnHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormNoReturnQuery,
        any,
        DormNoReturnProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormNoReturnQuery,
    any,
    DormNoReturnProps<TChildProps>
  >(DormNoReturnDocument, operationOptions);
}
export const DormsDocument = gql`
  query Dorms($take: Int) {
    dorms(take: $take) {
      count
      list {
        id
        name
        number
        floor_count
        floor_room_count
        type
      }
    }
  }
`;
export interface DormsQuery {
  dorms: Query["dorms"];
}
export class DormsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormsQuery, DormsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<DormsQuery, DormsQueryArgs>
        query={DormsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormsQuery, DormsQueryArgs>
> &
  TChildProps;
export function DormsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormsQuery,
        DormsQueryArgs,
        DormsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormsQuery,
    DormsQueryArgs,
    DormsProps<TChildProps>
  >(DormsDocument, operationOptions);
}
export const DormsFloorDocument = gql`
  query DormsFloor($take: Int) {
    dorms(take: $take) {
      count
      list {
        id
        number
      }
    }
  }
`;
export interface DormsFloorQuery {
  dorms: Query["dorms"];
}
export class DormsFloorComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DormsFloorQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DormsFloorQuery, any>
        query={DormsFloorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DormsFloorProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DormsFloorQuery, any>
> &
  TChildProps;
export function DormsFloorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DormsFloorQuery,
        any,
        DormsFloorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DormsFloorQuery,
    any,
    DormsFloorProps<TChildProps>
  >(DormsFloorDocument, operationOptions);
}
export const UpdateDormDocument = gql`
  mutation UpdateDorm($UpdateDorm: UpdateDorm!) {
    updateDorm(UpdateDorm: $UpdateDorm) {
      id
    }
  }
`;
export interface UpdateDormMutation {
  updateDorm: Mutation["updateDorm"];
}
export class UpdateDormComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateDormMutation, UpdateDormMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateDormMutation, UpdateDormMutationArgs>
        mutation={UpdateDormDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateDormProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateDormMutation, UpdateDormMutationArgs>
> &
  TChildProps;
export type UpdateDormMutationFn = ReactApollo.MutationFn<
  UpdateDormMutation,
  UpdateDormMutationArgs
>;
export function UpdateDormHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateDormMutation,
        UpdateDormMutationArgs,
        UpdateDormProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateDormMutation,
    UpdateDormMutationArgs,
    UpdateDormProps<TChildProps>
  >(UpdateDormDocument, operationOptions);
}
export const BatchDeleteFloorDocument = gql`
  mutation BatchDeleteFloor($ids: [String!]!) {
    batchDeleteFloor(ids: $ids) {
      success
    }
  }
`;
export interface BatchDeleteFloorMutation {
  batchDeleteFloor: Mutation["batchDeleteFloor"];
}
export class BatchDeleteFloorComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BatchDeleteFloorMutation,
      BatchDeleteFloorMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BatchDeleteFloorMutation,
        BatchDeleteFloorMutationArgs
      >
        mutation={BatchDeleteFloorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BatchDeleteFloorProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    BatchDeleteFloorMutation,
    BatchDeleteFloorMutationArgs
  >
> &
  TChildProps;
export type BatchDeleteFloorMutationFn = ReactApollo.MutationFn<
  BatchDeleteFloorMutation,
  BatchDeleteFloorMutationArgs
>;
export function BatchDeleteFloorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BatchDeleteFloorMutation,
        BatchDeleteFloorMutationArgs,
        BatchDeleteFloorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BatchDeleteFloorMutation,
    BatchDeleteFloorMutationArgs,
    BatchDeleteFloorProps<TChildProps>
  >(BatchDeleteFloorDocument, operationOptions);
}
export const CreateFloorDocument = gql`
  mutation CreateFloor($CreateFloor: CreateFloor!) {
    createFloor(CreateFloor: $CreateFloor) {
      id
    }
  }
`;
export interface CreateFloorMutation {
  createFloor: Mutation["createFloor"];
}
export class CreateFloorComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateFloorMutation, CreateFloorMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateFloorMutation, CreateFloorMutationArgs>
        mutation={CreateFloorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateFloorProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateFloorMutation, CreateFloorMutationArgs>
> &
  TChildProps;
export type CreateFloorMutationFn = ReactApollo.MutationFn<
  CreateFloorMutation,
  CreateFloorMutationArgs
>;
export function CreateFloorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateFloorMutation,
        CreateFloorMutationArgs,
        CreateFloorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateFloorMutation,
    CreateFloorMutationArgs,
    CreateFloorProps<TChildProps>
  >(CreateFloorDocument, operationOptions);
}
export const DeleteFloorDocument = gql`
  mutation DeleteFloor($id: String!) {
    deleteFloor(id: $id) {
      id
    }
  }
`;
export interface DeleteFloorMutation {
  deleteFloor: Mutation["deleteFloor"];
}
export class DeleteFloorComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteFloorMutation, DeleteFloorMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteFloorMutation, DeleteFloorMutationArgs>
        mutation={DeleteFloorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteFloorProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteFloorMutation, DeleteFloorMutationArgs>
> &
  TChildProps;
export type DeleteFloorMutationFn = ReactApollo.MutationFn<
  DeleteFloorMutation,
  DeleteFloorMutationArgs
>;
export function DeleteFloorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteFloorMutation,
        DeleteFloorMutationArgs,
        DeleteFloorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteFloorMutation,
    DeleteFloorMutationArgs,
    DeleteFloorProps<TChildProps>
  >(DeleteFloorDocument, operationOptions);
}
export const FloorsDocument = gql`
  query Floors(
    $dorm_number: Float
    $dorm_numbers: [Float!]
    $take: Int
    $skip: Int
  ) {
    floors(
      dorm_number: $dorm_number
      dorm_numbers: $dorm_numbers
      take: $take
      skip: $skip
    ) {
      count
      list {
        id
        dorm_number
        number
        uri
        count_people
        count_in_people
        room_count
        room_full_count
        room_use_count
        room_empty_count
      }
    }
  }
`;
export interface FloorsQuery {
  floors: Query["floors"];
}
export class FloorsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FloorsQuery, FloorsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<FloorsQuery, FloorsQueryArgs>
        query={FloorsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FloorsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FloorsQuery, FloorsQueryArgs>
> &
  TChildProps;
export function FloorsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FloorsQuery,
        FloorsQueryArgs,
        FloorsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FloorsQuery,
    FloorsQueryArgs,
    FloorsProps<TChildProps>
  >(FloorsDocument, operationOptions);
}
export const UpdateFloorDocument = gql`
  mutation UpdateFloor($UpdateFloor: UpdateFloor!) {
    updateFloor(UpdateFloor: $UpdateFloor) {
      id
    }
  }
`;
export interface UpdateFloorMutation {
  updateFloor: Mutation["updateFloor"];
}
export class UpdateFloorComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateFloorMutation, UpdateFloorMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateFloorMutation, UpdateFloorMutationArgs>
        mutation={UpdateFloorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateFloorProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateFloorMutation, UpdateFloorMutationArgs>
> &
  TChildProps;
export type UpdateFloorMutationFn = ReactApollo.MutationFn<
  UpdateFloorMutation,
  UpdateFloorMutationArgs
>;
export function UpdateFloorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateFloorMutation,
        UpdateFloorMutationArgs,
        UpdateFloorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateFloorMutation,
    UpdateFloorMutationArgs,
    UpdateFloorProps<TChildProps>
  >(UpdateFloorDocument, operationOptions);
}
export const CountHousemasterDocument = gql`
  query CountHousemaster {
    countHousemaster {
      count
    }
  }
`;
export interface CountHousemasterQuery {
  countHousemaster: Query["countHousemaster"];
}
export class CountHousemasterComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<CountHousemasterQuery, CountHousemasterQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<CountHousemasterQuery, CountHousemasterQueryArgs>
        query={CountHousemasterDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CountHousemasterProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CountHousemasterQuery, CountHousemasterQueryArgs>
> &
  TChildProps;
export function CountHousemasterHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CountHousemasterQuery,
        CountHousemasterQueryArgs,
        CountHousemasterProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CountHousemasterQuery,
    CountHousemasterQueryArgs,
    CountHousemasterProps<TChildProps>
  >(CountHousemasterDocument, operationOptions);
}
export const CreateHousemasterDocument = gql`
  mutation CreateHousemaster($CreateHousemaster: CreateHousemaster!) {
    createHousemaster(CreateHousemaster: $CreateHousemaster) {
      id
    }
  }
`;
export interface CreateHousemasterMutation {
  createHousemaster: Mutation["createHousemaster"];
}
export class CreateHousemasterComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateHousemasterMutation,
      CreateHousemasterMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateHousemasterMutation,
        CreateHousemasterMutationArgs
      >
        mutation={CreateHousemasterDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateHousemasterProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateHousemasterMutation,
    CreateHousemasterMutationArgs
  >
> &
  TChildProps;
export type CreateHousemasterMutationFn = ReactApollo.MutationFn<
  CreateHousemasterMutation,
  CreateHousemasterMutationArgs
>;
export function CreateHousemasterHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateHousemasterMutation,
        CreateHousemasterMutationArgs,
        CreateHousemasterProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateHousemasterMutation,
    CreateHousemasterMutationArgs,
    CreateHousemasterProps<TChildProps>
  >(CreateHousemasterDocument, operationOptions);
}
export const DeleteHousemasterDocument = gql`
  mutation DeleteHousemaster($id: String!) {
    deleteHousemaster(id: $id) {
      id
    }
  }
`;
export interface DeleteHousemasterMutation {
  deleteHousemaster: Mutation["deleteHousemaster"];
}
export class DeleteHousemasterComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteHousemasterMutation,
      DeleteHousemasterMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteHousemasterMutation,
        DeleteHousemasterMutationArgs
      >
        mutation={DeleteHousemasterDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteHousemasterProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteHousemasterMutation,
    DeleteHousemasterMutationArgs
  >
> &
  TChildProps;
export type DeleteHousemasterMutationFn = ReactApollo.MutationFn<
  DeleteHousemasterMutation,
  DeleteHousemasterMutationArgs
>;
export function DeleteHousemasterHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteHousemasterMutation,
        DeleteHousemasterMutationArgs,
        DeleteHousemasterProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteHousemasterMutation,
    DeleteHousemasterMutationArgs,
    DeleteHousemasterProps<TChildProps>
  >(DeleteHousemasterDocument, operationOptions);
}
export const HousemastersDocument = gql`
  query Housemasters(
    $search: String
    $skip: Int = 0
    $take: Int = 25
    $photo: [String!]
  ) {
    housemasters(take: $take, search: $search, skip: $skip, photo: $photo) {
      count
      list {
        id
        true_name
        mobile
        job_number
        start_date
        dorm_number
        photo
        status
        remark
        avatar
      }
    }
  }
`;
export interface HousemastersQuery {
  housemasters: Query["housemasters"];
}
export class HousemastersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<HousemastersQuery, HousemastersQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<HousemastersQuery, HousemastersQueryArgs>
        query={HousemastersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type HousemastersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<HousemastersQuery, HousemastersQueryArgs>
> &
  TChildProps;
export function HousemastersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        HousemastersQuery,
        HousemastersQueryArgs,
        HousemastersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    HousemastersQuery,
    HousemastersQueryArgs,
    HousemastersProps<TChildProps>
  >(HousemastersDocument, operationOptions);
}
export const OutHousemastersDocument = gql`
  mutation OutHousemasters(
    $search: String
    $columns: [String!]
    $photo: [String!]
  ) {
    outHousemasters(search: $search, columns: $columns, photo: $photo) {
      uri
      token
    }
  }
`;
export interface OutHousemastersMutation {
  outHousemasters: Mutation["outHousemasters"];
}
export class OutHousemastersComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      OutHousemastersMutation,
      OutHousemastersMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        OutHousemastersMutation,
        OutHousemastersMutationArgs
      >
        mutation={OutHousemastersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutHousemastersProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<OutHousemastersMutation, OutHousemastersMutationArgs>
> &
  TChildProps;
export type OutHousemastersMutationFn = ReactApollo.MutationFn<
  OutHousemastersMutation,
  OutHousemastersMutationArgs
>;
export function OutHousemastersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutHousemastersMutation,
        OutHousemastersMutationArgs,
        OutHousemastersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutHousemastersMutation,
    OutHousemastersMutationArgs,
    OutHousemastersProps<TChildProps>
  >(OutHousemastersDocument, operationOptions);
}
export const UpdateHousemasterDocument = gql`
  mutation UpdateHousemaster($UpdateHousemaster: UpdateHousemaster!) {
    updateHousemaster(UpdateHousemaster: $UpdateHousemaster) {
      id
    }
  }
`;
export interface UpdateHousemasterMutation {
  updateHousemaster: Mutation["updateHousemaster"];
}
export class UpdateHousemasterComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateHousemasterMutation,
      UpdateHousemasterMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateHousemasterMutation,
        UpdateHousemasterMutationArgs
      >
        mutation={UpdateHousemasterDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateHousemasterProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateHousemasterMutation,
    UpdateHousemasterMutationArgs
  >
> &
  TChildProps;
export type UpdateHousemasterMutationFn = ReactApollo.MutationFn<
  UpdateHousemasterMutation,
  UpdateHousemasterMutationArgs
>;
export function UpdateHousemasterHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateHousemasterMutation,
        UpdateHousemasterMutationArgs,
        UpdateHousemasterProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateHousemasterMutation,
    UpdateHousemasterMutationArgs,
    UpdateHousemasterProps<TChildProps>
  >(UpdateHousemasterDocument, operationOptions);
}
export const CountJobDocument = gql`
  query CountJob {
    countJob {
      count
    }
  }
`;
export interface CountJobQuery {
  countJob: Query["countJob"];
}
export class CountJobComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CountJobQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<CountJobQuery, any>
        query={CountJobDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CountJobProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CountJobQuery, any>
> &
  TChildProps;
export function CountJobHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CountJobQuery,
        any,
        CountJobProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CountJobQuery,
    any,
    CountJobProps<TChildProps>
  >(CountJobDocument, operationOptions);
}
export const CreateJobDocument = gql`
  mutation CreateJob($CreateJob: CreateJob!) {
    createJob(CreateJob: $CreateJob) {
      id
    }
  }
`;
export interface CreateJobMutation {
  createJob: Mutation["createJob"];
}
export class CreateJobComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateJobMutation, CreateJobMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateJobMutation, CreateJobMutationArgs>
        mutation={CreateJobDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateJobProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateJobMutation, CreateJobMutationArgs>
> &
  TChildProps;
export type CreateJobMutationFn = ReactApollo.MutationFn<
  CreateJobMutation,
  CreateJobMutationArgs
>;
export function CreateJobHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateJobMutation,
        CreateJobMutationArgs,
        CreateJobProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateJobMutation,
    CreateJobMutationArgs,
    CreateJobProps<TChildProps>
  >(CreateJobDocument, operationOptions);
}
export const DeleteJobDocument = gql`
  mutation DeleteJob($id: String!) {
    deleteJob(id: $id) {
      id
    }
  }
`;
export interface DeleteJobMutation {
  deleteJob: Mutation["deleteJob"];
}
export class DeleteJobComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteJobMutation, DeleteJobMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteJobMutation, DeleteJobMutationArgs>
        mutation={DeleteJobDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteJobProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteJobMutation, DeleteJobMutationArgs>
> &
  TChildProps;
export type DeleteJobMutationFn = ReactApollo.MutationFn<
  DeleteJobMutation,
  DeleteJobMutationArgs
>;
export function DeleteJobHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteJobMutation,
        DeleteJobMutationArgs,
        DeleteJobProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteJobMutation,
    DeleteJobMutationArgs,
    DeleteJobProps<TChildProps>
  >(DeleteJobDocument, operationOptions);
}
export const JobsDocument = gql`
  query Jobs($search: String, $skip: Int = 0, $take: Int = 25) {
    jobs(take: $take, search: $search, skip: $skip) {
      count
      list {
        id
        name
        created_date
        updated_date
      }
    }
  }
`;
export interface JobsQuery {
  jobs: Query["jobs"];
}
export class JobsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<JobsQuery, JobsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<JobsQuery, JobsQueryArgs>
        query={JobsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type JobsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<JobsQuery, JobsQueryArgs>
> &
  TChildProps;
export function JobsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        JobsQuery,
        JobsQueryArgs,
        JobsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    JobsQuery,
    JobsQueryArgs,
    JobsProps<TChildProps>
  >(JobsDocument, operationOptions);
}
export const UpdateJobDocument = gql`
  mutation UpdateJob($UpdateJob: UpdateJob!) {
    updateJob(UpdateJob: $UpdateJob) {
      id
    }
  }
`;
export interface UpdateJobMutation {
  updateJob: Mutation["updateJob"];
}
export class UpdateJobComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateJobMutation, UpdateJobMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateJobMutation, UpdateJobMutationArgs>
        mutation={UpdateJobDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateJobProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateJobMutation, UpdateJobMutationArgs>
> &
  TChildProps;
export type UpdateJobMutationFn = ReactApollo.MutationFn<
  UpdateJobMutation,
  UpdateJobMutationArgs
>;
export function UpdateJobHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateJobMutation,
        UpdateJobMutationArgs,
        UpdateJobProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateJobMutation,
    UpdateJobMutationArgs,
    UpdateJobProps<TChildProps>
  >(UpdateJobDocument, operationOptions);
}
export const CreateLateReturnRecordNoteDocument = gql`
  mutation CreateLateReturnRecordNote(
    $CreateLateReturnRecordNote: CreateLateReturnRecordNote!
  ) {
    createLateReturnRecordNote(
      CreateLateReturnRecordNote: $CreateLateReturnRecordNote
    ) {
      id
      note
    }
  }
`;
export interface CreateLateReturnRecordNoteMutation {
  createLateReturnRecordNote: Mutation["createLateReturnRecordNote"];
}
export class CreateLateReturnRecordNoteComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateLateReturnRecordNoteMutation,
      CreateLateReturnRecordNoteMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateLateReturnRecordNoteMutation,
        CreateLateReturnRecordNoteMutationArgs
      >
        mutation={CreateLateReturnRecordNoteDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateLateReturnRecordNoteProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateLateReturnRecordNoteMutation,
    CreateLateReturnRecordNoteMutationArgs
  >
> &
  TChildProps;
export type CreateLateReturnRecordNoteMutationFn = ReactApollo.MutationFn<
  CreateLateReturnRecordNoteMutation,
  CreateLateReturnRecordNoteMutationArgs
>;
export function CreateLateReturnRecordNoteHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateLateReturnRecordNoteMutation,
        CreateLateReturnRecordNoteMutationArgs,
        CreateLateReturnRecordNoteProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateLateReturnRecordNoteMutation,
    CreateLateReturnRecordNoteMutationArgs,
    CreateLateReturnRecordNoteProps<TChildProps>
  >(CreateLateReturnRecordNoteDocument, operationOptions);
}
export const LateReturnRecordsDocument = gql`
  query LateReturnRecords(
    $search: String
    $skip: Int = 0
    $take: Int = 25
    $type: String
    $job_number: String
    $start_date: String
    $end_date: String
    $start_time: String
    $end_time: String
    $dorm_number: [Float!]
    $direction: [String!]
    $department: [String!]
    $major: [String!]
    $grade: [Float!]
  ) {
    lateReturnRecords(
      take: $take
      search: $search
      skip: $skip
      type: $type
      job_number: $job_number
      start_date: $start_date
      end_date: $end_date
      start_time: $start_time
      end_time: $end_time
      dorm_number: $dorm_number
      department: $department
      major: $major
      grade: $grade
      direction: $direction
    ) {
      count
      list {
        id
        job_number
        image_uri
        title
        description
        name
        true_name
        description
        dorm_number
        direction
        type
        created_date
        department
        major
        grade
        note
      }
    }
  }
`;
export interface LateReturnRecordsQuery {
  lateReturnRecords: Query["lateReturnRecords"];
}
export class LateReturnRecordsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<LateReturnRecordsQuery, LateReturnRecordsQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<LateReturnRecordsQuery, LateReturnRecordsQueryArgs>
        query={LateReturnRecordsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type LateReturnRecordsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<LateReturnRecordsQuery, LateReturnRecordsQueryArgs>
> &
  TChildProps;
export function LateReturnRecordsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        LateReturnRecordsQuery,
        LateReturnRecordsQueryArgs,
        LateReturnRecordsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LateReturnRecordsQuery,
    LateReturnRecordsQueryArgs,
    LateReturnRecordsProps<TChildProps>
  >(LateReturnRecordsDocument, operationOptions);
}
export const OutLateReturnRecordsDocument = gql`
  mutation OutLateReturnRecords(
    $search: String
    $columns: [String!]
    $type: String
    $job_number: String
    $start_date: String
    $end_date: String
    $start_time: String
    $end_time: String
    $dorm_number: [Float!]
    $direction: [String!]
    $department: [String!]
    $major: [String!]
    $grade: [Float!]
  ) {
    outLateReturnRecords(
      search: $search
      columns: $columns
      type: $type
      job_number: $job_number
      start_date: $start_date
      end_date: $end_date
      start_time: $start_time
      end_time: $end_time
      dorm_number: $dorm_number
      department: $department
      major: $major
      grade: $grade
      direction: $direction
    ) {
      uri
      token
    }
  }
`;
export interface OutLateReturnRecordsMutation {
  outLateReturnRecords: Mutation["outLateReturnRecords"];
}
export class OutLateReturnRecordsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      OutLateReturnRecordsMutation,
      OutLateReturnRecordsMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        OutLateReturnRecordsMutation,
        OutLateReturnRecordsMutationArgs
      >
        mutation={OutLateReturnRecordsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutLateReturnRecordsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    OutLateReturnRecordsMutation,
    OutLateReturnRecordsMutationArgs
  >
> &
  TChildProps;
export type OutLateReturnRecordsMutationFn = ReactApollo.MutationFn<
  OutLateReturnRecordsMutation,
  OutLateReturnRecordsMutationArgs
>;
export function OutLateReturnRecordsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutLateReturnRecordsMutation,
        OutLateReturnRecordsMutationArgs,
        OutLateReturnRecordsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutLateReturnRecordsMutation,
    OutLateReturnRecordsMutationArgs,
    OutLateReturnRecordsProps<TChildProps>
  >(OutLateReturnRecordsDocument, operationOptions);
}
export const CreateLeaveDocument = gql`
  mutation CreateLeave($CreateLeave: CreateLeave!) {
    createLeave(CreateLeave: $CreateLeave) {
      id
    }
  }
`;
export interface CreateLeaveMutation {
  createLeave: Mutation["createLeave"];
}
export class CreateLeaveComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateLeaveMutation, CreateLeaveMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateLeaveMutation, CreateLeaveMutationArgs>
        mutation={CreateLeaveDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateLeaveProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateLeaveMutation, CreateLeaveMutationArgs>
> &
  TChildProps;
export type CreateLeaveMutationFn = ReactApollo.MutationFn<
  CreateLeaveMutation,
  CreateLeaveMutationArgs
>;
export function CreateLeaveHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateLeaveMutation,
        CreateLeaveMutationArgs,
        CreateLeaveProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateLeaveMutation,
    CreateLeaveMutationArgs,
    CreateLeaveProps<TChildProps>
  >(CreateLeaveDocument, operationOptions);
}
export const DeleteLeaveDocument = gql`
  mutation DeleteLeave($id: String!) {
    deleteLeave(id: $id) {
      id
    }
  }
`;
export interface DeleteLeaveMutation {
  deleteLeave: Mutation["deleteLeave"];
}
export class DeleteLeaveComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteLeaveMutation, DeleteLeaveMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteLeaveMutation, DeleteLeaveMutationArgs>
        mutation={DeleteLeaveDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteLeaveProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteLeaveMutation, DeleteLeaveMutationArgs>
> &
  TChildProps;
export type DeleteLeaveMutationFn = ReactApollo.MutationFn<
  DeleteLeaveMutation,
  DeleteLeaveMutationArgs
>;
export function DeleteLeaveHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteLeaveMutation,
        DeleteLeaveMutationArgs,
        DeleteLeaveProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteLeaveMutation,
    DeleteLeaveMutationArgs,
    DeleteLeaveProps<TChildProps>
  >(DeleteLeaveDocument, operationOptions);
}
export const LeavesDocument = gql`
  query Leaves($take: Int, $search: String, $skip: Int) {
    leaves(take: $take, skip: $skip, search: $search) {
      count
      list {
        id
        start_date
        end_date
        true_name
        department
        father_department
      }
    }
  }
`;
export interface LeavesQuery {
  leaves: Query["leaves"];
}
export class LeavesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<LeavesQuery, LeavesQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<LeavesQuery, LeavesQueryArgs>
        query={LeavesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type LeavesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<LeavesQuery, LeavesQueryArgs>
> &
  TChildProps;
export function LeavesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        LeavesQuery,
        LeavesQueryArgs,
        LeavesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LeavesQuery,
    LeavesQueryArgs,
    LeavesProps<TChildProps>
  >(LeavesDocument, operationOptions);
}
export const LogsDocument = gql`
  query Logs($search: String, $skip: Int = 0, $take: Int = 25) {
    logs(take: $take, search: $search, skip: $skip) {
      count
      list {
        id
        ip
      }
    }
  }
`;
export interface LogsQuery {
  logs: Query["logs"];
}
export class LogsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<LogsQuery, LogsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<LogsQuery, LogsQueryArgs>
        query={LogsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type LogsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<LogsQuery, LogsQueryArgs>
> &
  TChildProps;
export function LogsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        LogsQuery,
        LogsQueryArgs,
        LogsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LogsQuery,
    LogsQueryArgs,
    LogsProps<TChildProps>
  >(LogsDocument, operationOptions);
}
export const LoginByAdminDocument = gql`
  mutation LoginByAdmin($LoginByAdmin: LoginByAdmin!) {
    loginByAdmin(LoginByAdmin: $LoginByAdmin) {
      user_id
      expires_in
      access_token
      roles
      permissions
    }
  }
`;
export interface LoginByAdminMutation {
  loginByAdmin: Mutation["loginByAdmin"];
}
export class LoginByAdminComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<LoginByAdminMutation, LoginByAdminMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<LoginByAdminMutation, LoginByAdminMutationArgs>
        mutation={LoginByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type LoginByAdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<LoginByAdminMutation, LoginByAdminMutationArgs>
> &
  TChildProps;
export type LoginByAdminMutationFn = ReactApollo.MutationFn<
  LoginByAdminMutation,
  LoginByAdminMutationArgs
>;
export function LoginByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        LoginByAdminMutation,
        LoginByAdminMutationArgs,
        LoginByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LoginByAdminMutation,
    LoginByAdminMutationArgs,
    LoginByAdminProps<TChildProps>
  >(LoginByAdminDocument, operationOptions);
}
export const CreateNoReturnRecordNoteDocument = gql`
  mutation CreateNoReturnRecordNote(
    $CreateNoReturnRecordNote: CreateNoReturnRecordNote!
  ) {
    createNoReturnRecordNote(
      CreateNoReturnRecordNote: $CreateNoReturnRecordNote
    ) {
      id
      note
    }
  }
`;
export interface CreateNoReturnRecordNoteMutation {
  createNoReturnRecordNote: Mutation["createNoReturnRecordNote"];
}
export class CreateNoReturnRecordNoteComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateNoReturnRecordNoteMutation,
      CreateNoReturnRecordNoteMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateNoReturnRecordNoteMutation,
        CreateNoReturnRecordNoteMutationArgs
      >
        mutation={CreateNoReturnRecordNoteDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateNoReturnRecordNoteProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateNoReturnRecordNoteMutation,
    CreateNoReturnRecordNoteMutationArgs
  >
> &
  TChildProps;
export type CreateNoReturnRecordNoteMutationFn = ReactApollo.MutationFn<
  CreateNoReturnRecordNoteMutation,
  CreateNoReturnRecordNoteMutationArgs
>;
export function CreateNoReturnRecordNoteHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateNoReturnRecordNoteMutation,
        CreateNoReturnRecordNoteMutationArgs,
        CreateNoReturnRecordNoteProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateNoReturnRecordNoteMutation,
    CreateNoReturnRecordNoteMutationArgs,
    CreateNoReturnRecordNoteProps<TChildProps>
  >(CreateNoReturnRecordNoteDocument, operationOptions);
}
export const NoReturnRecordsDocument = gql`
  query NoReturnRecords(
    $search: String
    $skip: Int = 0
    $take: Int = 25
    $role: String
    $status: String
    $true_name: [String!]
    $number: [String!]
    $start_date: String
    $end_date: String
    $dorm_number: [Float!]
    $department: [String!]
    $father_department: [String!]
    $major: [String!]
    $grade: [Float!]
  ) {
    noReturnRecords(
      take: $take
      search: $search
      skip: $skip
      role: $role
      status: $status
      true_name: $true_name
      number: $number
      start_date: $start_date
      end_date: $end_date
      dorm_number: $dorm_number
      department: $department
      father_department: $father_department
      major: $major
      grade: $grade
    ) {
      count
      list {
        id
        date
        status
        people {
          id
          photo
          bed_number
          room_number
        }
        true_name
        dorm_number
        department
        father_department
        note
      }
    }
  }
`;
export interface NoReturnRecordsQuery {
  noReturnRecords: Query["noReturnRecords"];
}
export class NoReturnRecordsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<NoReturnRecordsQuery, NoReturnRecordsQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<NoReturnRecordsQuery, NoReturnRecordsQueryArgs>
        query={NoReturnRecordsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type NoReturnRecordsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<NoReturnRecordsQuery, NoReturnRecordsQueryArgs>
> &
  TChildProps;
export function NoReturnRecordsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        NoReturnRecordsQuery,
        NoReturnRecordsQueryArgs,
        NoReturnRecordsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    NoReturnRecordsQuery,
    NoReturnRecordsQueryArgs,
    NoReturnRecordsProps<TChildProps>
  >(NoReturnRecordsDocument, operationOptions);
}
export const OutNoReturnRecordsDocument = gql`
  mutation OutNoReturnRecords(
    $search: String
    $columns: [String!]
    $role: String
    $status: String
    $true_name: [String!]
    $number: [String!]
    $start_date: String
    $end_date: String
    $dorm_number: [Float!]
    $department: [String!]
    $father_department: [String!]
    $major: [String!]
    $grade: [Float!]
  ) {
    outNoReturnRecords(
      search: $search
      columns: $columns
      role: $role
      status: $status
      true_name: $true_name
      number: $number
      start_date: $start_date
      end_date: $end_date
      dorm_number: $dorm_number
      father_department: $father_department
      department: $department
      major: $major
      grade: $grade
    ) {
      uri
      token
    }
  }
`;
export interface OutNoReturnRecordsMutation {
  outNoReturnRecords: Mutation["outNoReturnRecords"];
}
export class OutNoReturnRecordsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      OutNoReturnRecordsMutation,
      OutNoReturnRecordsMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        OutNoReturnRecordsMutation,
        OutNoReturnRecordsMutationArgs
      >
        mutation={OutNoReturnRecordsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutNoReturnRecordsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    OutNoReturnRecordsMutation,
    OutNoReturnRecordsMutationArgs
  >
> &
  TChildProps;
export type OutNoReturnRecordsMutationFn = ReactApollo.MutationFn<
  OutNoReturnRecordsMutation,
  OutNoReturnRecordsMutationArgs
>;
export function OutNoReturnRecordsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutNoReturnRecordsMutation,
        OutNoReturnRecordsMutationArgs,
        OutNoReturnRecordsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutNoReturnRecordsMutation,
    OutNoReturnRecordsMutationArgs,
    OutNoReturnRecordsProps<TChildProps>
  >(OutNoReturnRecordsDocument, operationOptions);
}
export const BatchDeletePeopleDocument = gql`
  mutation BatchDeletePeople($ids: [String!]!) {
    batchDeletePeople(ids: $ids) {
      success
    }
  }
`;
export interface BatchDeletePeopleMutation {
  batchDeletePeople: Mutation["batchDeletePeople"];
}
export class BatchDeletePeopleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BatchDeletePeopleMutation,
      BatchDeletePeopleMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BatchDeletePeopleMutation,
        BatchDeletePeopleMutationArgs
      >
        mutation={BatchDeletePeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BatchDeletePeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    BatchDeletePeopleMutation,
    BatchDeletePeopleMutationArgs
  >
> &
  TChildProps;
export type BatchDeletePeopleMutationFn = ReactApollo.MutationFn<
  BatchDeletePeopleMutation,
  BatchDeletePeopleMutationArgs
>;
export function BatchDeletePeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BatchDeletePeopleMutation,
        BatchDeletePeopleMutationArgs,
        BatchDeletePeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BatchDeletePeopleMutation,
    BatchDeletePeopleMutationArgs,
    BatchDeletePeopleProps<TChildProps>
  >(BatchDeletePeopleDocument, operationOptions);
}
export const CountPeopleDocument = gql`
  query CountPeople {
    countPeople {
      count
    }
    countBoyPeople: countPeople(sex: "男") {
      count
    }
    countGirlPeople: countPeople(sex: "女") {
      count
    }
    countInPeople: countPeople(in_status: "在寝") {
      count
    }
    countOutPeople: countPeople(in_status: "外出") {
      count
    }
    noPhotoPeople: countPeople(photo: "未上传") {
      count
    }
  }
`;
export interface CountPeopleQuery {
  countPeople: Query["countPeople"];
}
export class CountPeopleComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CountPeopleQuery, CountPeopleQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<CountPeopleQuery, CountPeopleQueryArgs>
        query={CountPeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CountPeopleProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CountPeopleQuery, CountPeopleQueryArgs>
> &
  TChildProps;
export function CountPeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CountPeopleQuery,
        CountPeopleQueryArgs,
        CountPeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CountPeopleQuery,
    CountPeopleQueryArgs,
    CountPeopleProps<TChildProps>
  >(CountPeopleDocument, operationOptions);
}
export const CreatePeopleDocument = gql`
  mutation CreatePeople($CreatePeople: CreatePeople!) {
    createPeople(CreatePeople: $CreatePeople) {
      id
    }
  }
`;
export interface CreatePeopleMutation {
  createPeople: Mutation["createPeople"];
}
export class CreatePeopleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreatePeopleMutation, CreatePeopleMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreatePeopleMutation, CreatePeopleMutationArgs>
        mutation={CreatePeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreatePeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreatePeopleMutation, CreatePeopleMutationArgs>
> &
  TChildProps;
export type CreatePeopleMutationFn = ReactApollo.MutationFn<
  CreatePeopleMutation,
  CreatePeopleMutationArgs
>;
export function CreatePeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreatePeopleMutation,
        CreatePeopleMutationArgs,
        CreatePeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreatePeopleMutation,
    CreatePeopleMutationArgs,
    CreatePeopleProps<TChildProps>
  >(CreatePeopleDocument, operationOptions);
}
export const DeletePeopleDocument = gql`
  mutation DeletePeople($id: String!) {
    deletePeople(id: $id) {
      id
    }
  }
`;
export interface DeletePeopleMutation {
  deletePeople: Mutation["deletePeople"];
}
export class DeletePeopleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeletePeopleMutation, DeletePeopleMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeletePeopleMutation, DeletePeopleMutationArgs>
        mutation={DeletePeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeletePeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeletePeopleMutation, DeletePeopleMutationArgs>
> &
  TChildProps;
export type DeletePeopleMutationFn = ReactApollo.MutationFn<
  DeletePeopleMutation,
  DeletePeopleMutationArgs
>;
export function DeletePeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeletePeopleMutation,
        DeletePeopleMutationArgs,
        DeletePeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeletePeopleMutation,
    DeletePeopleMutationArgs,
    DeletePeopleProps<TChildProps>
  >(DeletePeopleDocument, operationOptions);
}
export const GetPeopleByIdDocument = gql`
  query GetPeopleById($id: String!) {
    getPeopleById(id: $id) {
      id
      true_name
      sex
      mobile
      id_card
      room_card_number
      department
      department_detail
      start_date
      dorm_number
      room_number
      bed_number
      photo
      subject_id
      type
      to_department
      in_status
      status
    }
  }
`;
export interface GetPeopleByIdQuery {
  getPeopleById: Query["getPeopleById"];
}
export class GetPeopleByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetPeopleByIdQuery, GetPeopleByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetPeopleByIdQuery, GetPeopleByIdQueryArgs>
        query={GetPeopleByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetPeopleByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetPeopleByIdQuery, GetPeopleByIdQueryArgs>
> &
  TChildProps;
export function GetPeopleByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetPeopleByIdQuery,
        GetPeopleByIdQueryArgs,
        GetPeopleByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetPeopleByIdQuery,
    GetPeopleByIdQueryArgs,
    GetPeopleByIdProps<TChildProps>
  >(GetPeopleByIdDocument, operationOptions);
}
export const GetPeopleByNumberDocument = gql`
  query GetPeopleByNumber($number: String!) {
    getPeopleByNumber(number: $number) {
      id
      true_name
      sex
      mobile
      id_card
      room_card_number
      department
      department_detail
      start_date
      dorm_number
      room_number
      bed_number
      photo
    }
  }
`;
export interface GetPeopleByNumberQuery {
  getPeopleByNumber: Query["getPeopleByNumber"];
}
export class GetPeopleByNumberComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetPeopleByNumberQuery, GetPeopleByNumberQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetPeopleByNumberQuery, GetPeopleByNumberQueryArgs>
        query={GetPeopleByNumberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetPeopleByNumberProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetPeopleByNumberQuery, GetPeopleByNumberQueryArgs>
> &
  TChildProps;
export function GetPeopleByNumberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetPeopleByNumberQuery,
        GetPeopleByNumberQueryArgs,
        GetPeopleByNumberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetPeopleByNumberQuery,
    GetPeopleByNumberQueryArgs,
    GetPeopleByNumberProps<TChildProps>
  >(GetPeopleByNumberDocument, operationOptions);
}
export const OutPeopleDocument = gql`
  mutation OutPeople(
    $search: String
    $columns: [String!]
    $photo: [String!]
    $avatar: [String!]
    $grade: [Float!]
    $department: [String!]
    $dorm_number: [Float!]
    $dorm_numbers: [Float!]
    $no_out_count: [Float!]
    $father_department: [String!]
    $no_return_count: [Float!]
    $more_no_return_count: Float
    $status: [String!]
    $in_status: [String!]
    $type: [String!]
    $floor_numbers: [Float!]
    $to_department: [String!]
  ) {
    outPeople(
      search: $search
      columns: $columns
      photo: $photo
      avatar: $avatar
      grade: $grade
      department: $department
      to_department: $to_department
      no_out_count: $no_out_count
      no_return_count: $no_return_count
      more_no_return_count: $more_no_return_count
      status: $status
      in_status: $in_status
      type: $type
      floor_numbers: $floor_numbers
      father_department: $father_department
      dorm_number: $dorm_number
      dorm_numbers: $dorm_numbers
    ) {
      uri
      token
    }
  }
`;
export interface OutPeopleMutation {
  outPeople: Mutation["outPeople"];
}
export class OutPeopleComponent extends React.Component<
  Partial<ReactApollo.MutationProps<OutPeopleMutation, OutPeopleMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<OutPeopleMutation, OutPeopleMutationArgs>
        mutation={OutPeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutPeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<OutPeopleMutation, OutPeopleMutationArgs>
> &
  TChildProps;
export type OutPeopleMutationFn = ReactApollo.MutationFn<
  OutPeopleMutation,
  OutPeopleMutationArgs
>;
export function OutPeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutPeopleMutation,
        OutPeopleMutationArgs,
        OutPeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutPeopleMutation,
    OutPeopleMutationArgs,
    OutPeopleProps<TChildProps>
  >(OutPeopleDocument, operationOptions);
}
export const OutPeopleOnlineDocument = gql`
  mutation OutPeopleOnline(
    $columns: [String!]
    $search: String
    $date: String
    $time: String
    $father_department: [String!]
    $department: [String!]
    $to_department: [String!]
    $in_status: [String!]
  ) {
    outPeopleOnline(
      columns: $columns
      search: $search
      date: $date
      time: $time
      father_department: $father_department
      department: $department
      to_department: $to_department
      in_status: $in_status
    ) {
      uri
      token
    }
  }
`;
export interface OutPeopleOnlineMutation {
  outPeopleOnline: Mutation["outPeopleOnline"];
}
export class OutPeopleOnlineComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      OutPeopleOnlineMutation,
      OutPeopleOnlineMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        OutPeopleOnlineMutation,
        OutPeopleOnlineMutationArgs
      >
        mutation={OutPeopleOnlineDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutPeopleOnlineProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<OutPeopleOnlineMutation, OutPeopleOnlineMutationArgs>
> &
  TChildProps;
export type OutPeopleOnlineMutationFn = ReactApollo.MutationFn<
  OutPeopleOnlineMutation,
  OutPeopleOnlineMutationArgs
>;
export function OutPeopleOnlineHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutPeopleOnlineMutation,
        OutPeopleOnlineMutationArgs,
        OutPeopleOnlineProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutPeopleOnlineMutation,
    OutPeopleOnlineMutationArgs,
    OutPeopleOnlineProps<TChildProps>
  >(OutPeopleOnlineDocument, operationOptions);
}
export const PeopleDocument = gql`
  query People(
    $order: PeopleFilterOrder
    $search: String
    $skip: Int = 0
    $take: Int = 25
    $photo: [String!]
    $dorm_number: [Float!]
    $dorm_numbers: [Float!]
    $avatar: [String!]
    $father_department: [String!]
    $no_out_count: [Float!]
    $more_no_return_count: Float
    $no_return_count: [Float!]
    $department: [String!]
    $to_department: [String!]
    $status: [String!]
    $in_status: [String!]
    $type: [String!]
    $floor_numbers: [Float!]
  ) {
    people(
      order: $order
      take: $take
      search: $search
      skip: $skip
      photo: $photo
      dorm_number: $dorm_number
      dorm_numbers: $dorm_numbers
      avatar: $avatar
      father_department: $father_department
      department: $department
      to_department: $to_department
      no_out_count: $no_out_count
      no_return_count: $no_return_count
      more_no_return_count: $more_no_return_count
      status: $status
      in_status: $in_status
      type: $type
      floor_numbers: $floor_numbers
    ) {
      count
      list {
        id
        true_name
        sex
        mobile
        id_card
        room_card_number
        department
        father_department
        department_detail
        start_date
        dorm_number
        room_number
        bed_number
        photo
        status
        in_status
        to_department
        floor_number
        type
        admin {
          id
          username
        }
        no_return_start_date
        no_return_end_date
        no_return_count
      }
    }
  }
`;
export interface PeopleQuery {
  people: Query["people"];
}
export class PeopleComponent extends React.Component<
  Partial<ReactApollo.QueryProps<PeopleQuery, PeopleQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<PeopleQuery, PeopleQueryArgs>
        query={PeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PeopleProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<PeopleQuery, PeopleQueryArgs>
> &
  TChildProps;
export function PeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PeopleQuery,
        PeopleQueryArgs,
        PeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PeopleQuery,
    PeopleQueryArgs,
    PeopleProps<TChildProps>
  >(PeopleDocument, operationOptions);
}
export const PeopleOnlineDocument = gql`
  query PeopleOnline(
    $order: PeopleFilterOrder
    $search: String
    $date: String
    $time: String
    $skip: Int = 0
    $take: Int = 25
    $father_department: [String!]
    $department: [String!]
    $to_department: [String!]
    $in_status: [String!]
  ) {
    peopleOnline(
      order: $order
      take: $take
      search: $search
      date: $date
      time: $time
      skip: $skip
      father_department: $father_department
      department: $department
      to_department: $to_department
      in_status: $in_status
    ) {
      count
      list {
        id
        true_name
        sex
        mobile
        id_card
        room_card_number
        department
        father_department
        department_detail
        start_date
        dorm_number
        room_number
        bed_number
        photo
        status
        in_status
        in_time_status(date: $date, time: $time)
        to_department
        floor_number
        type
        admin {
          id
          username
        }
        no_return_start_date
        no_return_end_date
        no_return_count
      }
    }
  }
`;
export interface PeopleOnlineQuery {
  peopleOnline: Query["peopleOnline"];
}
export class PeopleOnlineComponent extends React.Component<
  Partial<ReactApollo.QueryProps<PeopleOnlineQuery, PeopleOnlineQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<PeopleOnlineQuery, PeopleOnlineQueryArgs>
        query={PeopleOnlineDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PeopleOnlineProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<PeopleOnlineQuery, PeopleOnlineQueryArgs>
> &
  TChildProps;
export function PeopleOnlineHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PeopleOnlineQuery,
        PeopleOnlineQueryArgs,
        PeopleOnlineProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PeopleOnlineQuery,
    PeopleOnlineQueryArgs,
    PeopleOnlineProps<TChildProps>
  >(PeopleOnlineDocument, operationOptions);
}
export const ReopenPeopleDocument = gql`
  mutation ReopenPeople($id: String!) {
    reopenPeople(id: $id) {
      id
    }
  }
`;
export interface ReopenPeopleMutation {
  reopenPeople: Mutation["reopenPeople"];
}
export class ReopenPeopleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<ReopenPeopleMutation, ReopenPeopleMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<ReopenPeopleMutation, ReopenPeopleMutationArgs>
        mutation={ReopenPeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ReopenPeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<ReopenPeopleMutation, ReopenPeopleMutationArgs>
> &
  TChildProps;
export type ReopenPeopleMutationFn = ReactApollo.MutationFn<
  ReopenPeopleMutation,
  ReopenPeopleMutationArgs
>;
export function ReopenPeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ReopenPeopleMutation,
        ReopenPeopleMutationArgs,
        ReopenPeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ReopenPeopleMutation,
    ReopenPeopleMutationArgs,
    ReopenPeopleProps<TChildProps>
  >(ReopenPeopleDocument, operationOptions);
}
export const StopPeopleDocument = gql`
  mutation StopPeople($id: String!) {
    stopPeople(id: $id) {
      id
    }
  }
`;
export interface StopPeopleMutation {
  stopPeople: Mutation["stopPeople"];
}
export class StopPeopleComponent extends React.Component<
  Partial<ReactApollo.MutationProps<StopPeopleMutation, StopPeopleMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<StopPeopleMutation, StopPeopleMutationArgs>
        mutation={StopPeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StopPeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<StopPeopleMutation, StopPeopleMutationArgs>
> &
  TChildProps;
export type StopPeopleMutationFn = ReactApollo.MutationFn<
  StopPeopleMutation,
  StopPeopleMutationArgs
>;
export function StopPeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StopPeopleMutation,
        StopPeopleMutationArgs,
        StopPeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StopPeopleMutation,
    StopPeopleMutationArgs,
    StopPeopleProps<TChildProps>
  >(StopPeopleDocument, operationOptions);
}
export const UpdatePeopleDocument = gql`
  mutation UpdatePeople($UpdatePeople: UpdatePeople!) {
    updatePeople(UpdatePeople: $UpdatePeople) {
      id
    }
  }
`;
export interface UpdatePeopleMutation {
  updatePeople: Mutation["updatePeople"];
}
export class UpdatePeopleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdatePeopleMutation, UpdatePeopleMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdatePeopleMutation, UpdatePeopleMutationArgs>
        mutation={UpdatePeopleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdatePeopleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdatePeopleMutation, UpdatePeopleMutationArgs>
> &
  TChildProps;
export type UpdatePeopleMutationFn = ReactApollo.MutationFn<
  UpdatePeopleMutation,
  UpdatePeopleMutationArgs
>;
export function UpdatePeopleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdatePeopleMutation,
        UpdatePeopleMutationArgs,
        UpdatePeopleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdatePeopleMutation,
    UpdatePeopleMutationArgs,
    UpdatePeopleProps<TChildProps>
  >(UpdatePeopleDocument, operationOptions);
}
export const OutRecognitionResultsDocument = gql`
  mutation OutRecognitionResults(
    $search: String
    $columns: [String!]
    $role: String
    $type: String
    $start_date: String
    $end_date: String
    $start_time: String
    $end_time: String
    $dorm_number: [Float!]
    $department: [String!]
    $father_department: [String!]
    $subject_id: Float
    $is_group: Boolean
    $user_id: String
  ) {
    outRecognitionResults(
      search: $search
      columns: $columns
      role: $role
      type: $type
      start_date: $start_date
      end_date: $end_date
      start_time: $start_time
      end_time: $end_time
      dorm_number: $dorm_number
      department: $department
      father_department: $father_department
      subject_id: $subject_id
      is_group: $is_group
      user_id: $user_id
    ) {
      uri
      token
    }
  }
`;
export interface OutRecognitionResultsMutation {
  outRecognitionResults: Mutation["outRecognitionResults"];
}
export class OutRecognitionResultsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      OutRecognitionResultsMutation,
      OutRecognitionResultsMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        OutRecognitionResultsMutation,
        OutRecognitionResultsMutationArgs
      >
        mutation={OutRecognitionResultsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutRecognitionResultsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    OutRecognitionResultsMutation,
    OutRecognitionResultsMutationArgs
  >
> &
  TChildProps;
export type OutRecognitionResultsMutationFn = ReactApollo.MutationFn<
  OutRecognitionResultsMutation,
  OutRecognitionResultsMutationArgs
>;
export function OutRecognitionResultsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutRecognitionResultsMutation,
        OutRecognitionResultsMutationArgs,
        OutRecognitionResultsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutRecognitionResultsMutation,
    OutRecognitionResultsMutationArgs,
    OutRecognitionResultsProps<TChildProps>
  >(OutRecognitionResultsDocument, operationOptions);
}
export const RecognitionResultsDocument = gql`
  query RecognitionResults(
    $search: String
    $skip: Int = 0
    $take: Int = 25
    $type: String
    $job_number: String
    $dorm_number: [Float!]
    $direction: [String!]
    $department: [String!]
    $father_department: [String!]
    $start_date: String
    $end_date: String
    $start_time: String
    $end_time: String
    $user_id: String
    $subject_id: Float
    $later: Boolean
    $is_group: Boolean
  ) {
    recognitionResults(
      take: $take
      search: $search
      skip: $skip
      type: $type
      job_number: $job_number
      dorm_number: $dorm_number
      department: $department
      father_department: $father_department
      direction: $direction
      start_date: $start_date
      end_date: $end_date
      start_time: $start_time
      end_time: $end_time
      subject_id: $subject_id
      user_id: $user_id
      later: $later
      is_group: $is_group
    ) {
      count
      list {
        id
        image_uri
        megvii_image_uri
        title
        description
        true_name
        description
        dorm_number
        direction
        type
        created_date
        department
        father_department
        people {
          id
          true_name
        }
      }
    }
  }
`;
export interface RecognitionResultsQuery {
  recognitionResults: Query["recognitionResults"];
}
export class RecognitionResultsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<RecognitionResultsQuery, RecognitionResultsQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<RecognitionResultsQuery, RecognitionResultsQueryArgs>
        query={RecognitionResultsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RecognitionResultsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RecognitionResultsQuery, RecognitionResultsQueryArgs>
> &
  TChildProps;
export function RecognitionResultsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RecognitionResultsQuery,
        RecognitionResultsQueryArgs,
        RecognitionResultsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RecognitionResultsQuery,
    RecognitionResultsQueryArgs,
    RecognitionResultsProps<TChildProps>
  >(RecognitionResultsDocument, operationOptions);
}
export const CreateRoleDocument = gql`
  mutation CreateRole($CreateRole: CreateRole!) {
    createRole(CreateRole: $CreateRole) {
      id
      name
    }
  }
`;
export interface CreateRoleMutation {
  createRole: Mutation["createRole"];
}
export class CreateRoleComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateRoleMutation, CreateRoleMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateRoleMutation, CreateRoleMutationArgs>
        mutation={CreateRoleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateRoleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateRoleMutation, CreateRoleMutationArgs>
> &
  TChildProps;
export type CreateRoleMutationFn = ReactApollo.MutationFn<
  CreateRoleMutation,
  CreateRoleMutationArgs
>;
export function CreateRoleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateRoleMutation,
        CreateRoleMutationArgs,
        CreateRoleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateRoleMutation,
    CreateRoleMutationArgs,
    CreateRoleProps<TChildProps>
  >(CreateRoleDocument, operationOptions);
}
export const DeleteRoleItemDocument = gql`
  mutation DeleteRoleItem($id: String!) {
    deleteRoleItem(id: $id) {
      id
    }
  }
`;
export interface DeleteRoleItemMutation {
  deleteRoleItem: Mutation["deleteRoleItem"];
}
export class DeleteRoleItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteRoleItemMutation,
      DeleteRoleItemMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteRoleItemMutation, DeleteRoleItemMutationArgs>
        mutation={DeleteRoleItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteRoleItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteRoleItemMutation, DeleteRoleItemMutationArgs>
> &
  TChildProps;
export type DeleteRoleItemMutationFn = ReactApollo.MutationFn<
  DeleteRoleItemMutation,
  DeleteRoleItemMutationArgs
>;
export function DeleteRoleItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteRoleItemMutation,
        DeleteRoleItemMutationArgs,
        DeleteRoleItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteRoleItemMutation,
    DeleteRoleItemMutationArgs,
    DeleteRoleItemProps<TChildProps>
  >(DeleteRoleItemDocument, operationOptions);
}
export const RoleItemsDocument = gql`
  query RoleItems {
    roleItems {
      id
      owner {
        id
        username
        true_name
        mobile
      }
      role {
        id
        name
      }
    }
  }
`;
export interface RoleItemsQuery {
  roleItems: Query["roleItems"];
}
export class RoleItemsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RoleItemsQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<RoleItemsQuery, any>
        query={RoleItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RoleItemsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RoleItemsQuery, any>
> &
  TChildProps;
export function RoleItemsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RoleItemsQuery,
        any,
        RoleItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoleItemsQuery,
    any,
    RoleItemsProps<TChildProps>
  >(RoleItemsDocument, operationOptions);
}
export const RolesDocument = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;
export interface RolesQuery {
  roles: Query["roles"];
}
export class RolesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RolesQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<RolesQuery, any>
        query={RolesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RolesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RolesQuery, any>
> &
  TChildProps;
export function RolesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RolesQuery,
        any,
        RolesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RolesQuery,
    any,
    RolesProps<TChildProps>
  >(RolesDocument, operationOptions);
}
export const RolesAllDocument = gql`
  query RolesAll {
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;
export interface RolesAllQuery {
  roles: Query["roles"];
}
export class RolesAllComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RolesAllQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<RolesAllQuery, any>
        query={RolesAllDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RolesAllProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RolesAllQuery, any>
> &
  TChildProps;
export function RolesAllHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RolesAllQuery,
        any,
        RolesAllProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RolesAllQuery,
    any,
    RolesAllProps<TChildProps>
  >(RolesAllDocument, operationOptions);
}
export const SetRoleDocument = gql`
  mutation SetRole($SetRoleInput: SetRoleInput!) {
    setRole(SetRoleInput: $SetRoleInput) {
      id
      name
    }
  }
`;
export interface SetRoleMutation {
  setRole: Mutation["setRole"];
}
export class SetRoleComponent extends React.Component<
  Partial<ReactApollo.MutationProps<SetRoleMutation, SetRoleMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<SetRoleMutation, SetRoleMutationArgs>
        mutation={SetRoleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SetRoleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SetRoleMutation, SetRoleMutationArgs>
> &
  TChildProps;
export type SetRoleMutationFn = ReactApollo.MutationFn<
  SetRoleMutation,
  SetRoleMutationArgs
>;
export function SetRoleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SetRoleMutation,
        SetRoleMutationArgs,
        SetRoleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SetRoleMutation,
    SetRoleMutationArgs,
    SetRoleProps<TChildProps>
  >(SetRoleDocument, operationOptions);
}
export const UpdateRoleDocument = gql`
  mutation UpdateRole($UpdateRole: UpdateRole!) {
    updateRole(UpdateRole: $UpdateRole) {
      id
      name
    }
  }
`;
export interface UpdateRoleMutation {
  updateRole: Mutation["updateRole"];
}
export class UpdateRoleComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateRoleMutation, UpdateRoleMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateRoleMutation, UpdateRoleMutationArgs>
        mutation={UpdateRoleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateRoleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateRoleMutation, UpdateRoleMutationArgs>
> &
  TChildProps;
export type UpdateRoleMutationFn = ReactApollo.MutationFn<
  UpdateRoleMutation,
  UpdateRoleMutationArgs
>;
export function UpdateRoleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateRoleMutation,
        UpdateRoleMutationArgs,
        UpdateRoleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateRoleMutation,
    UpdateRoleMutationArgs,
    UpdateRoleProps<TChildProps>
  >(UpdateRoleDocument, operationOptions);
}
export const BatchDeleteRoomDocument = gql`
  mutation BatchDeleteRoom($ids: [String!]!) {
    batchDeleteRoom(ids: $ids) {
      success
    }
  }
`;
export interface BatchDeleteRoomMutation {
  batchDeleteRoom: Mutation["batchDeleteRoom"];
}
export class BatchDeleteRoomComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BatchDeleteRoomMutation,
      BatchDeleteRoomMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BatchDeleteRoomMutation,
        BatchDeleteRoomMutationArgs
      >
        mutation={BatchDeleteRoomDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BatchDeleteRoomProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<BatchDeleteRoomMutation, BatchDeleteRoomMutationArgs>
> &
  TChildProps;
export type BatchDeleteRoomMutationFn = ReactApollo.MutationFn<
  BatchDeleteRoomMutation,
  BatchDeleteRoomMutationArgs
>;
export function BatchDeleteRoomHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BatchDeleteRoomMutation,
        BatchDeleteRoomMutationArgs,
        BatchDeleteRoomProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BatchDeleteRoomMutation,
    BatchDeleteRoomMutationArgs,
    BatchDeleteRoomProps<TChildProps>
  >(BatchDeleteRoomDocument, operationOptions);
}
export const CreateRoomDocument = gql`
  mutation CreateRoom($CreateRoom: CreateRoom!) {
    createRoom(CreateRoom: $CreateRoom) {
      id
    }
  }
`;
export interface CreateRoomMutation {
  createRoom: Mutation["createRoom"];
}
export class CreateRoomComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateRoomMutation, CreateRoomMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateRoomMutation, CreateRoomMutationArgs>
        mutation={CreateRoomDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateRoomProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateRoomMutation, CreateRoomMutationArgs>
> &
  TChildProps;
export type CreateRoomMutationFn = ReactApollo.MutationFn<
  CreateRoomMutation,
  CreateRoomMutationArgs
>;
export function CreateRoomHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateRoomMutation,
        CreateRoomMutationArgs,
        CreateRoomProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateRoomMutation,
    CreateRoomMutationArgs,
    CreateRoomProps<TChildProps>
  >(CreateRoomDocument, operationOptions);
}
export const DeleteRoomDocument = gql`
  mutation DeleteRoom($id: String!) {
    deleteRoom(id: $id) {
      id
    }
  }
`;
export interface DeleteRoomMutation {
  deleteRoom: Mutation["deleteRoom"];
}
export class DeleteRoomComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteRoomMutation, DeleteRoomMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteRoomMutation, DeleteRoomMutationArgs>
        mutation={DeleteRoomDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteRoomProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteRoomMutation, DeleteRoomMutationArgs>
> &
  TChildProps;
export type DeleteRoomMutationFn = ReactApollo.MutationFn<
  DeleteRoomMutation,
  DeleteRoomMutationArgs
>;
export function DeleteRoomHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteRoomMutation,
        DeleteRoomMutationArgs,
        DeleteRoomProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteRoomMutation,
    DeleteRoomMutationArgs,
    DeleteRoomProps<TChildProps>
  >(DeleteRoomDocument, operationOptions);
}
export const RoomsDocument = gql`
  query Rooms(
    $dorm_number: Float
    $dorm_numbers: [Float!]
    $floor_number: Float
    $floor_numbers: [Float!]
    $take: Int
    $skip: Int
  ) {
    rooms(
      take: $take
      skip: $skip
      dorm_number: $dorm_number
      dorm_numbers: $dorm_numbers
      floor_number: $floor_number
      floor_numbers: $floor_numbers
    ) {
      count
      list {
        id
        number
        dorm_number
        floor_number
        created_date
        people_count
        people_in_count
        people {
          id
          true_name
          photo
          number
          in_status
          bed_number
        }
      }
    }
  }
`;
export interface RoomsQuery {
  rooms: Query["rooms"];
}
export class RoomsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RoomsQuery, RoomsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<RoomsQuery, RoomsQueryArgs>
        query={RoomsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RoomsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RoomsQuery, RoomsQueryArgs>
> &
  TChildProps;
export function RoomsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RoomsQuery,
        RoomsQueryArgs,
        RoomsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoomsQuery,
    RoomsQueryArgs,
    RoomsProps<TChildProps>
  >(RoomsDocument, operationOptions);
}
export const CreateScreenDocument = gql`
  mutation CreateScreen($CreateScreen: CreateScreen!) {
    createScreen(CreateScreen: $CreateScreen) {
      id
    }
  }
`;
export interface CreateScreenMutation {
  createScreen: Mutation["createScreen"];
}
export class CreateScreenComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateScreenMutation, CreateScreenMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateScreenMutation, CreateScreenMutationArgs>
        mutation={CreateScreenDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateScreenProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateScreenMutation, CreateScreenMutationArgs>
> &
  TChildProps;
export type CreateScreenMutationFn = ReactApollo.MutationFn<
  CreateScreenMutation,
  CreateScreenMutationArgs
>;
export function CreateScreenHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateScreenMutation,
        CreateScreenMutationArgs,
        CreateScreenProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateScreenMutation,
    CreateScreenMutationArgs,
    CreateScreenProps<TChildProps>
  >(CreateScreenDocument, operationOptions);
}
export const DeleteScreenDocument = gql`
  mutation DeleteScreen($id: String!) {
    deleteScreen(id: $id) {
      id
    }
  }
`;
export interface DeleteScreenMutation {
  deleteScreen: Mutation["deleteScreen"];
}
export class DeleteScreenComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteScreenMutation, DeleteScreenMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteScreenMutation, DeleteScreenMutationArgs>
        mutation={DeleteScreenDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteScreenProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteScreenMutation, DeleteScreenMutationArgs>
> &
  TChildProps;
export type DeleteScreenMutationFn = ReactApollo.MutationFn<
  DeleteScreenMutation,
  DeleteScreenMutationArgs
>;
export function DeleteScreenHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteScreenMutation,
        DeleteScreenMutationArgs,
        DeleteScreenProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteScreenMutation,
    DeleteScreenMutationArgs,
    DeleteScreenProps<TChildProps>
  >(DeleteScreenDocument, operationOptions);
}
export const ScreenListDocument = gql`
  query ScreenList {
    screen_list {
      id
      department
      structure_number
      name
      is_show
      structure_name
      room_count
      people_count
      people_in_count
    }
  }
`;
export interface ScreenListQuery {
  screen_list: Query["screen_list"];
}
export class ScreenListComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ScreenListQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ScreenListQuery, any>
        query={ScreenListDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ScreenListProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ScreenListQuery, any>
> &
  TChildProps;
export function ScreenListHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ScreenListQuery,
        any,
        ScreenListProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ScreenListQuery,
    any,
    ScreenListProps<TChildProps>
  >(ScreenListDocument, operationOptions);
}
export const ScreenListChooseDocument = gql`
  query ScreenListChoose {
    screen_list_choose {
      id
      department
      structure_number
      name
      is_show
      structure_name
      room_count
      people_count
      people_in_count
    }
  }
`;
export interface ScreenListChooseQuery {
  screen_list_choose: Query["screen_list_choose"];
}
export class ScreenListChooseComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<ScreenListChooseQuery, any>
  >
> {
  render() {
    return (
      <ReactApollo.Query<ScreenListChooseQuery, any>
        query={ScreenListChooseDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ScreenListChooseProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ScreenListChooseQuery, any>
> &
  TChildProps;
export function ScreenListChooseHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ScreenListChooseQuery,
        any,
        ScreenListChooseProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ScreenListChooseQuery,
    any,
    ScreenListChooseProps<TChildProps>
  >(ScreenListChooseDocument, operationOptions);
}
export const ScreensDocument = gql`
  query Screens($take: Int, $skip: Int, $search: String) {
    screens(take: $take, skip: $skip, search: $search) {
      count
      list {
        id
        department
        structure_number
        name
        is_show
        structure_name
        room_count
        sort_count
        people_count
        people_in_count
      }
    }
  }
`;
export interface ScreensQuery {
  screens: Query["screens"];
}
export class ScreensComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ScreensQuery, ScreensQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<ScreensQuery, ScreensQueryArgs>
        query={ScreensDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ScreensProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ScreensQuery, ScreensQueryArgs>
> &
  TChildProps;
export function ScreensHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ScreensQuery,
        ScreensQueryArgs,
        ScreensProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ScreensQuery,
    ScreensQueryArgs,
    ScreensProps<TChildProps>
  >(ScreensDocument, operationOptions);
}
export const UpdateScreenDocument = gql`
  mutation UpdateScreen($UpdateScreen: UpdateScreen!) {
    updateScreen(UpdateScreen: $UpdateScreen) {
      id
    }
  }
`;
export interface UpdateScreenMutation {
  updateScreen: Mutation["updateScreen"];
}
export class UpdateScreenComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateScreenMutation, UpdateScreenMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateScreenMutation, UpdateScreenMutationArgs>
        mutation={UpdateScreenDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateScreenProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateScreenMutation, UpdateScreenMutationArgs>
> &
  TChildProps;
export type UpdateScreenMutationFn = ReactApollo.MutationFn<
  UpdateScreenMutation,
  UpdateScreenMutationArgs
>;
export function UpdateScreenHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateScreenMutation,
        UpdateScreenMutationArgs,
        UpdateScreenProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateScreenMutation,
    UpdateScreenMutationArgs,
    UpdateScreenProps<TChildProps>
  >(UpdateScreenDocument, operationOptions);
}
export const SeeXlsxDocument = gql`
  query SeeXlsx($token: String!) {
    seeXlsx(token: $token) {
      uri
    }
  }
`;
export interface SeeXlsxQuery {
  seeXlsx: Query["seeXlsx"];
}
export class SeeXlsxComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SeeXlsxQuery, SeeXlsxQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<SeeXlsxQuery, SeeXlsxQueryArgs>
        query={SeeXlsxDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SeeXlsxProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SeeXlsxQuery, SeeXlsxQueryArgs>
> &
  TChildProps;
export function SeeXlsxHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SeeXlsxQuery,
        SeeXlsxQueryArgs,
        SeeXlsxProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SeeXlsxQuery,
    SeeXlsxQueryArgs,
    SeeXlsxProps<TChildProps>
  >(SeeXlsxDocument, operationOptions);
}
export const SignWeChatDocument = gql`
  mutation SignWeChat($SignWeChatInput: SignWeChatInput!) {
    signWeChat(SignWeChatInput: $SignWeChatInput) {
      debug
      appId
      timestamp
      nonceStr
      signature
      jsApiList
    }
  }
`;
export interface SignWeChatMutation {
  signWeChat: Mutation["signWeChat"];
}
export class SignWeChatComponent extends React.Component<
  Partial<ReactApollo.MutationProps<SignWeChatMutation, SignWeChatMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<SignWeChatMutation, SignWeChatMutationArgs>
        mutation={SignWeChatDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SignWeChatProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SignWeChatMutation, SignWeChatMutationArgs>
> &
  TChildProps;
export type SignWeChatMutationFn = ReactApollo.MutationFn<
  SignWeChatMutation,
  SignWeChatMutationArgs
>;
export function SignWeChatHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SignWeChatMutation,
        SignWeChatMutationArgs,
        SignWeChatProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SignWeChatMutation,
    SignWeChatMutationArgs,
    SignWeChatProps<TChildProps>
  >(SignWeChatDocument, operationOptions);
}
export const DataVDocument = gql`
  query DataV {
    datav {
      dorm_flow_counts {
        key
        count
      }
      people_count
      people_boy_count
      people_girl_count
      people_in_count
      people_out_count
      in_people_count
      out_people_count
      people_girl_in_count
      people_girl_out_count
      people_boy_in_count
      people_boy_out_count
      last_people {
        id
        image_uri
        megvii_image_uri
        people {
          id
          true_name
        }
      }
    }
    structure_number {
      count
      list {
        id
        name
        people_count
        people_in_count
      }
    }
  }
`;
export interface DataVQuery {
  datav: Query["datav"];
}
export class DataVComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DataVQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DataVQuery, any>
        query={DataVDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DataVProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DataVQuery, any>
> &
  TChildProps;
export function DataVHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DataVQuery,
        any,
        DataVProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DataVQuery,
    any,
    DataVProps<TChildProps>
  >(DataVDocument, operationOptions);
}
export const DataV1Document = gql`
  query DataV1 {
    datav {
      in_people_count
      out_people_count
      last_five_in_people {
        id
        image_uri
        megvii_image_uri
        people {
          id
          true_name
          department
          room_number
          bed_number
        }
      }
      last_five_out_people {
        id
        image_uri
        megvii_image_uri
        people {
          id
          true_name
          department
          room_number
          bed_number
        }
      }
    }
  }
`;
export interface DataV1Query {
  datav: Query["datav"];
}
export class DataV1Component extends React.Component<
  Partial<ReactApollo.QueryProps<DataV1Query, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DataV1Query, any>
        query={DataV1Document}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DataV1Props<TChildProps = any> = Partial<
  ReactApollo.DataProps<DataV1Query, any>
> &
  TChildProps;
export function DataV1HOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DataV1Query,
        any,
        DataV1Props<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DataV1Query,
    any,
    DataV1Props<TChildProps>
  >(DataV1Document, operationOptions);
}
export const DataV2Document = gql`
  query DataV2 {
    datav {
      people_count
      work_people_count
      common_people_count
      in_people_count
      out_people_count
      people_in_count
      work_people_in_count
      common_people_in_count
      today_visitor_count
      today_visitor_in_count
    }
    screen_list {
      id
      department
      structure_number
      name
      is_show
      structure_name
      room_count
      people_count
      people_in_count
    }
  }
`;
export interface DataV2Query {
  datav: Query["datav"];
}
export class DataV2Component extends React.Component<
  Partial<ReactApollo.QueryProps<DataV2Query, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DataV2Query, any>
        query={DataV2Document}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DataV2Props<TChildProps = any> = Partial<
  ReactApollo.DataProps<DataV2Query, any>
> &
  TChildProps;
export function DataV2HOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DataV2Query,
        any,
        DataV2Props<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DataV2Query,
    any,
    DataV2Props<TChildProps>
  >(DataV2Document, operationOptions);
}
export const StatisticsDocument = gql`
  query Statistics {
    statistics {
      dorm_flow_counts {
        key
        count
      }
      today_dorm_flow_count
    }
  }
`;
export interface StatisticsQuery {
  statistics: Query["statistics"];
}
export class StatisticsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StatisticsQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<StatisticsQuery, any>
        query={StatisticsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StatisticsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StatisticsQuery, any>
> &
  TChildProps;
export function StatisticsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StatisticsQuery,
        any,
        StatisticsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StatisticsQuery,
    any,
    StatisticsProps<TChildProps>
  >(StatisticsDocument, operationOptions);
}
export const StatisticsBedDocument = gql`
  query StatisticsBed {
    statistics {
      in_people_count
      out_people_count
    }
  }
`;
export interface StatisticsBedQuery {
  statistics: Query["statistics"];
}
export class StatisticsBedComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StatisticsBedQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<StatisticsBedQuery, any>
        query={StatisticsBedDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StatisticsBedProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StatisticsBedQuery, any>
> &
  TChildProps;
export function StatisticsBedHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StatisticsBedQuery,
        any,
        StatisticsBedProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StatisticsBedQuery,
    any,
    StatisticsBedProps<TChildProps>
  >(StatisticsBedDocument, operationOptions);
}
export const StructureNumberDocument = gql`
  query StructureNumber {
    structure_number {
      count
      list {
        id
        name
        people_count
        people_in_count
      }
    }
  }
`;
export interface StructureNumberQuery {
  structure_number: Query["structure_number"];
}
export class StructureNumberComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<StructureNumberQuery, any>
  >
> {
  render() {
    return (
      <ReactApollo.Query<StructureNumberQuery, any>
        query={StructureNumberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StructureNumberProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StructureNumberQuery, any>
> &
  TChildProps;
export function StructureNumberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StructureNumberQuery,
        any,
        StructureNumberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StructureNumberQuery,
    any,
    StructureNumberProps<TChildProps>
  >(StructureNumberDocument, operationOptions);
}
export const CreateStructureDocument = gql`
  mutation CreateStructure($CreateStructure: CreateStructure!) {
    createStructure(CreateStructure: $CreateStructure) {
      id
    }
  }
`;
export interface CreateStructureMutation {
  createStructure: Mutation["createStructure"];
}
export class CreateStructureComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateStructureMutation,
      CreateStructureMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateStructureMutation,
        CreateStructureMutationArgs
      >
        mutation={CreateStructureDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateStructureProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateStructureMutation, CreateStructureMutationArgs>
> &
  TChildProps;
export type CreateStructureMutationFn = ReactApollo.MutationFn<
  CreateStructureMutation,
  CreateStructureMutationArgs
>;
export function CreateStructureHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateStructureMutation,
        CreateStructureMutationArgs,
        CreateStructureProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateStructureMutation,
    CreateStructureMutationArgs,
    CreateStructureProps<TChildProps>
  >(CreateStructureDocument, operationOptions);
}
export const DeleteStructureDocument = gql`
  mutation DeleteStructure($id: String!) {
    deleteStructure(id: $id) {
      id
    }
  }
`;
export interface DeleteStructureMutation {
  deleteStructure: Mutation["deleteStructure"];
}
export class DeleteStructureComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteStructureMutation,
      DeleteStructureMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteStructureMutation,
        DeleteStructureMutationArgs
      >
        mutation={DeleteStructureDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteStructureProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteStructureMutation, DeleteStructureMutationArgs>
> &
  TChildProps;
export type DeleteStructureMutationFn = ReactApollo.MutationFn<
  DeleteStructureMutation,
  DeleteStructureMutationArgs
>;
export function DeleteStructureHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteStructureMutation,
        DeleteStructureMutationArgs,
        DeleteStructureProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteStructureMutation,
    DeleteStructureMutationArgs,
    DeleteStructureProps<TChildProps>
  >(DeleteStructureDocument, operationOptions);
}
export const StructureListDocument = gql`
  query StructureList {
    structure_list {
      id
      name
      father_number
      sort_number
      number
    }
  }
`;
export interface StructureListQuery {
  structure_list: Query["structure_list"];
}
export class StructureListComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StructureListQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<StructureListQuery, any>
        query={StructureListDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StructureListProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StructureListQuery, any>
> &
  TChildProps;
export function StructureListHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StructureListQuery,
        any,
        StructureListProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StructureListQuery,
    any,
    StructureListProps<TChildProps>
  >(StructureListDocument, operationOptions);
}
export const StructuresDocument = gql`
  query Structures($search: String, $take: Int = 25) {
    structures(take: $take, search: $search) {
      count
      list {
        id
        name
        father_number
        owner_number
        sort_number
        number
        created_date
        leading_cadre
        mobile
      }
    }
  }
`;
export interface StructuresQuery {
  structures: Query["structures"];
}
export class StructuresComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StructuresQuery, StructuresQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<StructuresQuery, StructuresQueryArgs>
        query={StructuresDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StructuresProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StructuresQuery, StructuresQueryArgs>
> &
  TChildProps;
export function StructuresHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StructuresQuery,
        StructuresQueryArgs,
        StructuresProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StructuresQuery,
    StructuresQueryArgs,
    StructuresProps<TChildProps>
  >(StructuresDocument, operationOptions);
}
export const StructuresTreeDocument = gql`
  query StructuresTree($take: Int = 500) {
    structures(take: $take) {
      count
      list {
        id
        name
        father_number
        number
      }
    }
  }
`;
export interface StructuresTreeQuery {
  structures: Query["structures"];
}
export class StructuresTreeComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StructuresTreeQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<StructuresTreeQuery, any>
        query={StructuresTreeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StructuresTreeProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StructuresTreeQuery, any>
> &
  TChildProps;
export function StructuresTreeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StructuresTreeQuery,
        any,
        StructuresTreeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StructuresTreeQuery,
    any,
    StructuresTreeProps<TChildProps>
  >(StructuresTreeDocument, operationOptions);
}
export const UpdateStructureDocument = gql`
  mutation UpdateStructure($UpdateStructure: UpdateStructure!) {
    updateStructure(UpdateStructure: $UpdateStructure) {
      id
    }
  }
`;
export interface UpdateStructureMutation {
  updateStructure: Mutation["updateStructure"];
}
export class UpdateStructureComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateStructureMutation,
      UpdateStructureMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateStructureMutation,
        UpdateStructureMutationArgs
      >
        mutation={UpdateStructureDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateStructureProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateStructureMutation, UpdateStructureMutationArgs>
> &
  TChildProps;
export type UpdateStructureMutationFn = ReactApollo.MutationFn<
  UpdateStructureMutation,
  UpdateStructureMutationArgs
>;
export function UpdateStructureHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateStructureMutation,
        UpdateStructureMutationArgs,
        UpdateStructureProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateStructureMutation,
    UpdateStructureMutationArgs,
    UpdateStructureProps<TChildProps>
  >(UpdateStructureDocument, operationOptions);
}
export const CreateTimingDocument = gql`
  mutation CreateTiming($CreateTiming: CreateTiming!) {
    createTiming(CreateTiming: $CreateTiming) {
      id
    }
  }
`;
export interface CreateTimingMutation {
  createTiming: Mutation["createTiming"];
}
export class CreateTimingComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateTimingMutation, CreateTimingMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateTimingMutation, CreateTimingMutationArgs>
        mutation={CreateTimingDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTimingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateTimingMutation, CreateTimingMutationArgs>
> &
  TChildProps;
export type CreateTimingMutationFn = ReactApollo.MutationFn<
  CreateTimingMutation,
  CreateTimingMutationArgs
>;
export function CreateTimingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTimingMutation,
        CreateTimingMutationArgs,
        CreateTimingProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTimingMutation,
    CreateTimingMutationArgs,
    CreateTimingProps<TChildProps>
  >(CreateTimingDocument, operationOptions);
}
export const DeleteTimingDocument = gql`
  mutation DeleteTiming($id: String!) {
    deleteTiming(id: $id) {
      id
    }
  }
`;
export interface DeleteTimingMutation {
  deleteTiming: Mutation["deleteTiming"];
}
export class DeleteTimingComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteTimingMutation, DeleteTimingMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteTimingMutation, DeleteTimingMutationArgs>
        mutation={DeleteTimingDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteTimingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteTimingMutation, DeleteTimingMutationArgs>
> &
  TChildProps;
export type DeleteTimingMutationFn = ReactApollo.MutationFn<
  DeleteTimingMutation,
  DeleteTimingMutationArgs
>;
export function DeleteTimingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteTimingMutation,
        DeleteTimingMutationArgs,
        DeleteTimingProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteTimingMutation,
    DeleteTimingMutationArgs,
    DeleteTimingProps<TChildProps>
  >(DeleteTimingDocument, operationOptions);
}
export const TimingsDocument = gql`
  query Timings($search: String, $skip: Int = 0, $take: Int = 25) {
    timings(take: $take, search: $search, skip: $skip) {
      count
      list {
        id
        name
        start_date
        end_date
      }
    }
  }
`;
export interface TimingsQuery {
  timings: Query["timings"];
}
export class TimingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<TimingsQuery, TimingsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<TimingsQuery, TimingsQueryArgs>
        query={TimingsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TimingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<TimingsQuery, TimingsQueryArgs>
> &
  TChildProps;
export function TimingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TimingsQuery,
        TimingsQueryArgs,
        TimingsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TimingsQuery,
    TimingsQueryArgs,
    TimingsProps<TChildProps>
  >(TimingsDocument, operationOptions);
}
export const UpdateTimingDocument = gql`
  mutation UpdateTiming($UpdateTiming: UpdateTiming!) {
    updateTiming(UpdateTiming: $UpdateTiming) {
      id
    }
  }
`;
export interface UpdateTimingMutation {
  updateTiming: Mutation["updateTiming"];
}
export class UpdateTimingComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateTimingMutation, UpdateTimingMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateTimingMutation, UpdateTimingMutationArgs>
        mutation={UpdateTimingDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateTimingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateTimingMutation, UpdateTimingMutationArgs>
> &
  TChildProps;
export type UpdateTimingMutationFn = ReactApollo.MutationFn<
  UpdateTimingMutation,
  UpdateTimingMutationArgs
>;
export function UpdateTimingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateTimingMutation,
        UpdateTimingMutationArgs,
        UpdateTimingProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateTimingMutation,
    UpdateTimingMutationArgs,
    UpdateTimingProps<TChildProps>
  >(UpdateTimingDocument, operationOptions);
}
export const ChangePasswordByOldPasswordDocument = gql`
  mutation ChangePasswordByOldPassword(
    $old_password: String!
    $password: String!
  ) {
    changePasswordByOldPassword(
      old_password: $old_password
      password: $password
    ) {
      id
    }
  }
`;
export interface ChangePasswordByOldPasswordMutation {
  changePasswordByOldPassword: Mutation["changePasswordByOldPassword"];
}
export class ChangePasswordByOldPasswordComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangePasswordByOldPasswordMutation,
      ChangePasswordByOldPasswordMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangePasswordByOldPasswordMutation,
        ChangePasswordByOldPasswordMutationArgs
      >
        mutation={ChangePasswordByOldPasswordDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangePasswordByOldPasswordProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangePasswordByOldPasswordMutation,
    ChangePasswordByOldPasswordMutationArgs
  >
> &
  TChildProps;
export type ChangePasswordByOldPasswordMutationFn = ReactApollo.MutationFn<
  ChangePasswordByOldPasswordMutation,
  ChangePasswordByOldPasswordMutationArgs
>;
export function ChangePasswordByOldPasswordHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangePasswordByOldPasswordMutation,
        ChangePasswordByOldPasswordMutationArgs,
        ChangePasswordByOldPasswordProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangePasswordByOldPasswordMutation,
    ChangePasswordByOldPasswordMutationArgs,
    ChangePasswordByOldPasswordProps<TChildProps>
  >(ChangePasswordByOldPasswordDocument, operationOptions);
}
export const CreateUserDocument = gql`
  mutation CreateUser($CreateUser: CreateUser!) {
    createUser(CreateUser: $CreateUser) {
      id
    }
  }
`;
export interface CreateUserMutation {
  createUser: Mutation["createUser"];
}
export class CreateUserComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateUserMutation, CreateUserMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateUserMutation, CreateUserMutationArgs>
        mutation={CreateUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateUserMutation, CreateUserMutationArgs>
> &
  TChildProps;
export type CreateUserMutationFn = ReactApollo.MutationFn<
  CreateUserMutation,
  CreateUserMutationArgs
>;
export function CreateUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateUserMutation,
        CreateUserMutationArgs,
        CreateUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateUserMutation,
    CreateUserMutationArgs,
    CreateUserProps<TChildProps>
  >(CreateUserDocument, operationOptions);
}
export const UsersDocument = gql`
  query Users($search: String, $take: Int = 25) {
    users(take: $take, search: $search) {
      count
      list {
        id
        username
        mobile
        email
      }
    }
  }
`;
export interface UsersQuery {
  users: Query["users"];
}
export class UsersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<UsersQuery, UsersQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<UsersQuery, UsersQueryArgs>
        query={UsersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UsersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<UsersQuery, UsersQueryArgs>
> &
  TChildProps;
export function UsersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UsersQuery,
        UsersQueryArgs,
        UsersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UsersQuery,
    UsersQueryArgs,
    UsersProps<TChildProps>
  >(UsersDocument, operationOptions);
}
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
      username
      permissions
      roles
    }
  }
`;
export interface ViewerQuery {
  viewer: Query["viewer"];
}
export class ViewerComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerQuery, any>
        query={ViewerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerQuery, any>
> &
  TChildProps;
export function ViewerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerQuery,
        any,
        ViewerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerQuery,
    any,
    ViewerProps<TChildProps>
  >(ViewerDocument, operationOptions);
}
export const GetFirstWarnRuleDocument = gql`
  query GetFirstWarnRule {
    getFirstWarnRule {
      id
      start_time
      end_time
      no_return_start_time
      no_return_type
    }
  }
`;
export interface GetFirstWarnRuleQuery {
  getFirstWarnRule: Query["getFirstWarnRule"];
}
export class GetFirstWarnRuleComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetFirstWarnRuleQuery, any>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetFirstWarnRuleQuery, any>
        query={GetFirstWarnRuleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetFirstWarnRuleProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetFirstWarnRuleQuery, any>
> &
  TChildProps;
export function GetFirstWarnRuleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetFirstWarnRuleQuery,
        any,
        GetFirstWarnRuleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetFirstWarnRuleQuery,
    any,
    GetFirstWarnRuleProps<TChildProps>
  >(GetFirstWarnRuleDocument, operationOptions);
}
export const UpdateWarnRuleDocument = gql`
  mutation UpdateWarnRule($UpdateWarnRule: UpdateWarnRule!) {
    updateWarnRule(UpdateWarnRule: $UpdateWarnRule) {
      id
    }
  }
`;
export interface UpdateWarnRuleMutation {
  updateWarnRule: Mutation["updateWarnRule"];
}
export class UpdateWarnRuleComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateWarnRuleMutation,
      UpdateWarnRuleMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateWarnRuleMutation, UpdateWarnRuleMutationArgs>
        mutation={UpdateWarnRuleDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateWarnRuleProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateWarnRuleMutation, UpdateWarnRuleMutationArgs>
> &
  TChildProps;
export type UpdateWarnRuleMutationFn = ReactApollo.MutationFn<
  UpdateWarnRuleMutation,
  UpdateWarnRuleMutationArgs
>;
export function UpdateWarnRuleHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateWarnRuleMutation,
        UpdateWarnRuleMutationArgs,
        UpdateWarnRuleProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateWarnRuleMutation,
    UpdateWarnRuleMutationArgs,
    UpdateWarnRuleProps<TChildProps>
  >(UpdateWarnRuleDocument, operationOptions);
}
