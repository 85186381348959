// export const server_uri = window.location.origin;
// export const server_uri = 'http://127.0.0.1:9999';
// let dd_uri = window.location.origin;

let dd_uri = window.location.protocol + '//' + window.location.hostname
// let dd_uri = window.location.protocol + '//' + window.location.hostname + ':9999'

let bed_base_count = 3240;

let base_megvii_uri = 'http://192.168.1.50/';
// let base_megvii_uri = 'http://10.1.11.4/';

let is_base = false;

let name = '朗清园工勤宿舍';

if (
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'
) {
  dd_uri = 'http://127.0.0.1:9999';
} else if (window.location.hostname === '192.168.1.2') {
  dd_uri = 'http://192.168.1.2:9999';
  bed_base_count = 3240;
  base_megvii_uri = 'http://192.168.1.1/';
  is_base = true;
  name = '召里';
} else if (window.location.hostname === '10.1.11.23') {
  dd_uri = 'http://10.1.11.23:9999';
  bed_base_count = 3044;
} else if (window.location.hostname === '10.11.2.2') {
  dd_uri = 'http://10.11.2.2:9999';
  bed_base_count = 3044;
  base_megvii_uri = 'http://10.11.2.2:9991/';
} else if (window.location.hostname === 'gql.langqingtown.online') {
  base_megvii_uri = 'http://gql.langqingtown.online:4343';
}

export const is_zhaoli = is_base;

export const system_name = name;

export const server_uri = dd_uri;
export const graphql_uri = `${server_uri}/graphql`;
export const api_uri = `${server_uri}/api`;

export const oss_uri = ``;

export const company_name = '无';

export const bed_count = bed_base_count;

export const megvii_uri = base_megvii_uri;
