import React from 'react';
import { Button, Modal, Form, Input } from 'antd';

import { CreateDepartmentComponent } from '../../apollo';
import { message } from '../../components/message';

class AddDepartmentModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateDepartment: { ...values },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>
          新增部门
        </Button>
        <Modal
          title="新增部门"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入名称!' }],
              })(<Input placeholder="名称" />)}
            </Form.Item>
            <Form.Item>
              <CreateDepartmentComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateDepartmentComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddDepartmentModalForm: any = Form.create({
  name: 'create_department',
})(AddDepartmentModal);
