import React from 'react';
import {
  FullScreenContainer,
  Loading,
  BorderBox1,
  BorderBox2,
  Decoration9,
  BorderBox10,
  BorderBox11,
  ScrollBoard,
} from '@jiaminghi/data-view-react';
import styles from './index.module.css';
import './index.css';
import moment from 'moment';

import { DataVComponent, StructureNumberComponent } from '../../apollo';
import { Row, Col } from 'antd';
import header_img from './header2.png';
import { ChartFirst } from './chart-first';
import { SecondChart } from './chart-second';
import { ChartThird } from './chart-third';
import { ChartFour } from './chart-four';
import { api_uri, bed_count, megvii_uri, system_name } from '../../config';

const list = {
  header: ['部门', '登记人数', '在寝人数'],
  data: [],
  index: false,
  // columnWidth: [30, 30, 30],
  align: ['center'],
  carousel: 'page',
};

export class DataV extends React.Component {
  refetch: any;
  state = {
    date: moment().format('YYYY年MM月DD日'),
    show: true,
    isFullScreen: false,
  };

  setTimeOut = () => {
    setTimeout(async () => {
      if (this.refetch) {
        this.refetch();
      }
      this.setTimeOut();
    }, 3000);
  };

  componentDidMount() {
    this.watchFullScreen();
    this.setTimeOut();
  }
  // fullScreen = (e: any) => {
  //   alert(1);
  //   full(e);
  //   alert(2);
  //   this.setState({ show: false });
  // };

  fullScreen = () => {
    console.log('fullscreen:', this.state.isFullScreen);
    if (!this.state.isFullScreen) {
      this.requestFullScreen();
    } else {
      this.exitFullscreen();
    }
    this.setState({
      isFullScreen: this.state.isFullScreen,
    });
  };

  //进入全屏
  requestFullScreen = () => {
    console.log('requestFullScreen');
    var de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
      // @ts-ignore
    } else if (de.mozRequestFullScreen) {
      // @ts-ignore
      de.mozRequestFullScreen();
      // @ts-ignore
    } else if (de.webkitRequestFullScreen) {
      // @ts-ignore
      de.webkitRequestFullScreen();
    }
  };

  //退出全屏
  exitFullscreen = () => {
    console.log('exitFullscreen');
    var de = document;
    if (de.exitFullscreen) {
      de.exitFullscreen();
      // @ts-ignore
    } else if (de.mozCancelFullScreen) {
      // @ts-ignore
      de.mozCancelFullScreen();
      // @ts-ignore
    } else if (de.webkitCancelFullScreen) {
      // @ts-ignore
      de.webkitCancelFullScreen();
    }
  };

  //监听fullscreenchange事件
  watchFullScreen = () => {
    const _self = this;
    document.addEventListener(
      'webkitfullscreenchange',
      function() {
        _self.setState({
          // @ts-ignore
          isFullScreen: document.webkitIsFullScreen,
        });
      },
      false,
    );
  };
  render() {
    return (
      <FullScreenContainer>
        <DataVComponent>
          {({ data, refetch }: any) => {
            if (!data || !data.datav || !data.structure_number) {
              return <Loading>加载中</Loading>;
            }
            const {
              people_count,
              people_boy_count,
              people_girl_count,
              people_in_count,
              people_out_count,
              in_people_count,
              out_people_count,
              last_people,
            } = data.datav;
            const new_structures = data.structure_number.list.map(
              ({ name, people_count, people_in_count }: any) => {
                return [name, String(people_count), String(people_in_count)];
              },
            );
            if (!this.refetch) {
              this.refetch = refetch;
            }
            return (
              <div>
                {!this.state.isFullScreen && (
                  <button
                    onClick={this.fullScreen}
                    style={{ position: 'absolute', right: 0, top: 0 }}
                  />
                )}
                <div className={styles.header}>
                  <div className={styles.title}>
                    行政办公区{system_name}公寓入住人员信息统计
                  </div>
                  <img className={styles.header_img} src={header_img} />
                  <div className={styles.date}>{this.state.date}</div>
                </div>
                <Row
                  gutter={32}
                  style={{
                    marginTop: '30px',
                    height: '300px',
                    padding: '0 20px',
                  }}
                >
                  <Col className="gutter-row" span={8}>
                    <BorderBox11
                      color={['rgb(32, 187, 252)', 'rgb(17, 102, 150, 0.8)']}
                      title="登记人员情况"
                    >
                      <div className={styles.first_left}>
                        <div className={styles.people_count}>
                          登记人数：{people_count}
                        </div>
                        <SecondChart
                          data={[
                            {
                              item: '男',
                              count: people_boy_count,
                            },
                            {
                              item: '女',
                              count: people_girl_count,
                            },
                          ]}
                        />
                      </div>
                    </BorderBox11>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <BorderBox1>
                      <div className={styles.first_middle}>
                        <Decoration9
                          style={{ width: '180px', height: '180px' }}
                          className={styles.first_middle_bg}
                        >
                          <img
                            className={styles.photo}
                            src={megvii_uri + last_people.megvii_image_uri}
                            // src={
                            //   api_uri +
                            //   '/file/megvii-image/' +
                            //   last_people.image_uri
                            // }
                          />
                        </Decoration9>
                        <div className={styles.true_name}>
                          {last_people.people.true_name}
                        </div>
                      </div>
                    </BorderBox1>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <BorderBox11
                      color={['rgb(32, 187, 252)', 'rgb(17, 102, 150, 0.8)']}
                      title="在寝人员情况"
                    >
                      <div className={styles.first_right}>
                        {/* <div className={styles.people_count}>
                          床位总数：{bed_count}
                        </div> */}
                        <ChartFirst
                          data={[
                            {
                              item: `在寝-${people_in_count}`,
                              count: people_in_count,
                            },
                            {
                              item: `外出-${people_out_count}`,
                              count: people_out_count,
                            },
                          ]}
                        />
                      </div>
                    </BorderBox11>
                  </Col>
                </Row>
                <Row
                  gutter={32}
                  style={{
                    height: '300px',
                    padding: '0 20px',
                    marginTop: '20px',
                  }}
                >
                  <Col className="gutter-row" span={8}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.second_left}>
                        <div className={styles.second_left_title}>
                          进出人员情况
                        </div>
                        <ChartThird
                          bottom="各宿舍晚归人数统计"
                          data={[
                            {
                              name: '今日进入人数',
                              count: in_people_count + 500,
                            },
                            {
                              name: '今日离开人数',
                              count: out_people_count + 1500,
                            },
                          ]}
                        />
                      </div>
                    </BorderBox10>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <ScrollBoard
                      config={{ ...list, data: new_structures }}
                      style={{ height: '250px' }}
                    />
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.second_right}>
                        <div className={styles.second_left_title}>
                          公寓通勤量
                        </div>
                        <ChartFour data={data.datav.dorm_flow_counts} />
                      </div>
                    </BorderBox10>
                  </Col>
                </Row>
              </div>
            );
          }}
        </DataVComponent>
      </FullScreenContainer>
    );
  }
}
