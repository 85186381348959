import React from 'react';

/**
 * 格式化时长
 * @param second
 * @returns {string}
 */
export const realFormatSecond = (second: number | string) => {
  let secondType = typeof second;

  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(String(second));

    let hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    let mimute = Math.floor(second / 60);
    second = second - mimute * 60;

    return (
      hours + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
    );
  } else {
    return '0:00:00';
  }
};

export const dealNumber = function(money: number | string) {
  if (money && money != null) {
    money = String(money);
    var left = money.split('.')[0];

    var temp = left
      .split('')
      .reverse()
      .join('')
      .match(/(\d{1,3})/g) || ['0'];
    return (
      (Number(money) < 0 ? '-' : '') +
      temp
        .join(',')
        .split('')
        .reverse()
        .join('')
    );
  } else if (money === 0) {
    return '0';
  } else {
    return '';
  }
};

export function getTrueName(name: string) {
  if (name) {
    const names = name.match(/-(\S*)\./);
    if (names) {
      const new_name = name.replace(`-${names[names.length - 1]}`, '');
      return new_name;
    }
    return '';
  }
  return '';
}

export function getErrorColumns(columns: any[]) {
  return columns.map(col => {
    return {
      width: 120,
      dataIndex: col.title,
      key: col.title,
      ...col,
      render: (text: any, record: any) => {
        if (record.error_key === col.title) {
          return <span style={{ color: 'red' }}>{text}</span>;
        }
        return text;
      },
    };
  });
}

export function isPhoneNumber(tel: String) {
  return String(tel).length === 11 && !isNaN(Number(tel));
  // const reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
  // const reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
  // const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  // // const reg = /^1[345678]\d{9}$/;
  // return reg.test(tel);
}

const grades = ['大一', '大二', '大三', '大四', '大五'];

export function getGrades() {
  const year = new Date().getFullYear();
  const years: any = [];
  for (var i = 0; i < 5; i++) {
    years.push({ text: grades[i], value: year - i });
  }
  return years;
}

export function mapData(data: any, key: string, callback: any) {
  const new_data =
    data[key] && data[key].list ? data[key].list.map(callback) : [];
  const count = (data[key] && data[key].count) || 0;
  return {
    data: new_data,
    count,
  };
}
