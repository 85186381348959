import React from 'react';
import { Get } from 'react-axios';

import queryString from 'query-string';

import XLSX2 from 'xlsx';
import XLSX from 'xlsx-style';

import { Button, DatePicker, Icon, Dropdown, Menu } from 'antd';
import moment from 'moment';

import { BaseTable } from '../../components/base-table';

import { BaseLayout } from '../../layout/base-layout';

import styles from './index.module.css';
import { system_name } from '../../config';

declare global {
  interface Window {
    MouseEvent: typeof MouseEvent;
  }
}

const month_array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default class MonthExport extends BaseTable {
  state = {
    date: '',
    query_params: {},
    edit: false,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };

  sheet2blob = (sheet: any, sheetName?: any) => {
    sheetName = sheetName || 'sheet1';
    var workbook: any = {
      SheetNames: [sheetName],
      Sheets: {},
    };
    workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

    var wopts: any = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    }); // 字符串转ArrayBuffer
    function s2ab(s: any) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    return blob;
  };

  openDownloadDialog = (url: any, saveName: any) => {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null,
      );
    }
    aLink.dispatchEvent(event);
  };

  exportExcel = ({ unit, center }: any) => {
    const alpha_cols = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
    ];
    const sheet = XLSX2.utils.table_to_sheet(
      document.getElementById('table-to-xls'),
    );
    const sub_title = ['A3', 'A4', 'A7', 'A11', 'G3', `A${unit.length + 13}`];
    Object.keys(sheet).forEach((key) => {
      if (/^[A-Z]\d{1,}$/g.test(key)) {
        sheet[key].s = {
          font:
            key === 'A1' || key === 'A10'
              ? { sz: 16, bold: true }
              : sub_title.indexOf(key) > -1
              ? { sz: 12, bold: true }
              : { sz: 11 },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
        };
      }
    });
    const border_cells = ['A4', 'A3', 'A5'];
    border_cells.map((item) => {
      sheet[item].s.border = {
        top: {
          style: 'thin',
          color: { rgb: 'cccccc' },
        },
        bottom: {
          style: 'thin',
          color: { rgb: 'cccccc' },
        },
      };
    });
    let row_count: any = sheet['!ref'] && sheet['!ref'].split(':')[1];
    row_count = Number(row_count.slice(1, row_count.length));
    const sum_cells = alpha_cols.map((item) => `${item}${unit.length + 12}`);
    alpha_cols.forEach((item) => sum_cells.push(`${item}${row_count}`));
    let depart_num = 0;
    center.map((department: any, dep_index: any) => {
      if (department.items) {
        department.items.map((item: any, index: any) => {
          depart_num++;
          if (item.name === '合计') {
            alpha_cols
              .slice(3)
              .forEach((alpha) =>
                sum_cells.push(`${alpha}${depart_num + unit.length + 14}`),
              );
          }
        });
      }
    });
    const back_cells: any = {
      ace0a1: ['A4', 'A7', `A${unit.length + 13}`],
      b6c8ec: sum_cells,
    };
    Object.keys(back_cells).forEach((color) => {
      if (back_cells[color]) {
        back_cells[color].map((cell: string) => {
          if (!sheet[cell]) return;
          sheet[cell].s.fill = {
            fgColor: {
              rgb: color,
            },
            bgColor: {
              indexed: 64,
            },
            patternType: 'solid',
          };
          sheet[cell].s.border = {
            top: {
              style: 'thin',
              color: { rgb: 'aaa9a9' },
            },
            bottom: {
              style: 'thin',
              color: { rgb: 'aaa9a9' },
            },
            left: {
              style: 'thin',
              color: { rgb: 'aaa9a9' },
            },
            right: {
              style: 'thin',
              color: { rgb: 'aaa9a9' },
            },
          };
        });
      }
    });
    sheet['!cols'] = Array(16)
      .fill(16)
      .map((item, index) => {
        return { wpx: 516 / 16 };
        // return { wpx: 650 / 16 };
      }); //单元格列宽
    sheet['!rows'] = Array.from(new Array(row_count)).map((item, index) => {
      return { hpx: 30 };
    }); //单元格列高
    // 设置边框
    const array = Array.from(new Array(row_count));
    alpha_cols.forEach((item) => {
      array.forEach((_, index) => {
        if (!sheet[`${item}${index + 1}`]) {
          sheet[`${item}${index + 1}`] = {
            s: {
              border: {
                left: { style: 'thin' },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              },
            },
          };
        } else {
          sheet[`${item}${index + 1}`].s = {
            ...sheet[`${item}${index + 1}`].s,
            border: {
              left: { style: 'thin' },
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
          };
        }
      });
    });
    this.openDownloadDialog(this.sheet2blob(sheet), '月报.xlsx');
  };

  render() {
    // const params = queryString.parse(this.props.location.search);
    const { edit, year, month } = this.state;
    // const { dailies_data } = this.state;
    const date = new Date();
    let year_array = [2020];
    const new_array = date.getFullYear() - 2020;
    if (new_array > 0) {
      year_array = new Array(new_array)
        .fill(1)
        .map((_, index: number) => date.getFullYear() - index);
    }
    const menu = (
      <Menu onClick={(e) => this.setState({ year: e.key })}>
        {year_array.map((item) => {
          return (
            <Menu.Item key={item}>
              {/* <Icon type="user" /> */}
              {item}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    const menu_month = (
      <Menu onClick={(e) => this.setState({ month: e.key })}>
        {month_array.map((item) => {
          return <Menu.Item key={item}>{item}</Menu.Item>;
        })}
      </Menu>
    );
    return (
      <BaseLayout>
        <Get url={`/statistics/month/${year}/${month}`}>
          {(error: any, response: any, isLoading: any, makeRequest: any) => {
            if (error || isLoading || !response) {
              return '';
            }
            // console.log('response.data', response.data);
            let { daily, center, unit } = response.data;
            return (
              <div className="table_container">
                <div style={{ marginBottom: '20px' }}>
                  {/* <DatePicker
                    onChange={(date, dateString) =>
                      this.setState({ date: dateString })
                    }
                    value={moment(date)}
                    placeholder="选择日期"
                    disabledDate={(current) => {
                      if (
                        current &&
                        current.toDate() > new Date('2020-05-05') &&
                        current.toDate() <= new Date()
                      ) {
                        return false;
                      }
                      return true;
                    }}
                  /> */}
                  <Dropdown overlay={menu}>
                    <Button>
                      {year} <Icon type="down" />
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={menu_month}>
                    <Button>
                      {month} <Icon type="down" />
                    </Button>
                  </Dropdown>
                  <Button
                    type="primary"
                    onClick={
                      daily
                        ? () => this.exportExcel({ daily, center, unit })
                        : () => alert('当前日期没有数据可以导出')
                    }
                    style={{ marginLeft: '20px' }}
                  >
                    导出
                  </Button>
                  {/* {edit ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          edit: false,
                        });
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      取消
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          edit: true,
                        });
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      编辑
                    </Button>
                  )} */}
                </div>
                {daily ? (
                  <table id="table-to-xls" className={styles.daily_table}>
                    <thead>
                      <tr>
                        <th className={styles.sec_title} colSpan={16}>
                          行政办公区{system_name}住宿数据统计月报
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={16}>
                          {moment(daily.created_date).format(
                            'YYYY[年]MM[月]DD[日] HH:mm',
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={7}>报告日期</th>
                        <th colSpan={9}>
                          {moment().format('YYYY[年]MM[月]DD[日]')}
                        </th>
                      </tr>
                      <tr>
                        <th className={styles.sub_title} colSpan={16}>
                          平台注册情况
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={7} rowSpan={2}>
                          注册人数
                        </td>
                        <td colSpan={2} rowSpan={2}>
                          {daily.people_count}
                        </td>
                        <td rowSpan={2}>人</td>
                        <td colSpan={3}>工勤人员</td>
                        <td colSpan={2}>{daily.common_people_count}</td>
                        <td>人</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>工作人员</td>
                        <td colSpan={2}>{daily.work_people_count}</td>
                        <td>人</td>
                      </tr>
                      <tr>
                        <th className={styles.sub_title} colSpan={16}>
                          人员在寝情况
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={7}>工寝人员在寝人数</td>
                        <td colSpan={2}>{daily.common_now_in_count}</td>
                        <td>人</td>
                        <td colSpan={3}>工勤人员未归人数</td>
                        <td colSpan={2}>{daily.common_now_out_count}</td>
                        <td>人</td>
                      </tr>
                      <tr>
                        <td colSpan={7}>工作人员在寝人数</td>
                        <td colSpan={8}>{daily.in_people_count}</td>
                        <td>人</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th className={styles.sec_title} colSpan={16}>
                          登记人员住宿情况表
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className={styles.sub_title} colSpan={16}>
                          按单位统计
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={7}>单位</td>
                        <td colSpan={3}>登记人数</td>
                        <td colSpan={3}>实住人数</td>
                        <td colSpan={3}>未住人数</td>
                      </tr>
                      {unit.map((item: any) => (
                        <tr
                          key={item.name}
                          className={
                            item.name === '总计' ? styles.statistic : ''
                          }
                        >
                          <td colSpan={7}>{item.name}</td>
                          <td colSpan={2}>{item.count}</td>
                          <td>人</td>
                          <td colSpan={2}>{item.in_count}</td>
                          <td>人</td>
                          <td colSpan={2}>{item.out_count}</td>
                          <td>人</td>
                        </tr>
                      ))}
                      <tr>
                        <th className={styles.sub_title} colSpan={16}>
                          按部门统计
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={3}>所属部门</td>
                        <td colSpan={4}>服务单位</td>
                        <td colSpan={3}>登记人数</td>
                        <td colSpan={3}>实住人数</td>
                        <td colSpan={3}>未住人数</td>
                      </tr>
                      {center.map((center: any) => {
                        if (center.items) {
                          return center.items.map((item: any, index: any) => (
                            <tr
                              key={item.name}
                              className={
                                item.name === '合计' ? styles.statistic : ''
                              }
                            >
                              {index === 0 && (
                                <td colSpan={3} rowSpan={center.items.length}>
                                  {center.name}
                                </td>
                              )}
                              <td colSpan={4}>{item.name}</td>
                              <td colSpan={2}>{item.count}</td>
                              <td>人</td>
                              <td colSpan={2}>{item.in_count}</td>
                              <td>人</td>
                              <td colSpan={2}>{item.out_count}</td>
                              <td>人</td>
                              {this.state.edit && item.name !== '合计' && (
                                <>
                                  <Icon type="minus-circle" />
                                  {(index === center.items.length - 1 ||
                                    index === center.items.length - 2) && (
                                    <Icon type="plus-circle" />
                                  )}
                                </>
                              )}
                            </tr>
                          ));
                        } else {
                          return (
                            <tr key={center.name} className={styles.statistic}>
                              <td colSpan={7}>{center.name}</td>
                              <td colSpan={2}>{center.count}</td>
                              <td>人</td>
                              <td colSpan={2}>{center.in_count}</td>
                              <td>人</td>
                              <td colSpan={2}>{center.out_count}</td>
                              <td>人</td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p>当前日期没有数据</p>
                )}
              </div>
            );
          }}
        </Get>
      </BaseLayout>
    );
  }
}
