const uri = encodeURIComponent(
  'rtsp://admin:admin123@172.16.21.122:554/cam/realmonitor?channel=1&subtype=0',
);

export const websocket = (message: (data: any) => void) => {
  const ws = new WebSocket(`ws://172.16.21.120:9000/video?url=${uri}`);
  ws.onopen = () => {
    console.log('open');
    // ws.send('something');
  };
  ws.onclose = () => {
    console.log('onclose');
  };
  ws.onerror = (error: any) => {
    console.log('onerror', error);
  };
  ws.onmessage = message;

  return ws;
};
