import React from 'react';

import queryString from 'query-string';

import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './leave.columns';

// import { AddLeaveModalForm } from './create-leave';
import { LeavesComponent, CommonFilterComponent } from '../../apollo';
import NoAccess from '../../components/no-access';

function getData(data: any) {
  const list_data = data.leaves;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            key: item.id,
            true_name: item.true_name,
            mobile: item.mobile,
            email: item.email,
            created_date: item.created_date,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

export default class Leave extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <CommonFilterComponent variables={{ take: 100 }}>
          {({ data, error, loading, refetch }: any) => {
            if (error || loading || !data) {
              return '';
            }
            const dorms = data.dorms.list.map(({ number }: any) => ({
              text: number,
              value: number,
            }));
            const structures = data.structures.list.map(
              ({ number, name }: any) => ({
                text: name,
                value: number,
              }),
            );
            return (
              <LeavesComponent
                variables={{
                  skip,
                  take: 10,
                  ...this.state.query_params,
                }}
                fetchPolicy="no-cache"
              >
                {({ data, error, refetch }) => {
                  if (error) {
                    if (
                      error.message.includes('Access denied') ||
                      error.message.includes('Forbidden resource')
                    ) {
                      return <NoAccess />;
                    }
                  }
                  const result = getData(data);

                  const new_columns = columns(structures, refetch);
                  return (
                    <div className="table_container">
                      <TableTitle name={`请假管理`} />

                      <TableSearch
                        style={{ marginTop: '60px' }}
                        search={(e: any) => this.search(e.target.value)}
                        options={columns_titles}
                        checked_options={this.state.checked_options}
                        onCheckAllChange={this.onCheckAllChange}
                        onColumnChange={this.onColumnChange}
                        columns={this.state.checked_options}
                        fixed={this.state.fixed}
                        fixedName={(fixed: any) => {
                          this.setState({ fixed });
                        }}
                        params={{
                          skip,
                          take: 10,
                          ...this.state.query_params,
                        }}
                      >
                        {/* <AddLeaveModalForm refetch={refetch} /> */}
                      </TableSearch>
                      <AllTable
                        scroll={{ x: true }}
                        onChange={(
                          pagination: any,
                          filters: any,
                          sorter: any,
                        ) =>
                          this.onTableChange(
                            pagination,
                            filters,
                            sorter,
                            refetch,
                          )
                        }
                        columns={new_columns}
                        loading={data && data.leaves ? false : true}
                        dataSource={result.data}
                        pagination={{
                          total: result.count,
                          pageSize: 10,
                        }}
                        checked_options={this.state.checked_options}
                        fixed={this.state.fixed}
                      />
                    </div>
                  );
                }}
              </LeavesComponent>
            );
          }}
        </CommonFilterComponent>
      </BaseLayout>
    );
  }
}
