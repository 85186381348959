import React from 'react';
import moment from 'moment';
import { api_uri } from '../../config';
import { years } from '../../db/years.db';
import { dorms } from '../stranger-record/stranger-record.columns';

export const columns = [
  {
    title: '抓拍照片',
    dataIndex: 'image_uri',
    key: 'image_uri',
    width: 80,
    // render: (text: string, record: any) =>
    //   text && <img style={{ width: 50, height: 50 }} src={text} />,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="抓拍照片"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/megvii-image/' + text}
        />
      ),
  },
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    width: 80,
    render: (text: string, record: any) => (
      <a href={`/people-detail?student_number=${record.job_number}`}>{text}</a>
    ),
  },
  {
    title: '系',
    dataIndex: 'department',
    key: 'department',
    // search: true,
    width: 80,
    // filters: departments.map(department => ({
    //   text: department,
    //   value: department,
    // })),
  },
  {
    title: '专业',
    dataIndex: 'major',
    key: 'major',
    // search: true,
    width: 80,
    // filters: majors.map(major => ({
    //   text: major,
    //   value: major,
    // })),
  },
  {
    title: '年级',
    dataIndex: 'grade',
    key: 'grade',
    // search: true,
    width: 80,
    filters: years.map(item => ({ text: item, value: item })),
  },
  {
    title: '楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
    filters: dorms.map(item => ({ text: item, value: item })),
  },
  {
    title: '方向',
    dataIndex: 'direction',
    key: 'direction',
    width: 120,
    filters: [
      { text: '出口', value: '出口' },
      { text: '入口', value: '入口' },
    ],
  },
  {
    title: '时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
  {
    title: '备注',
    dataIndex: 'note',
    key: 'note',
    width: 120,
    render: (text: string) => text,
  },
  {
    title: '操作',
    width: 60,
    key: 'action',
  },
];
