import React from 'react';
import moment from 'moment';
import { api_uri, megvii_uri } from '../../config';

export const columns = (filter?: any) => [
  {
    title: '抓拍照片',
    dataIndex: 'megvii_image_uri',
    key: 'megvii_image_uri',
    width: 80,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="抓拍照片"
          style={{ width: 50, height: 50 }}
          src={megvii_uri + text}
        />
      ),
  },
  // {
  //   title: '抓拍照片',
  //   dataIndex: 'image_uri',
  //   key: 'image_uri',
  //   width: 80,
  //   // render: (text: string, record: any) =>
  //   //   text && <img style={{ width: 50, height: 50 }} src={text} />,
  //   render: (text: string, record: any) =>
  //     text && (
  //       <img
  //         alt="抓拍照片"
  //         style={{ width: 50, height: 50 }}
  //         src={api_uri + '/file/megvii-image/' + text}
  //       />
  //     ),
  // },
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    width: 80,
    render: (text: string, record: any) =>
      record.user_id ? (
        <a href={`/people-detail?id=${record.user_id}`}>{text}</a>
      ) : (
        text
      ),
  },
  {
    title: '部门',
    dataIndex: 'father_department',
    key: 'father_department',
    // search: true,
    width: 80,
    filters: filter && filter.structures,
    render: (text: string) => text || '无',
  },
  {
    title: '分部门',
    dataIndex: 'department',
    key: 'department',
    // search: true,
    width: 80,
  },
  {
    title: '楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
    filters: filter && filter.dorms,
  },
  {
    title: '方向',
    dataIndex: 'direction',
    key: 'direction',
    width: 120,
    filters: [
      { text: '出口', value: '出口' },
      { text: '入口', value: '入口' },
    ],
  },
  {
    title: '时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
];
