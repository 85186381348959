import React from 'react';
import { Button, Modal, Form, Input, DatePicker } from 'antd';

import { CreateTimingComponent } from '../../apollo';
import { message } from '../../components/message';
import moment from 'moment';

class AddTimingModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateTiming: {
                ...values,
                start_date: moment(values.start_date).format('YYYY-MM-DD'),
                end_date: moment(values.end_date).format('YYYY-MM-DD'),
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          新增假日
        </Button>
        <Modal
          title="新增假日"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入名称!' }],
              })(<Input placeholder="名称" />)}
            </Form.Item>
            <Form.Item label="开始时间">
              {getFieldDecorator('start_date', {
                rules: [{ required: true, message: '请选择开始时间!' }],
              })(<DatePicker format={'YYYY/MM/DD'} />)}
            </Form.Item>
            <Form.Item label="结束时间">
              {getFieldDecorator('end_date', {
                rules: [{ required: true, message: '请选择结束时间!' }],
              })(<DatePicker format={'YYYY/MM/DD'} />)}
            </Form.Item>
            {/* <Form.Item label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea autosize={{ minRows: 5 }} placeholder="备注" />,
              )}
            </Form.Item> */}
            <Form.Item>
              <CreateTimingComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateTimingComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddTimingModalForm: any = Form.create({
  name: 'create_instructor',
})(AddTimingModal);
