export const permission: any = {
  管理人员: ['查看人员', '创建人员', '更新人员', '删除人员'],
  管理黑名单: ['查看黑名单', '创建黑名单', '更新黑名单', '删除黑名单'],
  // 实时查寝: ['查看实时查寝'],
  // 出入记录查看: ['查看人员出入记录', '查看陌生人出入记录'],
  晚归未归记录查看: [
    // '查看人员晚归记录',
    '查看人员未归记录',
  ],
  // 管理职位: ['查看职位', '创建职位', '更新职位', '删除职位'],
  管理部门: [
    // '查看部门',
    '创建部门',
    '更新部门',
    '删除部门',
  ],
  管理服务部门: ['创建服务部门', '更新服务部门', '删除服务部门'],
  管理楼层: [
    // '查看楼层',
    '创建楼层',
    '删除楼层',
    '更新楼层',
  ],
  管理房间: [
    // '查看房间',
    '创建房间',
    '删除房间',
  ],
  管理假期: [
    // '查看房间',
    '创建假期',
    '修改假期',
    '删除假期',
  ],
  管理请假: ['创建请假', '删除请假'],
  批量操作: [
    '批量上传底片',
    '批量创建人员',
    '批量删除人员',
    '批量删除房间',
    '批量删除楼层',
  ],
  统计表格管理: [
    '删除统计部门',
    '更新统计部门',
    '创建统计部门',
    '删除统计服务部门',
    '创建统计服务部门',
  ],
};

export const base: any = {
  管理人员: [],
  管理黑名单: [],
  // 实时查寝: [],
  // 出入记录查看: [],
  晚归未归记录查看: [],
  // 管理职位: [],
  管理部门: [],
  管理房间: [],
  管理楼层: [],
  管理假期: [],
  管理请假: [],
  批量操作: [],
  管理服务部门: [],
  统计表格管理: [],
};

export function findGroup(name: string) {
  const keys = Object.keys(permission);
  const group: any = keys.find((key) => {
    return permission[key].find((t: any) => t === name);
  });
  return group;
}

export const filter_roles = ['super_admin', 'back_admin'];
// export const filter_roles = ['super_admin', 'admin', 'back_admin']
