import React from 'react';
import { DeleteCameraModal } from './delete-camera';
import { Row, Col } from 'antd';
import moment from 'moment';
import { ChangeCameraModalForm } from './update-camera';

export const columns = (refetch?: any) => [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: '楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 80,
  },
  {
    title: 'ip 地址',
    dataIndex: 'camera_address',
    key: 'camera_address',
    width: 120,
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    width: 120,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <ChangeCameraModalForm item={record} refetch={refetch} />
          <DeleteCameraModal id={record.key} refetch={refetch}/>
        </Col>
      </Row>
    ),
  },
];
