import React from 'react';
import moment from 'moment';
import { api_uri } from '../../config';

export const columns = (filter?: any) => [
  {
    title: '照片',
    dataIndex: 'photo',
    key: 'photo',
    width: 80,
    // render: (text: string, record: any) =>
    //   text && <img style={{ width: 50, height: 50 }} src={text} />,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="照片"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/photo/' + text}
        />
      ),
  },
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    width: 80,
    // search: true,
    render: (text: string, record: any) => (
      <a href={`/people-detail?id=${record.people && record.people.id}`}>
        {text}
      </a>
    ),
  },
  {
    title: '部门',
    dataIndex: 'father_department',
    key: 'father_department',
    // search: true,
    width: 80,
    filters: filter && filter.structures,
  },
  {
    title: '分部门',
    dataIndex: 'department',
    key: 'department',
    // search: true,
    width: 100,
  },
  {
    title: '楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
    filters: filter && filter.dorms,
  },
  {
    title: '房间号',
    dataIndex: 'room_number',
    key: 'room_number',
    width: 120,
    render: (text: string, record: any) =>
      record.people && record.people.room_number,
  },
  {
    title: '床号',
    dataIndex: 'bed_number',
    key: 'bed_number',
    width: 120,
    render: (text: string, record: any) =>
      record.people && record.people.bed_number,
  },
  {
    title: '时间',
    dataIndex: 'date',
    key: 'date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '备注',
    dataIndex: 'note',
    key: 'note',
    width: 120,
    render: (text: string) => text,
  },
  {
    title: '操作',
    width: 60,
    key: 'action',
  },
];
