import React from 'react';
import { Button, Icon, Modal, Form, Input, DatePicker } from 'antd';
import moment from 'moment';

import { UpdateTimingComponent } from '../../apollo';
import { message } from '../../components/message';

class ChangeTimingModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateTiming: {
                ...values,
                id: item.id,
                start_date: moment(values.start_date).format('YYYY-MM-DD'),
                end_date: moment(values.end_date).format('YYYY-MM-DD'),
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form, item } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div>
        <Icon 
          style={{ color: 'rgb(37, 147, 252)' }}
          onClick={() => this.setState({ visible: true })} 
          type="edit" />
        <Modal
          title="修改信息"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
          <Form.Item label="名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入名称!' }],
                initialValue: item.name,
              })(<Input placeholder="名称" />)}
            </Form.Item>
            <Form.Item label="开始时间">
              {getFieldDecorator('start_date', {
                rules: [{ required: true, message: '请选择开始时间!' }],
                initialValue: moment(item.start_date, 'YYYY/MM/DD'),
              })(<DatePicker format={'YYYY/MM/DD'} />)}
            </Form.Item>
            <Form.Item label="结束时间">
              {getFieldDecorator('end_date', {
                rules: [{ required: true, message: '请选择结束时间!' }],
                initialValue: moment(item.end_date, 'YYYY/MM/DD'),
              })(<DatePicker format={'YYYY/MM/DD'} />)}
            </Form.Item>
            <Form.Item>
              <UpdateTimingComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdateTimingComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeTimingModalForm: any = Form.create({
  name: 'change_instructor',
})(ChangeTimingModal);
