import React from 'react';

import styles from './index.module.css';
import { websocket } from './ws';
import { BaseLayout } from '../../layout/base-layout';

export class OnLine extends React.Component<any, any> {
  state = {
    online_users: [],
  };

  componentDidMount() {
    websocket(result => {
      // console.log('result', result);
      const data = JSON.parse(result.data);
      console.log('data', data);
      if (data && data.type === 'recognized') {
        console.log('recognized.data', data);
        const new_online_users = [
          { image: data.data.person.avatar, name: data.data.person.name },
          ...this.state.online_users,
        ];
        this.setState({
          online_users: new_online_users,
        });
      } else if (data.data && data.data.image) {
        console.log('data.image', data.data.image);
        const new_online_users = [
          { image: data.data.image },
          ...this.state.online_users,
        ];
        this.setState({
          online_users: new_online_users,
        });
      }
    });
  }

  render() {
    const { online_users } = this.state;
    return (
      <BaseLayout
        contentStyles={{ background: 'rgb(240, 242, 245)', padding: '0px' }}
      >
        <div className={styles.container}>
          {online_users.map((online_user: any) => {
            return (
              <img
                key={online_user.image}
                alt="照片"
                height="100"
                width="100"
                src={
                  online_user.name
                    ? online_user.image
                    : `data:image/png;base64,${online_user.image}`
                }
              />
            );
          })}
        </div>
      </BaseLayout>
    );
  }
}
