import React from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  InputNumber,
} from 'antd';
import validator from 'validator';

import { CreateHousemasterComponent } from '../../apollo';
import { message } from '../../components/message';
import { isPhoneNumber } from '../../lib';

class AddHousemasterModal extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateHousemaster: { ...values },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          新增宿舍管理员
        </Button>
        <Modal
          title="新增宿舍管理员"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="真实姓名">
                  {getFieldDecorator('true_name', {
                    rules: [{ required: true, message: '请输入真实姓名!' }],
                  })(<Input style={{ width: 120 }} placeholder="真实姓名" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="职工号">
                  {getFieldDecorator('job_number', {
                    rules: [
                      { required: true, message: '请输入职工号!' },
                      { len: 8, message: '职工号必须是8位!' },
                    ],
                  })(<Input style={{ width: 120 }} placeholder="职工号" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="宿舍楼号">
                  {getFieldDecorator('dorm_number', {
                    rules: [{ required: true, message: '请输入宿舍楼号!' }],
                  })(
                    <InputNumber
                      style={{ width: 120 }}
                      placeholder="宿舍楼号"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="手机号">
              {getFieldDecorator('mobile', {
                validateFirst: true,
                rules: [
                  { required: true, message: '请输入手机号!' },
                  {
                    validator: (_: any, value: any, callback: any) => {
                      // if (value && validator.isMobilePhone(value, 'zh-CN')) {
                      if (value && isPhoneNumber(value)) {
                        callback();
                      } else {
                        callback(new Error('请输入正确的手机号'));
                      }
                    },
                  },
                ],
              })(<Input placeholder="手机号" />)}
            </Form.Item>

            <Form.Item label="入职时间">
              {getFieldDecorator('start_date', {
                rules: [{ required: true, message: '请选择入职时间!' }],
              })(<DatePicker placeholder="入职时间" />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea autosize={{ minRows: 5 }} placeholder="备注" />,
              )}
            </Form.Item>
            <Form.Item>
              <CreateHousemasterComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateHousemasterComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddHousemasterModalForm: any = Form.create({
  name: 'create_housemaster',
})(AddHousemasterModal);
