import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { AxiosProvider } from 'react-axios';

import './App.css';
import Routers from './router';
import client from './apollo/client';
import { api } from './request';

const App: React.FC = () => {
  return (
    <AxiosProvider instance={api}>
      <ApolloProvider client={client}>
        <Routers />
      </ApolloProvider>
    </AxiosProvider>
  );
};

export default App;
