import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './private-route';
// import { LocationListener } from './LocationListener';
import Index from '../pages/Index';
import Login from '../pages/login';
import User from '../pages/user';
import Dorm from '../pages/dorm';
import { Roles } from '../pages/role/roles';
import { Accounts } from '../pages/role/accounts';
import { OnLine } from '../pages/online';
import StrangerRecord from '../pages/stranger-record';
import { RealTime } from '../pages/real-time';
import Housemaster from '../pages/housemaster';
import Department from '../pages/department';
import Job from '../pages/job';
import LateReturnRecord from '../pages/late-return';
import { RuleSettingForm } from '../pages/rule-setting';
import NoReturnRecord from '../pages/no-return';
import NoOutRecord from '../pages/no-out';
import Blacklist from '../pages/blacklist';
import WrappedNormalChangePasswordForm from '../pages/change-password';
import People from '../pages/people';
import PeopleStatus from '../pages/people-status';
import PeopleOnline from '../pages/people-online';
import { Structure } from '../pages/structure';
import { PeopleDetail } from '../pages/people-detail';
import PeopleRecord from '../pages/people-record';
import Rooms from '../pages/room';
import RoomsExport from '../pages/room/room-export';
import ShiftExport from '../pages/room/shift-export';
import Cameras from '../pages/camera';
import Dailies from '../pages/daily';
import DailiesExport from '../pages/daily/daily-export';
import { DataV } from '../pages/datav';
import { DataV1 } from '../pages/datav1';
import { DataV2 } from '../pages/datav2';
import { DataV3 } from '../pages/datav3';
import Floors from '../pages/floor';
import Timing from '../pages/timing';
import Leave from '../pages/leave';
import ThreeDay from '../pages/three-day';
import Screens from '../pages/screen';
import { Fashion } from '../pages/fashion';
import Company from '../pages/company';
import Unit from '../pages/unit';
import MonthExport from '../pages/mouth/daily-export';

class Routers extends Component {
  render() {
    return (
      <Router>
        {/* <LocationListener> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/datav" component={DataV} />
        <Route exact path="/datav1" component={DataV1} />
        <Route exact path="/datav2" component={DataV2} />
        <Route exact path="/datav3" component={DataV3} />
        <PrivateRoute exact path="/" component={Index} />
        <PrivateRoute
          exact
          path="/change-password"
          component={WrappedNormalChangePasswordForm}
        />
        <PrivateRoute exact path="/daily" component={Dailies} />
        <PrivateRoute exact path="/daily-export" component={DailiesExport} />
        <PrivateRoute exact path="/month-export" component={MonthExport} />
        <PrivateRoute exact path="/user" component={User} />
        <PrivateRoute exact path="/people" component={People} />
        <PrivateRoute exact path="/leave" component={Leave} />
        {/* <PrivateRoute exact path="/student-online" component={StudentOnline} /> */}
        <PrivateRoute exact path="/people-detail" component={PeopleDetail} />
        <PrivateRoute exact path="/housemaster" component={Housemaster} />
        <PrivateRoute exact path="/blacklist" component={Blacklist} />
        <PrivateRoute exact path="/real-time" component={RealTime} />
        <PrivateRoute exact path="/people-record" component={PeopleRecord} />
        <PrivateRoute exact path="/late-return" component={LateReturnRecord} />
        <PrivateRoute exact path="/no-return" component={NoReturnRecord} />
        <PrivateRoute exact path="/three-day" component={ThreeDay} />
        <PrivateRoute exact path="/no-out" component={NoOutRecord} />
        <PrivateRoute exact path="/department" component={Department} />
        <PrivateRoute exact path="/screen" component={Screens} />
        <PrivateRoute exact path="/structure" component={Structure} />
        <PrivateRoute exact path="/job" component={Job} />
        <PrivateRoute exact path="/fashion" component={Fashion} />
        <PrivateRoute
          exact
          path="/stranger-record"
          component={StrangerRecord}
        />
        <PrivateRoute exact path="/people-status" component={PeopleStatus} />
        <PrivateRoute exact path="/people-online" component={PeopleOnline} />
        <PrivateRoute exact path="/dorm" component={Dorm} />
        <PrivateRoute exact path="/camera" component={Cameras} />
        <PrivateRoute exact path="/room" component={Rooms} />
        <PrivateRoute exact path="/room-export" component={RoomsExport} />
        <PrivateRoute exact path="/shift-export" component={ShiftExport} />
        <PrivateRoute exact path="/floor" component={Floors} />
        <PrivateRoute exact path="/role" component={Roles} />
        {/* <PrivateRoute exact path="/permission" component={Accounts} /> */}
        <PrivateRoute exact path="/admin-people" component={Accounts} />
        <PrivateRoute exact path="/online" component={OnLine} />
        <PrivateRoute exact path="/rule-setting" component={RuleSettingForm} />
        <PrivateRoute exact path="/timing" component={Timing} />
        <PrivateRoute exact path="/company-manage" component={Company} />
        <PrivateRoute exact path="/department-manage" component={Unit} />
        {/* <PrivateRoute exact path="/company" component={Company} /> */}
        {/* </LocationListener> */}
      </Router>
    );
  }
}

export default Routers;
