import React from 'react';
import {
  Button,
  Icon,
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  DatePicker,
  Radio,
  TreeSelect,
  Select,
} from 'antd';

import moment from 'moment';

import {
  UpdatePeopleComponent,
  StructuresTreeComponent,
  ScreenListChooseComponent,
} from '../../apollo';
import { message } from '../../components/message';
import { getData } from './create-people';

const { Option } = Select;

export function getNumber(name: string, old_data: any) {
  const data = old_data['structures'].list.find((structure: any) => {
    return structure.name === name;
  });
  if (data) {
    return data.number;
  }
  return null;
}

class ChangePeopleModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdatePeople: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        {this.props.button ? (
          <Button
            type="primary"
            onClick={() => this.setState({ visible: true })}
          >
            修改信息
          </Button>
        ) : (
          <Icon
            style={{ color: 'rgb(37, 147, 252)' }}
            onClick={() => this.setState({ visible: true })}
            type="edit"
          />
        )}
        <Modal
          title="修改人员信息"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <Row gutter={16}>
              <Col className="gutter-row" span={9}>
                <Form.Item label="真实姓名">
                  {getFieldDecorator('true_name', {
                    rules: [{ required: true, message: '请输入真实姓名!' }],
                    initialValue: this.props.item.true_name,
                  })(<Input placeholder="真实姓名" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={7}>
                <Form.Item label="性别">
                  {getFieldDecorator('sex', {
                    rules: [{ required: true, message: '请输入性别!' }],
                    initialValue: this.props.item.sex,
                  })(
                    <Radio.Group>
                      <Radio value={'男'}>男</Radio>
                      <Radio value={'女'}>女</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="入住时间">
                  {getFieldDecorator('start_date', {
                    // rules: [{ required: false, message: '请选择入住时间!' }],
                    initialValue:
                      this.props.item.start_date &&
                      moment(this.props.item.start_date),
                  })(<DatePicker placeholder="入住时间" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="手机号">
                  {getFieldDecorator('mobile', {
                    rules: [
                      // { required: false, message: '请输入手机号!' },
                      // {
                      //   validator: (_: any, value: any, callback: any) => {
                      //     if (value && isPhoneNumber(value)) {
                      //       callback();
                      //     } else {
                      //       callback(new Error('请输入正确的手机号'));
                      //     }
                      //   },
                      // },
                    ],
                    initialValue: this.props.item.mobile,
                  })(<Input placeholder="手机号" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="卡号">
                  {getFieldDecorator('room_card_number', {
                    rules: [{ required: false, message: '请输入卡号!' }],
                    initialValue: this.props.item.room_card_number,
                  })(<Input placeholder="卡号" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="人员类型">
                  {getFieldDecorator('type', {
                    rules: [{ required: true, message: '请选择人员类型!' }],
                    initialValue: this.props.item.type || '入住人员',
                  })(
                    <Select placeholder="人员类型">
                      <Option value="入住人员">入住人员</Option>
                      <Option value="内部员工">内部员工</Option>
                      <Option value="其他">其他</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="身份证号">
              {getFieldDecorator('id_card', {
                rules: [
                  { required: true, message: '请输入身份证号!' },
                  { len: 18, message: '身份证号必须是18位!' },
                ],
                initialValue: this.props.item.id_card,
              })(<Input placeholder="身份证号" />)}
            </Form.Item>
            <StructuresTreeComponent fetchPolicy="no-cache">
              {({ data, error, refetch, loading }) => {
                if (error) {
                  return <>Access denied</>;
                }
                if (loading || !data) {
                  return '';
                }
                const new_data = getData(data);
                return (
                  <Form.Item label="部门">
                    {getFieldDecorator('department', {
                      rules: [{ required: true, message: '请选择部门!' }],
                      initialValue: getNumber(this.props.item.department, data),
                    })(
                      <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: 'auto',
                        }}
                        treeData={new_data}
                        placeholder="请选择部门"
                        treeDefaultExpandAll
                      />,
                    )}
                  </Form.Item>
                );
              }}
            </StructuresTreeComponent>
            <ScreenListChooseComponent fetchPolicy="no-cache">
              {({ data, error, refetch, loading }) => {
                if (error) {
                  return <>Access denied</>;
                }
                if (loading || !data) {
                  return '';
                }
                const list_choose = Array.from(
                  new Set(data.screen_list_choose.map(({ name }) => name)),
                );
                return (
                  <Form.Item label="服务部门">
                    {getFieldDecorator('to_department', {
                      // rules: [{ required: true, message: '请选择服务部门!' }],
                      initialValue: this.props.item.to_department,
                    })(
                      <Select placeholder="服务部门">
                        {list_choose.map(name => {
                          return (
                            <Option key={name} value={name}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>,
                    )}
                  </Form.Item>
                );
              }}
            </ScreenListChooseComponent>
            {/* <Form.Item label="服务部门">
              {getFieldDecorator('to_department', {
                rules: [{ required: true, message: '请输入服务部门!' }],
                initialValue: this.props.item.to_department,
              })(<Input placeholder="服务部门" />)}
            </Form.Item> */}
            {/* <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="部门">
                  {getFieldDecorator('department', {
                    rules: [{ required: true, message: '请选择部门!' }],
                    initialValue: departments[0],
                  })(
                    <Select style={{ width: 180 }}>
                      {departments.map(department => (
                        <Option key={department}>{department}</Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={16}>
                <Form.Item label="分部门">
                  {getFieldDecorator('department_detail', {
                    rules: [{ required: true, message: '请输入分部门!' }],
                    initialValue: majors[0],
                  })(<Input placeholder="分部门" />)}
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="宿舍楼号">
                  {getFieldDecorator('dorm_number', {
                    rules: [{ required: true, message: '请输入宿舍楼号!' }],
                    initialValue: this.props.item.dorm_number,
                  })(
                    <InputNumber
                      style={{ width: 180 }}
                      placeholder="宿舍楼号"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="宿舍号">
                  {getFieldDecorator('room_number', {
                    rules: [{ required: true, message: '请输入宿舍号!' }],
                    initialValue: this.props.item.room_number,
                  })(
                    <InputNumber style={{ width: 180 }} placeholder="宿舍号" />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                {/* <Form.Item label="楼层号">
                  {getFieldDecorator('floor_number', {
                    rules: [{ required: true, message: '请输入楼层号!' }],
                  })(
                    <InputNumber style={{ width: 180 }} placeholder="楼层号" />,
                  )}
                </Form.Item> */}
                <Form.Item label="床号">
                  {getFieldDecorator('bed_number', {
                    rules: [{ required: true, message: '请输入床号!' }],
                    initialValue: this.props.item.bed_number,
                  })(<InputNumber style={{ width: 180 }} placeholder="床号" />)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ marginTop: '12px' }}>
              <UpdatePeopleComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdatePeopleComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangePeopleModalForm: any = Form.create({
  name: 'change_student',
})(ChangePeopleModal);
