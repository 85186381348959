import React from 'react';
import { Chart, Geom, Axis, Tooltip, Label } from 'bizcharts';

import styles from './index.module.css';

interface DataItem {
  name: string;
  count: number;
}

interface ChartThirdProps {
  data: DataItem[];
  bottom: string;
}

const cols = {
  count: {
    tickInterval: 100,
  },
};

export class ChartThird extends React.Component<ChartThirdProps> {
  render() {
    const { data, bottom } = this.props;
    return (
      <div
        style={{
          position: 'relative',
          paddingTop: '80px',
          marginLeft: '-20px',
          paddingRight: '10px',
        }}
      >
        <Chart height={200} data={data} scale={cols} forceFit>
          <Axis name="name" />
          <Axis name="count" />
          {/* <Axis
            name="count"
            label={{
              formatter: (val: any) => {
                return (val / 1000).toFixed(1) + 'k';
              },
            }}
          /> */}
          <Tooltip
            crosshairs={{
              type: 'y',
            }}
            itemTpl="<li><span></span>人数: {value}</li>"
          />
          <Geom type="interval" position="name*count">
            <Label
              content={['total', value => value]}
              // formatter={(text, item, index) => text}
              htmlTemplate={(text, item) => {
                return `<span style="color: #FFFFFF;">${text}</span>`;
              }}
            />
          </Geom>
        </Chart>
        {/* <div className={styles.bottom}>{bottom}</div> */}
      </div>
    );
  }
}
