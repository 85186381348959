import React from 'react';
import { Button, Icon, Modal, Form, Input, Select, Radio, InputNumber } from 'antd';

import { UpdateScreenComponent, StructureListComponent } from '../../apollo';
import { message } from '../../components/message';

const { Option } = Select;

class ChangeScreenModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateScreen: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        {this.props.button ? (
          <Button
            type="primary"
            onClick={() => this.setState({ visible: true })}
          >
            修改信息
          </Button>
        ) : (
          <Icon
            style={{ color: 'rgb(37, 147, 252)' }}
            onClick={() => this.setState({ visible: true })}
            type="edit"
          />
        )}
        <Modal
          title="修改楼层信息"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <StructureListComponent fetchPolicy="no-cache">
              {({ data, error, refetch, loading }) => {
                if (error) {
                  return <>Access denied</>;
                }
                if (loading || !data) {
                  return '';
                }
                return (
                  <Form.Item label="所属单位">
                    {getFieldDecorator('structure_number', {
                      rules: [{ required: true, message: '请选择所属单位!' }],
                      initialValue: this.props.item.structure_number,
                    })(
                      <Select placeholder="所属单位">
                        {data.structure_list.map(({ id, name, number }) => {
                          return (
                            <Option key={id} value={number}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>,
                    )}
                  </Form.Item>
                );
              }}
            </StructureListComponent>
            <Form.Item label="管理部门">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入管理部门!' }],
                initialValue: this.props.item.name,
              })(<Input placeholder="管理部门" />)}
            </Form.Item>
            <Form.Item label="是否在大屏显示">
              {getFieldDecorator('is_show', {
                rules: [{ required: true, message: '请选择是否在大屏显示!' }],
                initialValue: this.props.item.is_show,
              })(
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item label="房间数量">
              {getFieldDecorator('room_count', {
                rules: [{ required: true, message: '请输入房间数量!' }],
                initialValue: this.props.item.room_count,
              })(
                <InputNumber
                  style={{ width: '120px' }}
                  placeholder="房间数量"
                />,
              )}
            </Form.Item>
            <Form.Item label="排序">
              {getFieldDecorator('sort_count', {
                rules: [{ required: true, message: '请输入排序!' }],
                initialValue: this.props.item.sort_count,
              })(<InputNumber style={{ width: '120px' }} placeholder="排序" />)}
            </Form.Item>
            <Form.Item style={{ marginTop: '12px' }}>
              <UpdateScreenComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      修改
                    </Button>
                  );
                }}
              </UpdateScreenComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeScreenModalForm: any = Form.create({
  name: 'change_screen',
})(ChangeScreenModal);
