import React from 'react';
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts';
import DataSet from '@antv/data-set';

import styles from './index.module.css';

export class SecondChart extends React.Component<any> {
  render() {
    const { DataView } = DataSet;
    const dv = new DataView();
    dv.source(this.props.data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent',
      // type: 'reverse',
    });
    const cols = {
      percent: {
        formatter: (val: any) => {
          // val = val * 100 + '%';
          return val;
        },
      },
    };
    return (
      <div style={{ position: 'relative', paddingTop: '50px' }}>
        <Chart
          // height={window.innerHeight}
          data={dv}
          scale={cols}
          height={300}
          // padding={[80, 100, 80, 80]}
          forceFit
          className={styles.chart}
        >
          <Coord type="theta" radius={0.75} />
          {/* <Axis name="percent" /> */}
          {/* <Legend
            position="right"
            offsetY={-window.innerHeight / 2 + 120}
            offsetX={-100}
          /> */}
          {/* <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          /> */}
          <Geom
            type="intervalStack"
            position="percent"
            color="item"
            tooltip={[
              'item*percent',
              (item, percent) => {
                percent = percent * 100 + '%';
                return {
                  name: item,
                  value: percent,
                };
              },
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff',
            }}
          >
            <Label
              content="percent"
              // formatter={(val, item) => {
              //   const count = this.props.data.find(
              //     ({ item: old_item }: any) => item.point.item === old_item,
              //   );
              //   return item.point.item + ': ' + count.count;
              // }}
              htmlTemplate={(text, item) => {
                const count = this.props.data.find(
                  ({ item: old_item }: any) => item.point.item === old_item,
                );
                return `<span style="color: #FFFFFF; font-size: 16px; width: 80px; display: block;">${item
                  .point.item +
                  ': ' +
                  count.count}</span>`;
              }}
            />
          </Geom>
        </Chart>
      </div>
    );
  }
}
