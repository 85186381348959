import React from 'react';
import { Layout, Menu, Icon, Dropdown, Button } from 'antd';
import parse from 'url-parse';
import queryString from 'query-string';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { CatModal } from './cat-modal';
import boy from '../pages/Index/boy@2x.png';
import { ViewerComponent } from '../apollo';
import { hasPermission } from './permissions.db';
import styles from './layout.module.css';
import './base-layout.css';
import logo from '../images/logo.png';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
interface props {
  location: Location;
  history: any;
}

const opens = {
  people: ['/people', '/housemaster', '/blacklist', '/leave'],
  record: [
    '/people-record',
    '/student-record',
    '/teacher-record',
    '/stranger-record',
  ],
  safe: [
    '/dorm-data',
    '/people-status',
    '/people-online',
    '/real-time',
    '/late-return',
    '/no-return',
    '/three-day',
  ],
  warn: ['/rule-setting', '/warn-record'],
  department: ['/structure', '/screen'],
  point: ['/room', '/floor', '/dorm', '/device', '/camera'],
  setting: [
    '/department',
    '/job',
    '/role',
    '/permission',
    '/admin-people',
    '/timing',
  ],
  daily: [
    '/daily',
    '/daily-export',
    '/month-export',
    '/company-manage',
    '/department-manage',
  ],
};

const super_permissions = ['super_admin'];

function getKey(key: string) {
  // @ts-ignore
  return Object.keys(opens).find((item) => opens[item].includes(key));
}

class BaseLayoutComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const pathname = this.props.location.pathname;
    this.state = {
      collapsed: false,
      openKeys: [getKey(pathname)],
    };
  }

  toggle = () => {
    const pathname = this.props.location.pathname;
    this.setState({
      collapsed: !this.state.collapsed,
      openKeys: this.state.collapsed ? [getKey(pathname)] : [],
    });
  };

  rootSubmenuKeys = Object.keys(opens);

  onClick = ({ key }: { key: string }) => {
    const url: any = parse(key);
    const params = queryString.parse(url.query);
    const params2 = queryString.parse(this.props.location.search);
    const params3 = queryString.stringify({
      ...params2,
      ...params,
    });
    this.props.history.push(url.pathname + '?' + params3);
  };

  onOpenChange = (openKeys: any[]) => {
    const latestOpenKey = openKeys.find(
      // @ts-ignore
      (key: any) => this.state.openKeys.indexOf(key) === -1,
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    const { openKeys } = this.state;
    return (
      <ViewerComponent>
        {({ data }) => {
          if (!data || !data.viewer) {
            return '';
          }
          const permissions = data.viewer.permissions;
          return (
            <Layout id="components-layout-custom-trigger">
              <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
              >
                <div className="logo">
                  {/* <div
                    style={{
                      color: '#FFFFFF',
                      fontSize: '16px',
                      textAlign: 'center',
                      paddingTop: "4px"
                    }}
                  >
                    公寓管理系统
                  </div> */}
                  <img src={logo} className={styles.logo} />
                </div>
                <Menu
                  theme="dark"
                  mode="inline"
                  openKeys={openKeys}
                  onClick={this.onClick}
                  defaultSelectedKeys={[this.props.location.pathname]}
                  onOpenChange={this.onOpenChange}
                >
                  <Menu.Item key="/">
                    <Icon type="dashboard" />
                    <span>首页</span>
                  </Menu.Item>
                  {hasPermission(super_permissions, permissions) && (
                    <Menu.Item key="/user">
                      <Icon type="user" />
                      <span>用户管理</span>
                    </Menu.Item>
                  )}
                  {/* <Menu.Item key="/daily">
                    <Icon type="book" />
                    <span>日报管理</span>
                  </Menu.Item> */}
                  <SubMenu
                    key="daily"
                    title={
                      <span>
                        <Icon type="book" />
                        <span>日报管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/daily">
                      <span>日报</span>
                    </Menu.Item>
                    <Menu.Item key="/daily-export">
                      <span>部门日报</span>
                    </Menu.Item>
                    <Menu.Item key="/month-export">
                      <span>部门月报</span>
                    </Menu.Item>
                    <Menu.Item key="/company-manage">
                      <span>单位管理</span>
                    </Menu.Item>
                    <Menu.Item key="/department-manage">
                      <span>部门管理</span>
                    </Menu.Item>
                  </SubMenu>
                  {/* <Menu.Item key="/daily">
                    <Icon type="book" />
                    <span>日报管理</span>
                  </Menu.Item> */}
                  <SubMenu
                    key="people"
                    title={
                      <span>
                        <Icon type="user" />
                        <span>人员管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/people">
                      <span>人员管理</span>
                    </Menu.Item>
                    <Menu.Item key="/leave">
                      <span>请假管理</span>
                    </Menu.Item>
                    {/* <Menu.Item key="/housemaster">
                          <span>宿舍管理员管理</span>
                        </Menu.Item> */}
                    <Menu.Item key="/blacklist">
                      <span>黑名单管理</span>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="record"
                    title={
                      <span>
                        <Icon type="radar-chart" />
                        <span>出入记录</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/people-record">
                      <span>出入记录</span>
                    </Menu.Item>

                    <Menu.Item key="/stranger-record">
                      <span>陌生人记录</span>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="safe"
                    title={
                      <span>
                        <Icon type="safety-certificate" />
                        <span>安全管理</span>
                      </span>
                    }
                  >
                    {/* <Menu.Item key="/dorm-data">
                  <span>宿舍楼数据汇总</span>
                </Menu.Item> */}
                    <Menu.Item key="/real-time">
                      <span>实时查寝</span>
                    </Menu.Item>
                    <Menu.Item key="/people-online">
                      <span>实时在寝记录</span>
                    </Menu.Item>
                    <Menu.Item key="/people-status">
                      <span>历史在寝记录</span>
                    </Menu.Item>
                    {/* <Menu.Item key="/late-return">
                      <span>晚归记录</span>
                    </Menu.Item> */}
                    <Menu.Item key="/no-return">
                      <span>未归记录</span>
                    </Menu.Item>
                    <Menu.Item key="/three-day">
                      <span>三日未归</span>
                    </Menu.Item>
                  </SubMenu>
                  {/* {hasPermission(warn_show, permissions) && (
                    <SubMenu
                      key="warn"
                      title={
                        <span>
                          <Icon type="alert" />
                          <span>预警管理</span>
                        </span>
                      }
                    >
                      {hasPermission(rule_setting_permissions, permissions) && (
                        <Menu.Item key="/rule-setting">
                          <span>预警规则管理</span>
                        </Menu.Item>
                      )}
                      {hasPermission(warn_record_permissions, permissions) && (
                        <Menu.Item key="/warn-record">
                          <span>预警信息管理</span>
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )} */}
                  <SubMenu
                    key="department"
                    title={
                      <span>
                        <Icon type="highlight" />
                        <span>部门管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/structure">
                      <span>部门管理</span>
                    </Menu.Item>
                    <Menu.Item key="/screen">
                      <span>服务部门管理</span>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="point"
                    title={
                      <span>
                        <Icon type="bank" />
                        <span>资源管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/dorm">
                      <span>宿舍楼管理</span>
                    </Menu.Item>
                    <Menu.Item key="/floor">
                      <span>楼层管理</span>
                    </Menu.Item>
                    <Menu.Item key="/room">
                      <span>房间管理</span>
                    </Menu.Item>
                    {hasPermission(super_permissions, permissions) && (
                      <Menu.Item key="/camera">
                        <span>摄像头管理</span>
                      </Menu.Item>
                    )}
                    {/* <Menu.Item key="/device">
                  <span>设备信息管理</span>
                </Menu.Item> */}
                  </SubMenu>
                  <SubMenu
                    key="setting"
                    title={
                      <span>
                        <Icon type="setting" />
                        <span>系统管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/timing">
                      <span>假日管理</span>
                    </Menu.Item>
                    {/* <Menu.Item key="/department">
                      <span>部门管理</span>
                    </Menu.Item>
                    <Menu.Item key="/job">
                      <span>职位管理</span>
                    </Menu.Item> */}
                    {hasPermission(super_permissions, permissions) && (
                      <Menu.Item key="/role">
                        <span>角色管理</span>
                      </Menu.Item>
                    )}

                    {/* <Menu.Item key="/permission">
                  <span>权限管理</span>
                </Menu.Item> */}
                    {hasPermission(super_permissions, permissions) && (
                      <Menu.Item key="/admin-people">
                        <span>管理员管理</span>
                      </Menu.Item>
                    )}
                  </SubMenu>
                  {/* <Menu.Item key="7">
                <Icon type="edit" />
                <span>举报</span>
              </Menu.Item>
              <Menu.Item key="8">
                <Icon type="edit" />
                <span>反馈</span>
              </Menu.Item> */}
                </Menu>
              </Sider>
              <Layout>
                <Header
                  style={{
                    background: '#fff',
                    padding: 0,
                    position: 'relative',
                  }}
                >
                  <Icon
                    className="trigger"
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                  />
                  <div className="header_image" id="header_image">
                    <Dropdown
                      placement="bottomLeft"
                      overlay={
                        <Menu>
                          <Menu.Item key="5">
                            <Link to="/change-password">修改密码</Link>
                          </Menu.Item>
                          {/* <Menu.Item key="5">
                            <Link to="/person">账户信息</Link>
                          </Menu.Item> */}
                          <Menu.Item
                            key="6"
                            onClick={() => {
                              localStorage.session = null;
                              localStorage.token = null;
                              this.props.history.push('/login');
                            }}
                          >
                            <span>退出</span>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <span className="hand dropdown_text">
                        {data.viewer.username || '管理员'}
                        <img alt="头像" className="header_boy" src={boy} />
                      </span>
                    </Dropdown>
                  </div>
                </Header>
                <div className={styles.hover_link}>
                  <div className={styles.hover_empty}>
                    <div className={styles.link_animated}>
                      <CatModal />
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          width: '120px',
                          marginTop: '2px',
                          marginBottom: '2px',
                        }}
                      >
                        <Icon type="eye" style={{ color: '#fff' }} />
                        <Link to="/real-time">
                          <span
                            style={{
                              color: '#fff',
                              display: 'inline-block',
                              width: '80px',
                              textAlign: 'center',
                            }}
                          >
                            实时查寝
                          </span>
                        </Link>
                      </Button>
                      {/* <Button style={{ width: '120px' }} shape='round' type='primary'>
                        <Icon type='bar-chart' style={{ color: '#fff' }} />
                        <Link to='/fashion'><span style={{ color: '#fff', display: 'inline-block', width: '70px', textAlign: 'center' }}>统计</span></Link>
                      </Button> */}
                    </div>
                  </div>
                </div>
                <Content
                  style={{
                    margin: '24px 16px',
                    padding: 24,
                    background: '#fff',
                    minHeight: 280,
                    minWidth: 1000,
                    overFlow: 'auto',
                    ...this.props.contentStyles,
                  }}
                >
                  {this.props.children}
                </Content>
              </Layout>
            </Layout>
          );
        }}
      </ViewerComponent>
    );
  }
}

export const BaseLayout = withRouter(BaseLayoutComponent);
