import { message as AntMessage } from 'antd';

export const message = function() {};

message.success = function(value: string) {
  AntMessage.success(value);
};

message.info = function(value: string) {
  AntMessage.info(value);
};

message.error = function(e: Error) {
  AntMessage.error(
    e.message
      .replace('GraphQL error:', '')
      .replace('Forbidden resource', '无权限'),
  );
};
