import React from 'react';
import { Icon, Modal, Form, Button, Upload } from 'antd';

import { message } from '../../components/message';
import { file_multiple_props } from '../../lib/upload';

const { Dragger } = Upload;

class UploadHousemasterModal extends React.Component<any> {
  state = {
    visible: false,
    visible_upload: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateHousemaster: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false, uri: '' });

  render() {
    const { visible, visible_upload } = this.state;
    return (
      <div style={{ display: 'inline-block', marginRight: '12px' }}>
        <Button onClick={() => this.setState({ visible: true })} type="primary">
          上传底片
        </Button>
        <Modal
          title="上传底片"
          centered
          visible={visible}
          onCancel={() =>
            this.setState({ visible: false, visible_upload: false })
          }
          footer={null}
        >
          {visible && (
            <Dragger
              disabled={visible_upload}
              {...file_multiple_props('housemaster/upload')}
              onChange={() => {
                this.setState({ visible_upload: true });
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或者拖动到此处上传</p>
              <p className="ant-upload-hint">
                支持文件夹上传，通过文件名和学号匹配，如果学号相同则覆盖原有底片
              </p>
            </Dragger>
          )}
        </Modal>
      </div>
    );
  }
}

export const UploadHousemasterModalForm: any = Form.create({
  name: 'upload_housemaster',
})(UploadHousemasterModal);
