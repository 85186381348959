import React from 'react';
import moment from 'moment';
import { api_uri, megvii_uri } from '../../config';

export const dorms = [1, 2, 3, 4, 5, 6, 7, 8];

export const columns = (filter?: any) => [
  {
    title: '抓拍照片',
    dataIndex: 'megvii_image_uri',
    key: 'megvii_image_uri',
    width: 80,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="抓拍照片"
          style={{ width: 50, height: 50 }}
          src={megvii_uri + text}
        />
      ),
  },
  // {
  //   title: '抓拍照片',
  //   dataIndex: 'image_uri',
  //   key: 'image_uri',
  //   width: 80,
  //   render: (text: string, record: any) =>
  //     text && (
  //       <img
  //         alt="抓拍照片"
  //         style={{ width: 50, height: 50 }}
  //         src={api_uri + '/file/megvii-image/' + text}
  //       />
  //     ),
  //   // render: (text: string, record: any) =>
  //   //   text && (
  //   //     <img
  //   //       style={{ width: 50, height: 50 }}
  //   //       src={'data:image/jpeg;base64,' + text}
  //   //     />
  //   //   ),
  // },
  {
    title: '楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
    filters: filter && filter.dorms,
  },
  {
    title: '方向',
    dataIndex: 'direction',
    key: 'direction',
    width: 120,
    filters: [
      { text: '出口', value: '出口' },
      { text: '入口', value: '入口' },
    ],
  },
  {
    title: '时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    render: (text: string) => moment(text).format('YYYY年MM月DD日 HH:mm:ss'),
  },
];
