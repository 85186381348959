import React from 'react';
import { Button, Modal, Form, InputNumber, Row, Col, Upload, Icon } from 'antd';

import { CreateFloorComponent } from '../../apollo';
import { message } from '../../components/message';
import { file_props } from '../../lib/upload';

const { Dragger } = Upload;

class AddFloorModal extends React.Component<any> {
  state = {
    visible: false,
    visible_upload: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateFloor: {
                ...values,
                uri: this.state.uri,
              },
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible, visible_upload, uri } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          新增楼层
        </Button>
        <Modal
          title="新增楼层"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={680}
        >
          <Form>
            <Dragger
              disabled={visible_upload}
              {...file_props('floor/upload')}
              onChange={(info: any) => {
                const { status, response, name } = info.file;
                if (status === 'done') {
                  message.success('上传成功');
                  this.setState({ uri: response.file.filename });
                } else if (status === 'error') {
                  if (
                    response.message &&
                    (response.message.includes('Access denied') ||
                      response.message.includes('Forbidden resource'))
                  ) {
                    message.error(new Error(`无权限`));
                  } else {
                    message.error(new Error(`${name} 上传失败.`));
                  }
                }
                this.setState({ visible_upload: true });
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或者拖动到此处上传</p>
              <p className="ant-upload-hint">请上传 jpg,jpeg,png 格式</p>
            </Dragger>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label="宿舍楼号">
                  {getFieldDecorator('dorm_number', {
                    rules: [{ required: true, message: '请输入宿舍楼号!' }],
                  })(
                    <InputNumber
                      style={{ width: 220 }}
                      placeholder="宿舍楼号"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label="楼层">
                  {getFieldDecorator('number', {
                    rules: [{ required: true, message: '请输入楼层!' }],
                  })(<InputNumber style={{ width: 220 }} placeholder="楼层" />)}
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={8}>
                <Form.Item label="床号">
                  {getFieldDecorator('bed_number', {
                    rules: [{ required: false, message: '请输入床号!' }],
                  })(<InputNumber style={{ width: 180 }} placeholder="床号" />)}
                </Form.Item>
              </Col> */}
            </Row>
            <Form.Item style={{ marginTop: '12px' }}>
              <CreateFloorComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateFloorComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const AddFloorModalForm: any = Form.create({ name: 'create_Floor' })(
  AddFloorModal,
);
