import React from 'react';
import { Get } from 'react-axios';

import {
  Button,
  DatePicker,
  Icon,
  Popconfirm,
  Popover,
  Modal,
  Input,
} from 'antd';
import moment from 'moment';

import { BaseTable } from '../../components/base-table';

import { BaseLayout } from '../../layout/base-layout';

import styles from './index.module.css';
import { system_name } from '../../config';
import { api } from '../../request';
import AddItem from './add-item';

declare global {
  interface Window {
    MouseEvent: typeof MouseEvent;
  }
}

export default class Unit extends BaseTable {
  refetch: any;

  state = {
    date: '',
    query_params: {},
    edit: false,
    add: false,
    name: '',
    other_name: '',
    // edit: false,
  };

  delete = async (id: string, name: string) => {
    try {
      const center = await api.delete(`/statistics/department`, {
        data: { id, name },
      });
      if (this.refetch) {
        this.refetch();
      }
    } catch (e) {
      alert(e.message);
    }
  };

  add = async () => {
    try {
      const { name, other_name } = this.state;
      const center = await api.post(`/statistics/department`, {
        name,
        other_name,
      });
      if (this.refetch) {
        this.refetch();
      }
      this.setState({ name: '', other_name: '', add: false });
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    // const params = queryString.parse(this.props.location.search);
    const { edit, add, name, other_name } = this.state;
    // const { dailies_data } = this.state;
    const date =
      this.state.date ||
      moment(new Date(Date.now() - 1000 * 60 * 60 * 24)).format('YYYY-MM-DD');
    return (
      <BaseLayout>
        <Modal
          title="新增部门"
          centered
          visible={add}
          onCancel={() => this.setState({ add: false, name: '' })}
          footer={null}
        >
          <Input
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
            placeholder="名称"
          />
          <br />
          <br />
          <Input
            value={other_name}
            onChange={(e) => this.setState({ other_name: e.target.value })}
            placeholder="服务单位"
          />
          <br />
          <br />
          <Button
            type="primary"
            onClick={this.add}
            className="login-form-button"
          >
            增加
          </Button>
        </Modal>
        <Get url={`/statistics/daily/${date}`}>
          {(error: any, response: any, isLoading: any, makeRequest: any) => {
            this.refetch = makeRequest;
            if (error || isLoading || !response) {
              return '';
            }
            // console.log('response.data', response.data);
            let { daily, center, unit } = response.data;
            return (
              <div className="table_container">
                <div style={{ marginBottom: '20px' }}>
                  {edit ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          edit: false,
                        });
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      取消
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          edit: true,
                        });
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      编辑
                    </Button>
                  )}
                </div>
                <table id="table-to-xls" className={styles.daily_table}>
                  {/* <thead>
                    <tr>
                      <th className={styles.sec_title} colSpan={16}>
                        登记人员住宿情况表
                      </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <th className={styles.sub_title} colSpan={edit ? 17 : 16}>
                        按部门统计
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={3}>所属部门</td>
                      <td colSpan={4}>服务单位</td>
                      <td colSpan={3}>登记人数</td>
                      <td colSpan={3}>实住人数</td>
                      <td colSpan={3}>未住人数</td>
                      {edit && <td colSpan={3}>操作</td>}
                    </tr>
                    {center.map((center: any) => {
                      if (center.items) {
                        return center.items.map((item: any, index: any) => {
                          // if (item.name !== '合计') {
                          return (
                            <tr
                              key={item.name}
                              className={
                                item.name === '合计' ? styles.statistic : ''
                              }
                            >
                              {index === 0 && (
                                <td colSpan={3} rowSpan={center.items.length}>
                                  {center.name}
                                </td>
                              )}
                              <td colSpan={4}>{item.name}</td>
                              <td colSpan={2}>{item.count}</td>
                              <td>人</td>
                              <td colSpan={2}>{item.in_count}</td>
                              <td>人</td>
                              <td colSpan={2}>{item.out_count}</td>
                              <td>人</td>
                              {this.state.edit && item.name !== '合计' && (
                                <td>
                                  <Popconfirm
                                    title="是否确定删除?"
                                    onConfirm={() =>
                                      this.delete(center.id, item.name)
                                    }
                                    // onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消"
                                  >
                                    <Icon type="minus-circle" />
                                  </Popconfirm>

                                  {(index === center.items.length - 1 ||
                                    index === center.items.length - 2) && (
                                    // <Icon
                                    //   type="plus-circle"
                                    //   onClick={() =>
                                    //     this.setState({ add: true })
                                    //   }
                                    // />
                                    <AddItem
                                      id={center.id}
                                      refetch={makeRequest}
                                    />
                                  )}
                                </td>
                              )}
                            </tr>
                          );
                          // }
                        });
                      }
                      //  else {
                      //   return (
                      //     <tr key={center.name} className={styles.statistic}>
                      //       <td colSpan={7}>{center.name}</td>
                      //       <td colSpan={2}>{center.count}</td>
                      //       <td>人</td>
                      //       <td colSpan={2}>{center.in_count}</td>
                      //       <td>人</td>
                      //       <td colSpan={2}>{center.out_count}</td>
                      //       <td>人</td>
                      //     </tr>
                      //   );
                      // }
                    })}
                    {edit && (
                      <tr>
                        <th
                          className={styles.sub_title}
                          colSpan={edit ? 17 : 16}
                        >
                          <Icon
                            style={{ fontSize: '22px' }}
                            type="plus-circle"
                            onClick={() => this.setState({ add: true })}
                          />
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            );
          }}
        </Get>
      </BaseLayout>
    );
  }
}
