import React from 'react';

import queryString from 'query-string';

import {
  BaseTable,
  TableTitle,
  TableTimeSearch,
  OutSearch,
} from '../../components/base-table';
import {
  NoReturnRecordsComponent,
  OutNoReturnRecordsComponent,
} from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './no-return-record.columns';
import { CreateNoteModalForm } from './create-note';

function getData(data: any) {
  const list_data = data.noReturnRecords;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          const result = {
            ...item,
            key: item.id,
          };
          if (item.teacher) {
            return {
              ...item.teacher,
              ...result,
            };
          }
          if (item.student) {
            return {
              ...item.student,
              ...result,
            };
          }
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

export default class NoOutRecord extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
    },
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { start_date, end_date } = this.state;
    return (
      <BaseLayout>
        <NoReturnRecordsComponent
          variables={{
            skip,
            take: 10,
            role: '人员',
            status: '未出',
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            const result = getData(data);

            const new_columns = columns.map(item => {
              if (item.title === '操作') {
                return {
                  title: '操作',
                  width: 50,
                  key: 'action',
                  render: (_: string, record: any) => (
                    <CreateNoteModalForm
                      id={record.id}
                      note={record.note}
                      refetch={refetch}
                    />
                  ),
                };
              }
              return item;
            });
            return (
              <div className="table_container">
                <TableTitle name={`未出记录`} />
                <TableTimeSearch
                  start_date={start_date}
                  end_date={end_date}
                  close_start_time={true}
                  close_end_time={true}
                  onChange={(name, value) => this.setState({ [name]: value })}
                  reset={() =>
                    this.setState({
                      query_params: {
                        ...this.state.query_params,
                        start_date: null,
                        end_date: null,
                      },
                      start_date: null,
                      end_date: null,
                    })
                  }
                  search={() =>
                    this.setState({
                      query_params: {
                        ...this.state.query_params,
                        start_date,
                        end_date,
                      },
                    })
                  }
                >
                  <OutNoReturnRecordsComponent>
                    {(send, { loading }) => {
                      return (
                        <OutSearch
                          // style={{ marginTop: '60px' }}
                          options={columns_titles}
                          checked_options={this.state.checked_options}
                          onCheckAllChange={this.onCheckAllChange}
                          outData={send}
                          loading={loading}
                          columns={this.state.checked_options}
                          params={{
                            skip,
                            take: 10,
                            ...this.state.query_params,
                          }}
                        />
                      );
                    }}
                  </OutNoReturnRecordsComponent>
                </TableTimeSearch>
                {/* <TableSearch
                  style={{ marginTop: '60px' }}
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  // outData={send}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...this.state.query_params,
                  }}
                ></TableSearch> */}
                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={new_columns}
                  loading={data && data.noReturnRecords ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 10,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </NoReturnRecordsComponent>
      </BaseLayout>
    );
  }
}
