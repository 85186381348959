import React from 'react';
import { Button, Modal } from 'antd';

import { api_uri } from '../../config';
import ReactZmage from 'react-zmage';

export class CatModal extends React.Component<any> {
  state = {
    visible: false,
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { uri } = this.props;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button type="link" onClick={() => this.setState({ visible: true })}>
          平面图
        </Button>
        <Modal
          title="楼层图"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={1180}
        >
          {/* <img
            style={{ width: '1140px' }}
            src={api_uri + '/file/floor/' + uri}
          /> */}
          <ReactZmage
            // style={{ width: '1140px' }}
            src={api_uri + '/file/floor/' + uri}
          />
        </Modal>
      </div>
    );
  }
}
