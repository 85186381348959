import React from 'react';
import { Row, Col } from 'antd';
import { DeleteLeaveModal } from './delete-leave';

export const columns = (filter?: any, refetch?: any) => [
  {
    title: '姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    fixed: 'left',
    width: 80,
  },
  {
    title: '部门',
    dataIndex: 'father_department',
    key: 'father_department',
    width: 120,
    filters: filter && filter.structures,
    render: (text: string) => text || '无',
  },
  {
    title: '分部门',
    dataIndex: 'department',
    key: 'department',
    width: 120,
  },
  {
    title: '开始时间',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 120,
  },
  {
    title: '结束时间',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 120,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <DeleteLeaveModal id={record.key} refetch={refetch} />
        </Col>
      </Row>
    ),
  },
];
