import React from 'react';
import { Button, Icon, Modal, Form, Input, Upload } from 'antd';

import { UpdateBlacklistComponent } from '../../apollo';
import { message } from '../../components/message';
import { file_props } from '../../lib/upload';
import { api_uri } from '../../config';

class ChangeBlacklistModal extends React.Component<any> {
  state = {
    visible: false,
    uri: this.props.item.photo,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const { item, form, refetch } = this.props;
        try {
          await send({
            variables: {
              UpdateBlacklist: {
                ...values,
                id: item.id,
              },
            },
          });
          message.success('修改成功');
          const { resetFields } = form;
          resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => this.setState({ visible: false });

  render() {
    const { visible, uri } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    return (
      <div>
        <Icon onClick={() => this.setState({ visible: true })} type="edit" />
        <Modal
          title="修改黑名单"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item
              label="用户头像（⽀持拓展名: .jpg, .jpeg, .png）"
              style={{ marginBottom: '0px' }}
            >
              {getFieldDecorator('photo', {
                // getValueFromEvent: this.photoNormFile,
                rules: [
                  {
                    required: true,
                    message: '请上传文件',
                  },
                ],
                initialValue: this.state.uri,
              })(
                <Upload
                  {...file_props('blacklist/upload', uri =>
                    this.setState({
                      uri,
                    }),
                  )}
                  accept=".jpg,.jpeg,.png"
                  listType="picture-card"
                  showUploadList={false}
                  className="hand"
                  style={{ margin: '0 auto' }}
                >
                  {uri ? (
                    <img
                      style={{ width: '100%' }}
                      src={api_uri + '/file/blacklist/' + uri}
                      alt="avatar"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>,
              )}
            </Form.Item>
            <Form.Item label="姓名">
              {getFieldDecorator('true_name', {
                rules: [{ required: true, message: '请输入姓名!' }],
                initialValue: this.props.item.true_name,
              })(<Input placeholder="姓名" />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('remark', {
                initialValue: this.props.item.remark,
              })(
                <Input.TextArea autosize={{ minRows: 5 }} placeholder="备注" />,
              )}
            </Form.Item>
            <Form.Item>
              <UpdateBlacklistComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      确认
                    </Button>
                  );
                }}
              </UpdateBlacklistComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeBlacklistModalForm: any = Form.create({
  name: 'change_blacklist',
})(ChangeBlacklistModal);
