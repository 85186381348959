import React from 'react';
import { Chart, Geom, Axis, Tooltip, Coord, Label } from 'bizcharts';
import DataSet from '@antv/data-set';

import styles from './index.module.css';

interface DataItem {
  item: string;
  count: number;
}

interface ChartFirstProps {
  data: DataItem[];
}

export class ChartFirst extends React.Component<ChartFirstProps> {
  render() {
    const { data } = this.props;
    const { DataView } = DataSet;
    const dv = new DataView();
    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent',
    });
    const cols = {
      percent: {
        formatter: (val: any) => {
          val = Math.round(val * 100) + '%';
          return val;
        },
      },
    };
    return (
      <div style={{ position: 'relative', paddingTop: '70px' }}>
        <Chart
          height={300}
          data={dv}
          scale={cols}
          // padding={[30, 80, 30, 80]}
          forceFit
          className={styles.chart}
        >
          <Coord type={'theta'} radius={0.75} innerRadius={0.6} />
          <Axis name="percent" />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Geom
            type="intervalStack"
            position="percent"
            color="item"
            tooltip={[
              'item*percent',
              (item, percent) => {
                percent = Math.round(percent * 100) + '%';
                return {
                  name: item,
                  value: percent,
                };
              },
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff',
            }}
          >
            <Label
              content="percent"
              // formatter={(val, item) => {
              //   return item.point.item;
              //   // return item.point.item + ': ' + val;
              // }}
              htmlTemplate={(text, item) => {
                return `<span style="color: #FFFFFF; font-size: 16px; width: 80px; display: block;">${item.point.item}</span>`;
              }}
            />
          </Geom>
        </Chart>
      </div>
    );
  }
}
