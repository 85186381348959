import React from 'react';
import { Button, Modal, Icon } from 'antd';
import ReactZmage from 'react-zmage';

import building from './building.png';

export class CatModal extends React.Component<any> {
  state = {
    visible: false,
  };

  close = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    return (
      <div>
        <Button style={{ width: '120px' }} type='primary' shape='round' onClick={() => this.setState({ visible: true })}>
          <Icon type='picture' style={{ color: '#fff' }} />
          <span style={{ color: '#fff', display: 'inline-block', width: '70px', textAlign: 'center' }}>楼宇图</span>
        </Button>
        <Modal
          title="楼宇图"
          centered
          visible={visible}
          onCancel={this.close}
          footer={null}
          width={1180}
        >
          <ReactZmage
            // style={{ width: '1140px' }}
            src={building}
          />
          {/* <img
            style={{ width: '1140px' }}
            src={building}
          /> */}
        </Modal>
      </div>
    );
  }
}
