import React from 'react';

import styles from './index.module.css';

interface FloorProps {
  number: number;
  className: any;
}

export class Floor extends React.Component<FloorProps> {
  render() {
    const { number, className } = this.props;
    return (
      <div className={`${styles.box} ${styles.floor} ${className}`}>
        <div className={styles.number}>
          <span className={styles.number_bg}>{number}</span>
        </div>
        {/* <img className={styles.number_image} src={icon5} /> */}
        {this.props.children}
      </div>
    );
  }
}

interface FloorNumberProps {
  people_count: number;
  people_in_count: number;
}

export class FloorNumber extends React.Component<FloorNumberProps> {
  render() {
    const { people_count, people_in_count } = this.props;
    return (
      <div style={{ marginLeft: '6px' }}>
        {people_in_count.toLocaleString()}/{people_count.toLocaleString()}
      </div>
    );
  }
}

interface FloorCountProps {
  number: number;
}

export class FloorCount extends React.Component<FloorCountProps> {
  render() {
    const { number } = this.props;
    return <div style={{ marginLeft: '6px' }}>{number.toLocaleString()}</div>;
  }
}
