import client from '../apollo/client';
// import { RequestUploadOssFileDocument } from '../apollo';
import { message } from 'antd';
import { api_uri } from '../config';
import { UploadFile } from 'antd/lib/upload/interface';
import { getFileItem } from 'antd/lib/upload/utils';

// export async function signFile(file_name: string) {
//   const { data } = await client.query({
//     query: RequestUploadOssFileDocument,
//     variables: {
//       file_name,
//     },
//     fetchPolicy: 'network-only',
//   });
//   return data;
// }

// export async function upload(file: any) {
//   const data = await signFile(file.name);
//   const body = new FormData();
//   body.append('key', data.requestUploadOSSFile.key);
//   body.append('policy', data.requestUploadOSSFile.policy);
//   body.append('OSSAccessKeyId', data.requestUploadOSSFile.access_key_id);
//   body.append('signature', data.requestUploadOSSFile.signature);
//   body.append('callback', data.requestUploadOSSFile.callback);
//   body.append('success_action_status', '200');
//   body.append('file', file);
//   // const uploadResponse =
//   await fetch(data.requestUploadOSSFile.host, {
//     method: 'POST',
//     body,
//   });
//   // const { data: fileData } = await uploadResponse.json();
//   // await uploadResponse.json();
//   return data.requestUploadOSSFile.key;
// }

export const up_load_life_photo_options = (callback: (uri: string) => void) => {
  const token = localStorage.getItem('token');
  return {
    name: 'file',
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 上传成功`);
      } else if (info.file.status === 'error') {
        message.error(new Error(`${info.file.name} 上传失败`));
      }
    },
    // customRequest: async ({ file, onSuccess }: any) => {
    //   const insurance_book_uri = await upload(file);
    //   callback(insurance_book_uri);
    //   onSuccess();
    //   return {
    //     abort() {},
    //   };
    // },
  };
};

export const file_props = (post_uri: string, cb?: (uri: string) => void) => {
  const token = localStorage.getItem('token');
  return {
    name: 'file',
    action: `${api_uri}/${post_uri}`,
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    onChange(info: any) {
      const { status, response } = info.file;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        if (cb) {
          cb(response.file_name);
        }
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(new Error(`${info.file.name} file upload failed.`));
      }
    },
  };
};

const listType: any = `picture`;
// const customRequest: any = {
//   onError: (error: any) => {
//     console.log('error', error);
//   },
// };
export const file_multiple_props = (
  post_uri: string,
  cb?: (uri: string) => void,
) => {
  const token = localStorage.getItem('token');
  return {
    name: 'file',
    multiple: true,
    directory: true,
    listType,
    action: `${api_uri}/${post_uri}`,
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    onChange(info: any) {
      // const { status } = info.file;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      //   message.error(new Error(`${info.file.name} file upload failed.`));
      // }
    },
    // beforeUpload (file: any) {
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error('文件必须小于2M');
    //   }
    //   return isLt2M;
    // }
  };
};
