import React from 'react';

import styles from './index.module.css';

interface NumberCardProps {
  title: string;
  number: number;
  src: any;
}

export class NumberCard extends React.Component<NumberCardProps> {
  render() {
    const { src, title, number } = this.props;
    return (
      <div className={`${styles.box} ${styles.number}`}>
        <img alt="头像" className={styles.number_image} src={src} />
        <div>
          <span className={styles.number_title}>{title}</span>
          <span className={styles.number_number}>
            {number.toLocaleString()}
          </span>
        </div>
      </div>
    );
  }
}
