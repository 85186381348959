import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './no-access.module.css';

class NoAccess extends Component<any> {
  render() {
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.imgEle} />
          您当前没有该权限，请联系管理员。
          {/* <a href="/">返回首页</a> */}
        </div>
      </div>
    );
  }
}

export default withRouter(NoAccess);
