import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { UsersComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './user.columns';
import { mapData } from '../../lib';
import { CreateUserModal } from './create-user';

function getData(data: any) {
  return mapData(data, 'users', (user: any) => {
    return {
      key: user.id,
      ...user,
    };
  });
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = ['姓名', '手机号码', '邮箱'];

export default class User extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <UsersComponent
          variables={{
            skip,
            take: 10,
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            const result = getData(data);
            return (
              <div className="table_container">
                <TableTitle name={`用户管理`} number={result.count} />
                <TableSearch
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  // outData={() => {}}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...this.state.query_params,
                  }}
                >
                  <CreateUserModal refetch={refetch} />
                </TableSearch>
                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={columns}
                  loading={data && data.users ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 10,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </UsersComponent>
      </BaseLayout>
    );
  }
}
