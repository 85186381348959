import React from 'react';
import { ChangePeopleModalForm } from './update-people';
import { DeletePeopleModal } from './delete-people';
import { Row, Col } from 'antd';
import moment from 'moment';
import { api_uri } from '../../config';
import { StopPeopleModal } from './stop-people';
import { ReopenPeopleModal } from './reopen-people';
import { AddLeaveModalForm } from '../leave/create-leave';

// true_name
// sex
// mobile
// id_card
// room_card_number
// department
// department_detail
// start_date
// dorm_number
// room_number
// bed_number

export const columns = (filter?: any, refetch?: any) => [
  // {
  //   title: '头像',
  //   dataIndex: 'avatar',
  //   key: 'avatar',
  //   width: 80,
  //   render: (text: string, record: any) =>
  //     text && (
  //       <img
  //         alt="头像"
  //         style={{ width: 50, height: 50 }}
  //         src={api_uri + '/file/avatar/' + text}
  //       />
  //     ),
  //   filters: [
  //     { text: '已上传', value: '已上传' },
  //     { text: '未上传', value: '未上传' },
  //   ],
  // },
  {
    title: '底片',
    dataIndex: 'photo',
    key: 'photo',
    width: 80,
    render: (text: string, record: any) =>
      text && (
        <img
          alt="底片"
          style={{ width: 50, height: 50 }}
          src={api_uri + '/file/photo/' + text}
        />
      ),
    filters: [
      { text: '已上传', value: '已上传' },
      { text: '未上传', value: '未上传' },
    ],
  },
  {
    title: '真实姓名',
    dataIndex: 'true_name',
    key: 'true_name',
    // search: true,
    // fixed: 'left',
    width: 120,
    render: (text: string, record: any) => (
      <a href={`/people-detail?id=${record.id}`}>{text}</a>
    ),
  },
  {
    title: '手机号码',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 120,
  },
  {
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
    width: 120,
  },
  {
    title: '身份证号',
    dataIndex: 'id_card',
    key: 'id_card',
    width: 120,
  },
  {
    title: '部门',
    dataIndex: 'father_department',
    key: 'father_department',
    width: 120,
    filters: filter && filter.structures,
    render: (text: string) => text || '无',
  },
  {
    title: '分部门',
    dataIndex: 'department',
    key: 'department',
    width: 120,
  },
  {
    title: '服务部门',
    dataIndex: 'to_department',
    key: 'to_department',
    width: 120,
    filters: filter && filter.list_choose,
  },
  // {
  //   title: '部门',
  //   dataIndex: 'department',
  //   key: 'department',
  //   width: 120,
  //   // filters: departments.map(department => ({
  //   //   text: department,
  //   //   value: department,
  //   // })),
  // },
  // {
  //   title: '分部门',
  //   dataIndex: 'department_detail',
  //   key: 'department_detail',
  //   width: 120,
  // },
  {
    title: '卡号',
    dataIndex: 'room_card_number',
    key: 'room_card_number',
    width: 120,
  },
  {
    title: '入住时间',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 120,
    render: (text: string) => text && moment(text).format('YYYY年MM月DD日'),
  },
  {
    title: '宿舍楼号',
    dataIndex: 'dorm_number',
    key: 'dorm_number',
    width: 120,
    filters: filter && filter.dorms,
  },
  {
    title: '楼层号',
    dataIndex: 'floor_numbers',
    key: 'floor_numbers',
    width: 120,
    render: (_: string, record: any) => record.floor_number,
    filters: [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
      { text: 6, value: 6 },
      { text: 7, value: 7 },
      { text: 8, value: 8 },
      { text: 9, value: 9 },
      { text: 10, value: 10 },
      { text: 11, value: 11 },
    ],
  },
  {
    title: '宿舍号',
    dataIndex: 'room_number',
    key: 'room_number',
    width: 120,
  },
  {
    title: '床号',
    dataIndex: 'bed_number',
    key: 'bed_number',
    width: 120,
  },
  {
    title: '人员类型',
    dataIndex: 'type',
    key: 'type',
    width: 120,
    filters: [
      { text: '入住人员', value: '入住人员' },
      { text: '内部员工', value: '内部员工' },
      { text: '其他', value: '其他' },
    ],
  },
  {
    title: '创建人',
    dataIndex: 'admin',
    key: 'admin',
    width: 120,
    render: (text: string, record: any) =>
      record.admin && record.admin.username,
  },
  {
    title: '在寝状态',
    dataIndex: 'in_status',
    key: 'in_status',
    width: 120,
    filters: [
      { text: '在寝', value: '在寝' },
      { text: '外出', value: '外出' },
    ],
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    filters: [
      { text: '正常', value: '正常' },
      { text: '停用', value: '停用' },
    ],
  },
  {
    title: '操作',
    width: 200,
    key: 'action',
    render: (_: string, record: any) => (
      <Row gutter={16}>
        <Col className="gutter-row" span={5}>
          <ChangePeopleModalForm
            item={record}
            id={record.key}
            refetch={refetch}
          />
        </Col>
        <Col className="gutter-row" span={5}>
          <DeletePeopleModal id={record.key} refetch={refetch} />
        </Col>
        <Col className="gutter-row" span={6}>
          <AddLeaveModalForm people_id={record.key} refetch={refetch} />
        </Col>
        <Col className="gutter-row" span={6}>
          {record.status === '正常' ? (
            <StopPeopleModal id={record.key} refetch={refetch} />
          ) : (
            <ReopenPeopleModal id={record.key} refetch={refetch} />
          )}
        </Col>
      </Row>
    ),
  },
];
