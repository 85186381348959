import React from 'react';
import {
  FullScreenContainer,
  Loading,
  ScrollBoard,
  Decoration7,
  BorderBox10,
} from '@jiaminghi/data-view-react';
import styles from './index.module.css';
import './index.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Row, Col, Icon, Button } from 'antd';

import { DataV2Component } from '../../apollo';
import header_img from '../../images/header.png';
import worker from '../../images/worker.png';
import visitor from '../../images/visitor.png';
import register from '../../images/register.png';
import headcount from '../../images/headcount.png';
import { system_name } from '../../config';
import logo from '../../images/logo.png';

const list = {
  header: ['管理部门', '所属单位', '登记人数'],
  data: [],
  index: false,
  // columnWidth: [30, 30, 30],
  align: ['center', 'center', 'center'],
  carousel: 'page',
  rowNum: 10,
  headerBGC: '#104E8B',
  waitTime: 5000,
};
export class Date extends React.Component {
  interval: NodeJS.Timeout = setInterval(() => {}, 1000);

  state = {
    date: moment().format('YYYY年MM月DD日 HH:mm:ss'),
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        date: moment().format('YYYY年MM月DD日 HH:mm:ss'),
      });
    }, 1000);
  }

  // 清除定时器
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <div className={styles.date}>{this.state.date}</div>;
  }
}
export class DataV2 extends React.Component {
  refetch: any;
  timeout: NodeJS.Timeout = setTimeout(() => {}, 1000);

  state = {
    show: true,
    isFullScreen: false,
  };

  setTimeOut = () => {
    this.timeout = setTimeout(async () => {
      if (this.refetch) {
        this.refetch();
      }
      this.setTimeOut();
    }, 6000);
  };

  componentDidMount() {
    this.watchFullScreen();
    this.setTimeOut();
  }

  fullScreen = () => {
    console.log('fullscreen:', this.state.isFullScreen);
    if (!this.state.isFullScreen) {
      this.requestFullScreen();
    } else {
      this.exitFullscreen();
    }
    this.setState({
      isFullScreen: this.state.isFullScreen,
    });
  };

  //进入全屏
  requestFullScreen = () => {
    console.log('requestFullScreen');
    var de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
      // @ts-ignore
    } else if (de.mozRequestFullScreen) {
      // @ts-ignore
      de.mozRequestFullScreen();
      // @ts-ignore
    } else if (de.webkitRequestFullScreen) {
      // @ts-ignore
      de.webkitRequestFullScreen();
    }
  };

  //退出全屏
  exitFullscreen = () => {
    console.log('exitFullscreen');
    var de = document;
    if (de.exitFullscreen) {
      de.exitFullscreen();
      // @ts-ignore
    } else if (de.mozCancelFullScreen) {
      // @ts-ignore
      de.mozCancelFullScreen();
      // @ts-ignore
    } else if (de.webkitCancelFullScreen) {
      // @ts-ignore
      de.webkitCancelFullScreen();
    }
  };

  //监听fullscreenchange事件
  watchFullScreen = () => {
    const _self = this;
    document.addEventListener(
      'webkitfullscreenchange',
      function() {
        _self.setState({
          // @ts-ignore
          isFullScreen: document.webkitIsFullScreen,
        });
      },
      false,
    );
  };

  //清除定时器
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <FullScreenContainer>
        <DataV2Component>
          {({ data, refetch }: any) => {
            if (!data || !data.datav || !data.screen_list) {
              return <Loading>加载中</Loading>;
            }
            if (!this.refetch) {
              this.refetch = refetch;
            }
            const {
              people_count,
              work_people_count,
              today_visitor_count,
              common_people_count,
            } = data.datav;
            const new_structures = data.screen_list.map(
              ({ name, department, people_count }: any) => {
                return [name, department, String(people_count)];
              },
            );
            return (
              <div>
                {/* {!this.state.isFullScreen && (
                  <button
                    onClick={this.fullScreen}
                    style={{ position: 'absolute', right: 0, top: 0 }}
                  ></button>
                )} */}
                <div className={styles.header}>
                  <img src={logo} className={styles.logo} />
                  <div className={styles.title}>
                    行政办公区{system_name}公寓入住人员信息统计
                  </div>
                  <img className={styles.header_img} src={header_img} />
                  <Date />
                </div>
                <div className={styles.hover_link}>
                  <div className={styles.hover_empty}>
                    <div className={styles.link_animated}>
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.fullScreen}
                      >
                        全屏
                      </Button>
                      <Button type="primary" size="large">
                        <Link to="/datav3">在寝人员</Link>
                      </Button>
                      <Button type="primary" size="large">
                        <Link to="/datav1">实时情况</Link>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.middle_line}>
                  <span className={styles.blue_line} />
                  <Decoration7 style={{ width: '320px', height: '30px' }}>
                    <span className={styles.middle_line_title}>
                      &nbsp;&nbsp;&nbsp;登记人员情况&nbsp;&nbsp;&nbsp;
                    </span>
                  </Decoration7>
                  <span className={styles.blue_line} />
                </div>
                <Row
                  gutter={32}
                  style={{
                    marginTop: '38px',
                    height: '120px',
                    padding: '0 20px',
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        {/* <Icon
                          type="usergroup"
                          style={{
                            fontSize: '30px',
                            paddingRight: '8px',
                          }}
                        /> */}
                        <img
                          src={headcount}
                          alt="总人数"
                          className={styles.headcount_icon}
                        />
                        总人数{' '}
                        <span className={styles.number_count}>
                          {people_count}
                        </span>
                      </div>
                    </BorderBox10>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        <img
                          src={register}
                          alt="工勤人员"
                          className={styles.count_icon}
                        />
                        工勤人员
                        <span className={styles.number_count}>
                          {common_people_count}
                        </span>
                      </div>
                    </BorderBox10>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        <img
                          src={worker}
                          alt="工作人员"
                          className={styles.worker_icon}
                        />
                        工作人员{' '}
                        <span className={styles.number_count}>
                          {work_people_count}
                        </span>
                      </div>
                    </BorderBox10>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <BorderBox10
                      color={['rgb(32, 187, 252)', 'rgb(212, 226, 247)']}
                    >
                      <div className={styles.people_all}>
                        <img
                          src={visitor}
                          alt="来访人员"
                          className={styles.worker_icon}
                        />
                        来访人员
                        <span className={styles.number_count}>
                          {today_visitor_count}
                        </span>
                      </div>
                    </BorderBox10>
                  </Col>
                </Row>
                <div style={{ padding: '0px 20px', marginTop: '18px' }}>
                  <ScrollBoard
                    config={{ ...list, data: new_structures }}
                    // style={{ height: '480px' }}
                    style={{ height: '630px' }}
                  />
                </div>
              </div>
            );
          }}
        </DataV2Component>
      </FullScreenContainer>
    );
  }
}
