import React from 'react';

import queryString from 'query-string';
import { Row, Col } from 'antd';

import {
  BaseTable,
  TableSearch,
  TableTitle,
} from '../../components/base-table';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './job.columns';

import { AddJobModalForm } from './create-job';
import { JobsComponent, CountJobComponent } from '../../apollo';
import { ChangeJobModalForm } from './update-job';
import { DeleteJobModal } from './delete-job';

function getData(data: any) {
  const list_data = data.jobs;
  const new_data =
    list_data && list_data.list
      ? list_data.list.map((item: any) => {
          return {
            ...item,
            key: item.id,
            true_name: item.true_name,
            mobile: item.mobile,
            email: item.email,
            created_date: item.created_date,
          };
        })
      : [];
  const count = (list_data && list_data.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

export default class Job extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <JobsComponent
          variables={{
            skip,
            take: 10,
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            const result = getData(data);

            const new_columns = columns.map(item => {
              if (item.key === 'action') {
                return {
                  title: '操作',
                  width: 80,
                  key: 'action',
                  render: (_: string, record: any) => (
                    <Row gutter={16}>
                      <Col className="gutter-row" span={12}>
                        <ChangeJobModalForm
                          refetch={refetch}
                          item={record}
                          id={record.key}
                        />
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <DeleteJobModal refetch={refetch} id={record.key} />
                      </Col>
                    </Row>
                  ),
                };
              }
              return item;
            });
            return (
              <div className="table_container">
                <CountJobComponent>
                  {({ data }) => {
                    return (
                      <TableTitle
                        name={`职位管理`}
                        number={
                          (data && data.countJob && data.countJob.count) || 0
                        }
                      />
                    );
                  }}
                </CountJobComponent>
                <TableSearch
                  style={{ marginTop: '60px' }}
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 10,
                    ...this.state.query_params,
                  }}
                >
                  <AddJobModalForm refetch={refetch} />
                </TableSearch>

                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={new_columns}
                  loading={data && data.jobs ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 10,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </JobsComponent>
      </BaseLayout>
    );
  }
}
