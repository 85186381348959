import React from 'react';
import { Button, Modal, Form, Input } from 'antd';

import { CreateUserComponent } from '../../apollo';
import { message } from '../../components/message';
import { isPhoneNumber } from '../../lib';

const button_name = '新增用户';
const form_name = 'create_user';

class CreateUserModalForm extends React.Component<any> {
  state = {
    visible: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateUser: values,
            },
          });
          message.success('创建成功');
          const { form, refetch } = this.props;
          form.resetFields();
          this.close();
          refetch();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button onClick={() => this.setState({ visible: true })}>
          {button_name}
        </Button>
        <Modal
          title={button_name}
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="真实姓名">
              {getFieldDecorator('username', {
                rules: [{ required: true, message: '请输入真实姓名!' }],
              })(<Input placeholder="真实姓名" />)}
            </Form.Item>
            <Form.Item label="手机号">
              {getFieldDecorator('mobile', {
                rules: [
                  { required: true, message: '请输入手机号!' },
                  {
                    validator: (_: any, value: any, callback: any) => {
                      if (value && isPhoneNumber(value)) {
                        callback();
                      } else {
                        callback(new Error('请输入正确的手机号'));
                      }
                    },
                  },
                ],
              })(<Input placeholder="手机号" />)}
            </Form.Item>
            <Form.Item label="邮箱" hasFeedback>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: '请输入邮箱!' }],
              })(<Input placeholder="邮箱" />)}
            </Form.Item>
            <Form.Item label="密码" hasFeedback>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(<Input placeholder="密码" type="password" />)}
            </Form.Item>
            <Form.Item style={{ marginTop: '12px' }}>
              <CreateUserComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                      className="login-form-button"
                    >
                      增加
                    </Button>
                  );
                }}
              </CreateUserComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const CreateUserModal: any = Form.create({ name: form_name })(
  CreateUserModalForm,
);
