import React from 'react';
import moment from 'moment';
import { UpdateStructureModal } from './update-structure';
import { DeleteStructureModal } from './delete-structure';

export const columns = (refetch?: any) => [
  {
    title: '部门名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: '编号',
    dataIndex: 'number',
    key: 'number',
    width: 80,
  },
  {
    title: '父级部门',
    dataIndex: 'father_name',
    key: 'father_name',
    width: 80,
  },
  {
    title: '负责人',
    dataIndex: 'leading_cadre',
    key: 'leading_cadre',
    width: 80,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 80,
  },
  {
    title: '排序',
    dataIndex: 'sort_number',
    key: 'sort_number',
    width: 80,
  },
  {
    title: '创建时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 160,
    render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 60,
    render: (text: any, item: any) => (
      <>
        <UpdateStructureModal refetch={refetch} item={item} />
        {!item.children && (
          <DeleteStructureModal refetch={refetch} id={item.id} />
        )}
      </>
    ),
  },
];
