import React from 'react';
import { Card, List } from 'antd';
import { BaseLayout } from '../../layout/base-layout';
import { AddDormModalForm } from './create-dorm';
import { DormsComponent } from '../../apollo';
import { ChangeDormModalForm } from './update-dorm';
import { DeleteDormModal } from './delete-dorm';
import home from './home.png';
import styles from './index.module.css';

const { Meta } = Card;

class Dorm extends React.Component {
  render() {
    return (
      <BaseLayout>
        <DormsComponent variables={{ take: 100 }}>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            return (
              <>
                <AddDormModalForm refetch={refetch} />
                <br />
                <List
                  grid={{ gutter: 16, column: 4 }}
                  dataSource={data.dorms.list}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        cover={
                          <img
                            alt="example"
                            src={home}
                            className={styles.card_image}
                          />
                        }
                        // actions={[
                        //   <ChangeDormModalForm refetch={refetch} item={item} />,
                        //   <DeleteDormModal refetch={refetch} id={item.id} />,
                        // ]}
                      >
                        <Meta
                          title={item.name}
                          description={
                            <div>
                              编号：{item.number}
                              <br />
                              楼层：{item.floor_count} 层
                              <br />
                              每层房间数量：{item.floor_room_count}
                            </div>
                          }
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </>
            );
          }}
        </DormsComponent>
      </BaseLayout>
    );
  }
}

export default Dorm;
