import React from 'react';
import { Table } from 'antd';
import { BaseLayout } from '../../layout/base-layout';

const columns = [
  {
    title: '服务部门',
    dataIndex: 'name',
    // render: (text: any, row: any, index: any) => {
    //   if (index < 4) {
    //     return <a>{text}</a>;
    //   }
    //   return {
    //     children: <a>{text}</a>,
    //     props: {
    //       colSpan: 5,
    //     },
    //   };
    // },

    // render: (value: any, row: any, index: any) => {
    //   const obj: any = {
    //     children: value,
    //     props: {},
    //   };
    //   if (index === 2) {
    //     obj.props.rowSpan = 2;
    //   }
    //   // These two are merged into above cell
    //   if (index === 3) {
    //     obj.props.rowSpan = 0;
    //   }
    //   if (index === 4) {
    //     obj.props.colSpan = 0;
    //   }
    //   return obj;
    // },
  },
  {
    title: '管理单位',
    dataIndex: 'department',
  },
  {
    title: '登记人数',
    dataIndex: 'people_count',
  },
  {
    title: '在寝人数',
    dataIndex: 'people_in_count',
  },
  {
    title: '操作',
    dataIndex: 'action',
  },
];

const data = [
  {
    key: '1',
    name: '第一服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
  {
    key: '2',
    name: '第一服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
  {
    key: '3',
    name: '第一服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
  {
    key: '4',
    name: '第二服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
  {
    key: '5',
    name: '第三服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
  {
    key: '6',
    name: '第三服务中心',
    department: '宽沟',
    people_count: 0,
    people_in_count: 0,
  },
];

export class Fashion extends React.Component {
  render() {
    return (
      <BaseLayout>
        <Table columns={columns} dataSource={data} bordered />
      </BaseLayout>
    );
  }
}
